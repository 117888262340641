import * as turf from '@turf/turf';

// noinspection JSUnusedGlobalSymbols
const SnappedLineStringMode = {
  onSetup(opts) {
    const lineString = this.newFeature({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
      properties: {
        snapIds: [],
      },
    });
    this.addFeature(lineString);

    return {
      done: false,
      snapLayer: opts.snapLayer,
      lineString,
    };
  },
  onClick(state, e) {
    if (!state.done && this.isHoveringLastPoint(state, e)) {
      state.lineString.removeCoordinate(state.lineString.coordinates.length - 1);
      state.done = true;
      this.map.fire('draw.create', { features: [state.lineString.toGeoJSON()] });
      this.changeMode('simple_select', { featureIds: [state.lineString.id] });
      return;
    }

    const snapPoint = this.getSnapPoint(state, e);
    const target = snapPoint !== null ? snapPoint.coordinates : [e.lngLat.lng, e.lngLat.lat];

    if (state.done) {
      state.lineString.coordinates = [target];
      state.lineString.changed();
      state.done = false;
      this.map.fire('draw.delete', { features: [state.lineString.toGeoJSON()] });
    }

    state.lineString.addCoordinate(state.lineString.coordinates.length - 1, ...target);

    if (snapPoint !== null && snapPoint.properties.snapId !== null && !state.lineString.properties.snapIds.find((id) => id === snapPoint.properties.snapId)) {
      state.lineString.properties.snapIds.push(snapPoint.properties.snapId);
    }

    this.map.getCanvas().style.cursor = 'pointer';
  },
  onMouseMove(state, e) {
    let target;

    if (!state.done && this.isHoveringLastPoint(state, e)) {
      this.map.getCanvas().style.cursor = 'pointer';
      target = [e.lngLat.lng, e.lngLat.lat];
    } else {
      this.map.getCanvas().style.cursor = '';
      const snapPoint = this.getSnapPoint(state, e);
      target = snapPoint !== null ? snapPoint.coordinates : [e.lngLat.lng, e.lngLat.lat];
    }

    if (!state.done) {
      state.lineString.updateCoordinate(state.lineString.coordinates.length > 0 ? state.lineString.coordinates.length - 1 : 0, ...target);
    }
  },
  onKeyUp(state, e) {
    if (e.key === 'Escape') {
      this.deleteFeature(state.lineString.id);
      this.changeMode('simple_select');
    }
  },
  isHoveringLastPoint(state, e) {
    return state.lineString.coordinates.length > 2 && turf.distance([e.lngLat.lng, e.lngLat.lat], state.lineString.coordinates[state.lineString.coordinates.length - 2], { units: 'meters' }) < 2;
  },
  getSnapPoint(state, e) {
    if (!state.snapLayer) return null;

    const closestFeature = this.getClosestFeature(
      e.lngLat,
      this.map.queryRenderedFeatures(
        [
          [e.point.x - 20, e.point.y - 20],
          [e.point.x + 20, e.point.y + 20],
        ],
        {
          layers: [state.snapLayer],
          filter: ['in', '$type', 'LineString'],
        },
      ),
    );

    if (closestFeature === null) return null;

    const turfPoint = turf.nearestPointOnLine(turf.lineString(closestFeature.geometry.coordinates), turf.point([e.lngLat.lng, e.lngLat.lat]));

    return this.newFeature({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: turfPoint.geometry.coordinates,
      },
      properties: {
        snapId: closestFeature.id,
      },
    });
  },
  getClosestFeature(lngLat, features) {
    const target = turf.point([lngLat.lng, lngLat.lat]);
    let closestFeature = null;
    let smallestDistance = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];

      let distance;
      if (feature.geometry.type === 'LineString') {
        distance = turf.pointToLineDistance(target, turf.lineString(feature.geometry.coordinates));
      } else {
        distance = turf.distance(target, turf.point(feature.geometry.coordinates));
      }

      if (distance < smallestDistance) {
        closestFeature = feature;
        smallestDistance = distance;
      }
    }

    return closestFeature;
  },
  toDisplayFeatures(state, geojson, display) {
    display(geojson);
  },
};

export default SnappedLineStringMode;
