<template>
  <div id="sv" ref="sv">
    <div id="sv-hint" v-if="this.streetViewPositionClick === null">
      <span>
        CTRL+Click anywhere on the map (CMD+Click on MacOS) to set Street View position
      </span>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { Viewer } from 'mapillary-js';

import { getClosestImage } from '@/utils/mapillaryApi';
import Toast from '@/utils/toast';

const accessToken = process.env.VUE_APP_MAPILLARY_ACCESS_TOKEN;

export default {
  name: 'MapillaryStreetView',
  data() {
    return {
      viewer: null,
    };
  },
  computed: {
    ...mapGetters('editor', [
      'streetViewPositionClick',
      'streetViewCamera',
    ]),
  },
  async mounted() {
    this.viewer = new Viewer({
      accessToken,
      container: this.$refs.sv,
    });
    this.viewer.on('position', this.onPositionChanged);
    this.viewer.on('bearing', this.onPovChanged);
    const position = this.streetViewCamera.position;
    if (position !== null) {
      const { lng, lat } = position;
      this.onMapClicked(lng, lat);
    }
  },
  watch: {
    streetViewPositionClick({ lng, lat }) {
      this.onMapClicked(lng, lat);
    },
  },
  methods: {
    resize() {
      this.viewer.resize();
    },
    async onMapClicked(lng, lat) {
      const radius = 10;
      let closest;
      try {
        closest = await getClosestImage(lng, lat, radius, this.streetViewCamera.heading);
      } catch (error) {
        Toast.danger('Error fetching street view image');
        console.error(error);
        return;
      }
      if (closest === undefined) {
        Toast.info('Sorry, no street view is available in this area');
        return;
      }
      const [imageLng, imageLat] = closest.computed_geometry.coordinates;
      await this.viewer.moveTo(closest.id);
      this.$store.dispatch(
        'editor/streetViewPosition',
        {
          lng: imageLng,
          lat: imageLat,
        },
      );
    },
    async onPositionChanged() {
      const { lng, lat } = await this.viewer.getPosition();
      this.$store.dispatch('editor/streetViewPosition', { lng, lat });
    },
    onPovChanged(event) {
      this.$store.dispatch('editor/streetViewHeading', event.bearing);
    },
  },
};

</script>

<style src="mapillary-js/dist/mapillary.css"/>

<style scoped>

#sv {
  height: 100%;
  background: black;
}

#sv-hint {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  pointer-events: none;
}

#sv-hint > span {
  width: 80%;
}

</style>
