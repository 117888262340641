import store from '@/store/index';

const StreetViewControl = {
  onAdd() {
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.controlContainer.classList.add('mapboxgl-ctrl');

    this.button = document.createElement('button');
    this.button.title = 'Toggle Street View';
    this.button.classList.add('mapboxgl-ctrl-icon');
    this.button.classList.add('disabled');
    this.button.classList.add('mapboxgl-custom-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon-sv');
    this.controlContainer.appendChild(this.button);
    this.button.addEventListener('click', () => this.toggleStreetView());

    return this.controlContainer;
  },
  onRemove() {
    this.controlContainer.parentNode.removeChild(this.controlContainer);
  },
  toggleStreetView() {
    if (this.button.classList.contains('disabled')) {
      store.dispatch('editor/streetViewEnabled', true);
      this.button.classList.remove('disabled');
    } else {
      store.dispatch('editor/streetViewEnabled', false);
      this.button.classList.add('disabled');
    }
  },
};

export default StreetViewControl;
