<template>
  <Layout>
    <template slot="body">
      <div id="sidebar">
        <div
          class="sidebar-button"
          v-bind:class="{ selected: tab === 'profile' }"
          v-on:click="changeTab('profile')"
        >
          <img :src="require('@/assets/images/person_outline_black_24dp.svg')" alt="Profile"/>
          Profile
        </div>
        <div
          class="sidebar-button"
          v-bind:class="{ selected: tab === 'activity' }"
          v-on:click="changeTab('activity')"
        >
          <img :src="require('@/assets/images/schedule_black_24dp.svg')" alt="Activity"/>
          Activity
        </div>
      </div>
      <Profile v-if="tab === 'profile'"/>
      <Activity v-if="tab === 'activity'"/>
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout';
import Activity from '@/views/Activity';
import Profile from '@/views/Profile';
import { getHash, setHash } from '@/utils/url';

export default {
  components: {
    Layout,
    Activity,
    Profile,
  },
  data() {
    return {
      tab: null, // 'profile' | 'activity'
    };
  },
  mounted() {
    this.hashChange();
    window.addEventListener('hashchange', this.hashChange, false);
  },
  watch: {
    tab() {
      setHash('tab', this.tab, true);
    },
  },
  computed: {
  },
  methods: {
    hashChange() {
      this.tab = getHash('tab', 'profile');
    },
    changeTab(tab) {
      if (this.tab !== tab) {
        this.tab = tab;
      }
    },
  },
};
</script>

<style scoped>

#sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 375px;
  background-color: white;
  border-right: 1px solid #CED6DE;
}

.sidebar-button {
  display: flex;
  align-items: center;
  height: 55px;
  padding-left: 20px;
  border-bottom: 1px solid #CED6DE;
  width: 100%;
  cursor: pointer;
}

.sidebar-button.selected {
  background-color: #EDF8FF;
  cursor: default;
}

.sidebar-button img {
  margin-right: 10px;
}

</style>
