import ServicesHelper from '@/utils/servicesHelper';
import Toast from '@/utils/toast';

class ApiHelper {
  static TICKET_TYPES = Object.freeze({
    PICTURE: 0,
    MARKED_AS_INACCURATE: 1,
    SPOT_EDITED: 2,
    SPOT_DELETED: 3,
    MONTHLY_REQUEST: 4,
    TICKET_PROCESSED: 5,
    GAS_PRICES_PICTURE: 6,
  });

  static PICTURE_STATUSES = Object.freeze({
    GPS_ISSUE: 1,
    NOT_HANDLED: 2,
    PRIVATE: 3,
    MISUNDERSTANDING: 5,
    UNUSABLE: 6,
    TRASH: 7,
    GOLD_DATA: 8,
  });

  static ROLE_DISPLAY_NAMES = Object.freeze({
    'baby-angel': 'Baby Angel',
    angel: 'Angel',
    'guard-angel': 'Guard',
    'knight-angel': 'Knight',
    'royal-angel': 'Royal',
    'map-maker': 'Map Maker',
    'map-validator': 'Map Validator',
    admin: 'Admin',
  });

  static postMagicLinkRequest(email, redirect, success, error) {
    return ServicesHelper.post(
      `${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/users/magic`,
      {
        email,
        r: redirect,
        editor: true,
      },
      success, error,
    );
  }

  static postMagicLinkLogin(token, success, error) {
    return ServicesHelper.post(
      `${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/users/magic/auth`,
      { token },
      success, error,
      {
        'SA-App-Type': 'Web',
      },
    );
  }

  static postGoogleLogin(googleUser, success, error) {
    const profile = googleUser.getBasicProfile();
    return ServicesHelper.post(
      `${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/users`,
      {
        provider: 7,
        authentication: {
          provider_uid: profile.getId(),
          refresh_token: '',
          access_token: googleUser.getAuthResponse().id_token,
          expires_in: 0,
        },
        user: {
          first_name: profile.getGivenName(),
          last_name: profile.getFamilyName(),
          email: profile.getEmail(),
        },
      },
      success, error,
      {
        'SA-App-Type': 'Web',
      },
    );
  }

  static postLogout(success, error) {
    return ServicesHelper.patch(
      `${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/users/logout`,
      null,
      success,
      error,
    );
  }

  static getUser(success, error) {
    return ServicesHelper.get(
      `${process.env.VUE_APP_SPOTANGELS_API_URL}/api/v3/users/me/editor`,
      success,
      error,
    );
  }

  static uploadPhotos(segmentId, files, onPhotosAdded) {
    const total = files.length;
    const toast = Toast.info(`Uploading photos... 0/${total} done`, Toast.DURATION_INDEFINITE);
    let uploaded = 0;
    let failed = 0;

    for (const file of files) {
      if (file.type.split('/')[0] !== 'image') {
        failed += 1;
        Toast.danger(`Error: ${file.name} is not a photo.`);
        this.checkDoneUploading(toast, uploaded, failed, total, onPhotosAdded);
        continue;
      }

      const formData = new FormData();

      formData.append('segment_id', segmentId);
      formData.append('image_slug', 'other');
      formData.append('image', file);
      formData.append('lat', '0.0');
      formData.append('lng', '0.0');

      ServicesHelper.post(
        `${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/pictures/add`,
        formData,
        // eslint-disable-next-line no-loop-func
        () => {
          uploaded += 1;
          this.checkDoneUploading(toast, uploaded, failed, total, onPhotosAdded);
        },
        // eslint-disable-next-line no-loop-func
        () => {
          failed += 1;
          Toast.danger(`Error uploading photo ${file.name}, please try again`);
          this.checkDoneUploading(toast, uploaded, failed, total, onPhotosAdded);
        },
      );
    }
  }

  static updatePictureStatus(pictureId, statusId, comments, reassignedSegmentId, success, error) {
    const body = {
      picture_id: pictureId,
      status_id: statusId,
      validated: statusId === ApiHelper.PICTURE_STATUSES.GOLD_DATA,
      comments,
    };

    if (reassignedSegmentId !== null) {
      body.target_segment = reassignedSegmentId;
    }

    return ServicesHelper.post(`${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/update_picture_status`, body, success, error);
  }

  static markAsAccurate(segmentId, success, error) {
    const body = {
      segment_id: segmentId,
      accurate: true,
    };

    return ServicesHelper.post(`${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/crowdsource/accuracy`, body, success, error);
  }

  static savePicturesOrder(segmentId, reorderedPicturesTransient, reorderedPicturesMonthly, success, error) {
    const body = {
      segment_id: segmentId,
      transient_pictures_ids: reorderedPicturesTransient.map((p) => p.id),
      monthly_pictures_ids: reorderedPicturesMonthly.map((p) => p.id),
    };

    return ServicesHelper.post(`${process.env.VUE_APP_SPOTANGELS_API_MASTER_URL}/api/v3/pictures/reorder`, body, success, error);
  }

  static request(method, endpoint, ...args) {
    let baseUrl;
    if (method.localeCompare('get', 'en', { sensitivity: 'accent' }) === 0) {
      baseUrl = process.env.VUE_APP_SPOTANGELS_API_URL;
    } else {
      baseUrl = process.env.VUE_APP_SPOTANGELS_API_MASTER_URL;
    }

    return ServicesHelper[method](`${baseUrl}${endpoint}`, ...args);
  }

  static async requestAsync(...args) {
    return new Promise((resolve, reject) => {
      ApiHelper.request(
        ...args,
        (_, response) => {
          resolve(response);
        },
        (_, response) => {
          const error = new Error(response.body);
          error.response = response;
          reject(error);
        },
      );
    });
  }

  /**
   * Private methods, please don't call from outside
   */

  static checkDoneUploading(toast, uploaded, failed, total, onPhotosAdded) {
    if (uploaded + failed < total) {
      toast.text(`Uploading photos... ${uploaded + failed}/${total}`);
    } else if (failed === total) {
      toast.goAway(0);
      if (total > 1) {
        Toast.danger(`All ${total} photo uploads failed`);
      }
    } else {
      let text;
      if (failed !== 0) {
        text = `Done processing ${total} photos. ${uploaded} uploaded, ${failed} failed.`;
      } else if (total > 1) {
        text = `All ${total} photos uploaded!`;
      } else {
        text = 'Photo successfully uploaded!';
      }

      toast.text(text);
      toast.goAway(5000);

      onPhotosAdded();
    }
  }
}

export default ApiHelper;
