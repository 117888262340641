<template>
  <div class="sheet-card-row" :style="{ 'flex-direction': direction, 'padding-left': hPadding, 'padding-right': hPadding }">
    <span v-if="title" class="sheet-card-row-title" :style="{'padding-left': `max(${hPadding}, 16px)`, 'padding-right': `max(${hPadding}, 16px)`}">{{ title }}</span>
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'SheetCardRow',
  props: {
    direction: {
      type: String,
      default: 'column',
    },
    title: {
      type: String,
      default: null,
    },
    hPadding: {
      type: String,
      default: '16px',
    },
  },
};

</script>

<style scoped>

.sheet-card-row {
  min-height: 54px;
  padding: 16px;
  border-bottom: 1px solid #CED6DE;
  display: flex;
  justify-content: center;
}

.sheet-card-row-title {
  height: 32px;
  font-weight: bold;
}

</style>
