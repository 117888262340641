class AuthHelper {
  static user = null;

  static onLogin(result) {
    window.localStorage.setItem('login_token', result.login_token);
    this.handleUser(result);
  }

  static onLogout() {
    window.localStorage.clear();
  }

  static getLoginToken() {
    const loginToken = window.localStorage.getItem('login_token');
    if (loginToken !== undefined) {
      return loginToken;
    }

    // Login token used to be stored in cookie, keep this to avoid logging users out
    for (const kv of document.cookie.split(';')) {
      const [k, v] = kv.split('=', 2);
      if (k.trim() === 'login_token') {
        return v ? v.trim() : null;
      }
    }

    return null;
  }

  static handleUser(user) {
    this.user = user;
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  static getUser() {
    if (this.user === null) {
      this.user = JSON.parse(window.localStorage.getItem('user'));
    }

    return this.user;
  }
}

export default AuthHelper;
