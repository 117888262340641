<template>
  <div class="day-selection">
    <span>{{ label }}</span>
    <vue-multiselect
      :options="[{ everyDay: 'Every day', days: [1, 2, 3, 4, 5, 6, 0]}]"
      v-model="sortedSelectedDays"
      :custom-label="(d) => DAYS[d]"
      :allow-empty="false"
      :searchable="false"
      :preselect-first="false"
      :group-select="true"
      group-label="everyDay"
      group-values="days"
      select-group-label=""
      deselect-group-label=""
      :multiple="true" :close-on-select="false" :clear-on-select="false"
      placeholder="Select active days"
    >
      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single" v-if="values.length === 7">Every day</span>
      </template>
      <template slot="tag" slot-scope="day">
          <span>
            {{ DAYS[day.option] + (day.option === selectedDays[selectedDays.length - 1] ? '' : ',') }}
          </span>
      </template>
    </vue-multiselect>
  </div>
</template>

<script>

import vueMultiselect from 'vue-multiselect';

export default {
  name: 'DaySelection',
  components: {
    vueMultiselect,
  },
  props: {
    value: null,
    label: {
      type: String,
    },
  },
  data() {
    return {
      DAYS: Object.freeze(['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']),
      selectedDays: this.value,
    };
  },
  computed: {
    sortedSelectedDays: {
      get() {
        return this.selectedDays;
      },
      set(newSelectedDays) {
        this.selectedDays = newSelectedDays.map((e) => (e + 6) % 7).sort().map((e) => (e + 1) % 7); // Sunday is index 0 but shown last in list
        this.$emit('input', newSelectedDays);
      },
    },
  },
};

</script>

<style scoped>

.day-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selection > span {
  width: 30%;
  text-align: right;
  padding-right: 8px;
}

.day-selection .multiselect {
  width: 70%;
  min-height: 32px;
}

</style>

<style>

.day-selection .multiselect {
  min-height: 32px;
}

.day-selection .multiselect__tags {
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selection .multiselect.multiselect--active .multiselect__tags {
  border: 1px solid #1C9BE6;
}

.day-selection .multiselect__tags:focus {
  border: 1px solid #1C9BE6;
}

.day-selection .multiselect__tags:disabled {
  border: 1px solid #CED6DE;
}

.day-selection .multiselect__tags-wrap {
  display: flex;
  flex-direction: row;
}

.day-selection .multiselect__tags-wrap span {
  margin: 0 4px 0 0;
}

.day-selection .multiselect__select {
  height: 32px;
}

.day-selection .multiselect__placeholder {
  margin: 0;
  padding: 0;
}

.day-selection .multiselect__input {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.day-selection .multiselect__single {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

</style>
