import Vue from 'vue/dist/vue.esm';

export const eventBus = new Vue();

/**
 * Please add events here and use constants in $emit and $on
 * This is to avoid typos by having IDE completion, have a centralized list of all existing events and avoid potential collisions when adding new events
 */
export const events = {
  ticketProcessed: 'ticket-processed',
  jumpToRequested: 'jump-to-requested',
  refreshNewFeaturesRequested: 'request-new-features-requested',
  editFeatureRequested: 'edit-feature-requested',
  drawAreaToMapRequested: 'draw-area-to-map-requested',
  spotMarkedAsAccurate: 'spot-marked-as-accurate',
  changesetRestored: 'changeset-restored',
  segmentDeleted: 'segment-deleted',
  fuelStationDeleted: 'fuel-station-deleted',
  cameraIdle: 'camera-idle',
  setSourceLayersVisible: 'set-source-layers-visible',
};
