<template>
  <div id="garage-rates">
    <ItemCard v-for="(rate, i) in rates" :key="rate.id" title="Rate" v-on:copy="copyRate(i)" v-on:delete="deleteRate(i)">
      <DaySelection label="Days" :value="getSelectedDays(rate)" v-on:input="setSelectedDays($event, rate)"/>
      <TimeSelection label="Times" :range="true" :optional="true" :value="getSelectedTimes(rate)" v-on:input="setSelectedTimes($event, rate)"/>

      <DurationSelection
        label="Duration"
        v-model="rate.duration"
        v-on:input="checkRateCanBeProportional(rate)"
      />

      <Input type="number" label="Price" v-model="rate.price" :step=".1"/>
      <Checkbox label="Proportional" v-model="rate.proportional" :grayed="rate.duration >= 1440" :form-style="true"/>
      <DurationSelection label="Valid after initial stay of" v-model="rate.min_initial_stay"/>
    </ItemCard>
    <MaterialButton label="Add rate" v-on:click="addRate"/>
  </div>
</template>

<script>

import Checkbox from '@/components/form/Checkbox';
import DaySelection from '@/components/form/DaySelection';
import DurationSelection from '@/components/form/DurationSelection';
import Input from '@/components/form/Input';
import ItemCard from '@/components/editing/ItemCard';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';
import TimeSelection from '@/components/form/TimeSelection';

export default {
  name: 'GarageRates',
  components: {
    Checkbox,
    DaySelection,
    DurationSelection,
    Input,
    ItemCard,
    MaterialButton,
    TimeSelection,
  },
  props: {
    value: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      rates: [...MapDataHelper.assignRandomIds(this.value)].sort((a, b) => {
        const daysA = this.getSelectedDays(a);
        const daysB = this.getSelectedDays(b);

        if (JSON.stringify(daysA) === JSON.stringify(daysB)) {
          const timesA = this.getSelectedTimes(a);
          const timesB = this.getSelectedTimes(b);
          return ((timesA.start_hour * 60) - timesA.start_minute) - ((timesB.start_hour * 60) + timesB.start_minute);
        }

        for (let i = 0; i < daysA.length && i < daysB.length; i++) {
          if (daysA[i] !== daysB[i]) {
            return daysA[i] - daysB[i];
          }
        }

        return daysA.length > daysB.length ? -1 : 1;
      }),
    };
  },
  watch: {
    rates: {
      handler(rates) {
        this.$emit('input', rates);
      },
      deep: true,
    },
  },
  methods: {
    getSelectedDays(rate) {
      const selectedDays = [];
      for (let i = 0; i < 7; i++) {
        if (rate[MapDataHelper.WEEKDAYS[i]]) {
          selectedDays.push((i + 1) % 7);
        }
      }

      return selectedDays;
    },
    setSelectedDays(rawSelectedDays, rate) {
      for (let i = 0; i < 7; i++) {
        rate[MapDataHelper.WEEKDAYS[i]] = rawSelectedDays.indexOf((i + 1) % 7) !== -1;
      }
    },
    getSelectedTimes(rate) {
      return {
        start_hour: rate.start_time === null ? null : parseInt(rate.start_time.substr(0, 2), 10),
        start_minute: rate.start_time === null ? null : parseInt(rate.start_time.substr(3, 5), 10),
        end_hour: rate.end_time === null ? null : parseInt(rate.end_time.substr(0, 2), 10),
        end_minute: rate.end_time === null ? null : parseInt(rate.end_time.substr(3, 5), 10),
      };
    },
    setSelectedTimes(rawSelectedTimes, rate) {
      rate.start_time = `${rawSelectedTimes.start_hour.toString().padStart(2, '0')}:${rawSelectedTimes.start_minute.toString().padStart(2, '0')}:00`;
      rate.end_time = `${rawSelectedTimes.end_hour.toString().padStart(2, '0')}:${rawSelectedTimes.end_minute.toString().padStart(2, '0')}:00`;
      rate.is_next_day = rate.end_time <= rate.start_time;
    },
    checkRateCanBeProportional(rate) {
      if (rate.duration >= 1440) {
        rate.proportional = false;
      }
    },
    addRate() {
      this.rates.push(MapDataHelper.newGarageRate());
    },
    copyRate(i) {
      this.rates.push(MapDataHelper.copyItemWithId(this.rates[i]));
    },
    deleteRate(i) {
      this.rates.splice(i, 1);
    },
  },
};

</script>

<style scoped>

#garage-rates {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#garage-rates > * {
  margin-bottom: 8px;
}

#garage-rates > *:last-child {
  margin-bottom: 32px;
}

</style>
