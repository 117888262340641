<template>
  <div class="sheet-title" :class="{'sheet-title-sticky': sticky }">
    <img :style="{visibility: !!backButton ? 'visible' : 'hidden'}" v-on:click="$emit('back-clicked')" :src="require(backButton === 'close' ? '@/assets/images/close-black-24dp.svg' : '@/assets/images/arrow_back-black-18dp.svg')" :alt="backButton">
    <div class="text-container">
      <span :class="titleClass">{{ title ? title : '' }}</span>
      <span class="subtitle" v-if="subtitle !== null">{{ subtitle }}</span>
    </div>
    <MaterialButton v-if="action || src" :label="action" :src="src" v-on:click="$emit('action-clicked')" :disabled="actionDisabled"/>
  </div>
</template>

<script>

import MaterialButton from '@/components/MaterialButton';

export default {
  name: 'SheetTitle',
  components: { MaterialButton },
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    backButton: null,
    title: null,
    titleClass: {},
    subtitle: null,
    action: null,
    src: null,
    actionDisabled: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style scoped>
.sheet-title {
  background-color: white;
  width: 100%;
  max-width: 375px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px 0 0;
  color: #212529;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #CED6DE;
  flex-shrink: 0;
}

.sheet-title.sheet-title-sticky {
  position: sticky;
}

.sheet-title img {
  width: 32px;
  height: 32px;
  padding: 7px;
  margin: 0 8px;
  cursor: pointer;
  border-radius: 100%;
}

.sheet-title img:hover {
  background: #F5F7F8;
}

.text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.text-container span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.sheet-title .subtitle {
  font-size: 14px;
  color: #545E69;
  font-weight: initial;
}

</style>
