<template>
  <div v-if="value !== null" class="price-info">
    <div v-for="entry in value.entries" :key="entry.title" class="entry">
      <div class="title" v-html="entry.title"/>
      <div class="values" v-html="entry.values.join('<br>')"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GaragePriceInfo',
  props: {
    value: {
      type: Object,
    },
  },
};

</script>

<style scoped>

.price-info {
  padding: 16px;
  border-bottom: 1px solid #CED6DE;
}

.entry {
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.entry:last-child {
  padding-bottom: 0;
}

</style>
