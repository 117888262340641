<template>
  <div>
    <ItemCard v-for="(price, i) in prices" :key="price.id" title="Price" v-on:copy="copyPrice(price)" v-on:delete="deletePrice(i)">
      <div class="editing-form">
        <DaySelection label="Days" v-model="price.days"/>
        <TimeSelection label="Times" :range="true" :value="getTimes(price)" v-on:input="setTimes(price, $event)"/>
        <div class="editing-field" v-for="(rate, j) in price.rates" :key="j">
          <template v-if="j === 0">
            <span>Hourly rate</span>
          </template>
          <template v-else>
            <div class="spacer"></div>
          </template>
          <label class="meter-price-rate">
            <span>$</span>
            <input type="number" class="rate-input" :value="rate" step=".25" min="0" v-on:input="setRate(price, j, Number.parseFloat($event.target.value))"/>
          </label>
          <template v-if="j === 0">
            <span class="spacer">{{ price.rates.length > 1 ? 'initially' : '' }}</span>
            <button type="button" class="price-action-button" v-on:click="addRate(price)">
              <img :src="require('@/assets/images/add-black-24dp.svg')" title="Add rate" alt="Add rate"/>
            </button>
          </template>
          <template v-else>
            <span>after</span>
            <div class="select-wrapper">
              <select required="required" v-model="price.after_durations[j]" v-on:input="$emit('input', prices)">
                <option v-for="duration in DURATIONS" :key="duration" :value="duration">
                  {{ duration < 60 ? `${duration}m` : (`${Math.trunc(duration / 60)}h` + (duration % 60 ? ` ${duration % 60}m` : '')) }}
                </option>
              </select>
            </div>
            <button type="button" class="price-action-button" v-on:click="deleteRate(price, j)">
              <img :src="require('@/assets/images/delete-black-24dp.svg')" title="Delete rate" alt="Delete rate"/>
            </button>
          </template>
        </div>
      </div>
    </ItemCard>
  </div>
</template>

<script>

import ItemCard from '@/components/editing/ItemCard';
import DaySelection from '@/components/form/DaySelection';
import TimeSelection from '@/components/form/TimeSelection';
import MapDataHelper from '@/utils/mapDataHelper';

export default {
  name: 'MeterPrices',
  components: {
    ItemCard,
    DaySelection,
    TimeSelection,
  },
  props: {
    value: null,
  },
  data() {
    return {
      DURATIONS: Object.freeze([20, 60, 120, 180, 240, 340, 600]),
      prices: MapDataHelper.assignRandomIds(this.value),
    };
  },
  watch: {
    prices: {
      handler(prices) {
        this.$emit('input', prices);
      },
      deep: true,
    },
  },
  methods: {
    addPrice() {
      this.prices.push(MapDataHelper.newMeterPrice());
    },
    copyPrice(price) {
      this.prices.push(MapDataHelper.copyItemWithId(price));
    },
    deletePrice(i) {
      this.prices.splice(i, 1);
    },
    setRate(price, j, rate) {
      price.rates[j] = rate;
      this.$emit('input', this.prices);
    },
    addRate(price) {
      price.rates.push(price.rates[price.rates.length - 1] + 1.0);

      let idx = this.DURATIONS.indexOf(price.after_durations[price.after_durations.length - 1]) + 1;
      if (idx === this.DURATIONS.length) {
        idx -= 1;
      }
      price.after_durations.push(this.DURATIONS[idx]);
      this.$emit('input', this.prices);
    },
    deleteRate(price, j) {
      price.rates.splice(j, 1);
      price.after_durations.splice(j, 1);
      this.$emit('input', this.prices);
    },
    getTimes(price) {
      return {
        start_hour: price.from_hour,
        start_minute: price.from_min,
        end_hour: price.to_hour,
        end_minute: price.to_min,
      };
    },
    setTimes(price, times) {
      price.from_hour = times.start_hour;
      price.from_min = times.start_minute;
      price.to_hour = times.end_hour;
      price.to_min = times.end_minute;
      this.$emit('input', this.prices);
    },
  },
};

</script>

<style scoped>

.meter-price-rate {
  height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  padding: 0 6px;
  display: flex;
  align-items: center;
}

.meter-price-rate:hover, .meter-price-rate:focus {
  border: 1px solid #1C9BE6;
}

.rate-input {
  width: 60px;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: none;
  margin-left: 2px;
  border: none;
  background: none;
}

.price-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 4px;
  opacity: 0.6;
  background: none;
  border: none;
  cursor: pointer;
}

.price-action-button:hover {
  opacity: 1;
}

.spacer {
  flex-grow: 1;
}

</style>
