class FormatHelper {
  static shortDateTimeFormat = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });

  static shortTimeFormat = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: '2-digit',
  });

  static shortDateTime(date) {
    return FormatHelper.shortDateTimeFormat.format(date);
  }

  static shortTime(time) {
    return FormatHelper.shortTimeFormat.format(time);
  }
}

export default FormatHelper;
