<template>
  <div id="garage-entrances">
    <ItemCard v-for="(entrance, i) in entrances" :key="entrance.id" title="Entrance" v-on:copy="copyEntrance(i)" v-on:delete="deleteEntrance(i)">
      <Input label="Address" v-model="entrance.address"/>
      <Input label="LngLat" v-model="entrance.geom_entrance"/>
      <Checkbox label="Pick from map" :form-style="true" :value="pickFromMap === i" v-on:change="onCheckboxChange($event, i)"/>
    </ItemCard>
    <MaterialButton label="Add entrance" v-on:click="addEntrance"/>
  </div>
</template>

<script>

import ItemCard from '@/components/editing/ItemCard';
import Input from '@/components/form/Input';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';
import Checkbox from '@/components/form/Checkbox';
import Toast from '@/utils/toast';
import MapboxHelper from '@/utils/mapboxHelper';

export default {
  name: 'GarageEntrances',
  components: {
    Checkbox,
    MaterialButton,
    ItemCard,
    Input,
  },
  props: {
    value: null,
  },
  data() {
    return {
      entrances: MapDataHelper.assignRandomIds(this.value),
      pickFromMap: null,
    };
  },
  watch: {
    entrances(entrances) {
      this.$emit('input', entrances);
    },
    pickFromMap(pickFromMap) {
      if (pickFromMap === null) {
        this.$store.dispatch('editor/onMapClickListener', null);
      } else {
        this.$store.dispatch('editor/onMapClickListener', (lngLat) => this.onMapClick(pickFromMap, lngLat));
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('editor/onMapClickListener', null);
  },
  methods: {
    addEntrance() {
      this.entrances.push(MapDataHelper.newEntrance());
      this.pickFromMap = this.entrances.length - 1;
    },
    copyEntrance(i) {
      this.entrances.push(MapDataHelper.copyItemWithId(this.entrances[i]));
      this.pickFromMap = this.entrances.length - 1;
    },
    deleteEntrance(i) {
      this.entrances.splice(i, 1);
      if (this.pickFromMap === i) {
        this.pickFromMap = null;
      }
    },
    onCheckboxChange(e, i) {
      this.pickFromMap = e.target.checked ? i : null;
    },
    onMapClick(idx, lngLat) {
      this.entrances.splice(idx, 1, MapDataHelper.newEntrance(null, `${lngLat.lng.toFixed(7)} ${lngLat.lat.toFixed(7)}`));
      MapboxHelper.reverseGeocode(
        lngLat,
        (result) => this.onReverseGeocodeSuccess(idx, result),
        this.onReverseGeocodeError,
      );
      return true;
    },
    onReverseGeocodeSuccess(idx, result) {
      this.entrances.splice(idx, 1, MapDataHelper.newEntrance(result.features[0].place_name, this.entrances[idx].geom_entrance));
    },
    onReverseGeocodeError() {
      Toast.danger('Error reverse geocoding entrance, please try again');
    },
  },
};

</script>

<style scoped>

#garage-entrances {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#garage-entrances > * {
  margin-bottom: 8px;
}

#garage-entrances > *:last-child {
  margin-bottom: 0;
}

</style>
