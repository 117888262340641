function compareNumbers(a, b) {
  return a - b;
}

function compareStrings(a, b) {
  return a.localeCompare(b);
}

const COMPARATORS = {
  string: compareStrings,
  number: compareNumbers,
};

export function createComparator(getter) {
  return (value1, value2) => {
    const a = getter(value1);
    const b = getter(value2);
    const comparator = COMPARATORS[typeof a];
    return comparator(a, b);
  };
}

export function combineComparators(...comparators) {
  return (a, b) => {
    for (const comparator of comparators) {
      const cmp = comparator(a, b);
      if (cmp !== 0) {
        return cmp;
      }
    }
    return 0;
  };
}

export function sortBy(list, ...comparators) {
  // Sorts in place
  return list.sort(combineComparators(...comparators));
}

export function minBy(list, ...comparators) {
  if (list.length === 0) {
    return undefined;
  }
  let min = list[0];
  const comparator = combineComparators(...comparators);
  for (const value of list.slice(1)) {
    if (comparator(value, min) < 0) {
      min = value;
    }
  }
  return min;
}
