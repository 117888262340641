import ServicesHelper from '@/utils/servicesHelper';
import sha512 from '@/utils/sha512';

class RegulationsHelper {
  static getBlockfaceOccupancy(blockfaceId, weekday, hour, success, error) {
    return ServicesHelper.get(
      `${process.env.VUE_APP_REGULATIONS_API_URL}/api/v4/occupancy/blockface?id=${blockfaceId}&weekday=${weekday}&hour=${hour}&token=${process.env.VUE_APP_REGULATIONS_ACCESS_TOKEN}&hash=${RegulationsHelper.buildHash({ id: blockfaceId })}`,
      success, error,
    );
  }

  static getSegmentOccupancy(segmentId, weekday, hour, success, error) {
    return ServicesHelper.get(
      `${process.env.VUE_APP_REGULATIONS_API_URL}/api/v4/occupancy/blockface?segmentId=${segmentId}&weekday=${weekday}&hour=${hour}&token=${process.env.VUE_APP_REGULATIONS_ACCESS_TOKEN}&hash=${RegulationsHelper.buildHash({ id: segmentId })}`,
      success, error,
    );
  }

  static getSpotInfo(segmentId, changesetId, success, error) {
    let startDate = new Date();
    startDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000));
    let url = `${process.env.VUE_APP_REGULATIONS_API_URL}/api/v4/regulations/segment?id=${segmentId}&time=${(startDate.toISOString())}&token=${process.env.VUE_APP_REGULATIONS_ACCESS_TOKEN}&hash=${RegulationsHelper.buildHash({ id: segmentId })}`;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone !== undefined) {
      url += `&zone=${timezone}`;
    }

    if (changesetId !== null) {
      url += `&changeset_id=${changesetId}`;
    }

    return ServicesHelper.get(url, success, error);
  }

  static computeCalendar(regulations, meterPrices, lng, lat, success, error) {
    return ServicesHelper.post(
      `${process.env.VUE_APP_REGULATIONS_API_URL}/api/v4/regulations/calendar`,
      {
        regulations,
        meter_prices: meterPrices,
        lng,
        lat,
      },
      success, error,
    );
  }

  /*
  Private methods, please don't call from outside
   */

  static buildHash(opts) {
    if (process.env.VUE_APP_REGULATIONS_ACCESS_TOKEN === null) {
      throw new Error('No regulations access token given');
    }

    let base = process.env.VUE_APP_REGULATIONS_ACCESS_TOKEN;
    if (opts.id !== undefined) {
      base += opts.id.toString();
    } else if (opts.lng !== undefined && opts.lat !== undefined) {
      base += opts.lng.toString() + opts.lat.toString();
    } else {
      throw new Error('Opts must have either id or lng and lat');
    }
    return btoa(RegulationsHelper.nxbHns(sha512(base))).replace(/\+/g, '-').replace(/\//g, '_');
  }

  static nxbHns(x) {
    const bytes = [];
    for (let i = 0; i < x.length - 1; i += 2) {
      bytes.push(parseInt(x.substr(i, 2), 16));
    }

    return String.fromCharCode(...bytes);
  }
}

export default RegulationsHelper;
