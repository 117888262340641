<template>
  <div id="container">
    <div class="center" v-if="state == 'loading'">
      <Spinner/>
    </div>
    <div v-else-if="state == 'error'">
      Something went wrong, try reloading this page.
    </div>
    <div id="main" v-else-if="state == 'ok'">
      <div class="col">
        <div class="panel">
          <div class="panel-element title">
            Account
          </div>
          <div class="panel-element">
            Username
            <div class="light">
              {{ user.username }}
            </div>
          </div>
          <div class="panel-element">
            First name
            <div class="light">
              {{ user.first_name }}
            </div>
          </div>
          <div class="panel-element">
            Last name
            <div class="light">
              {{ user.last_name }}
            </div>
          </div>
          <div class="panel-element">
            Email
            <div class="light">
              {{ user.email }}
            </div>
          </div>
          <div class="panel-element">
            <a v-on:click="this.showEditUserModal">
              Edit your information
            </a>
          </div>
        </div>
        <div class="panel">
          <div class="panel-element title">
            SPTC Balance
          </div>
          <div class="panel-element">
            SPTC available
            <div class="light">
              {{ format(points.nb_points) }}
            </div>
          </div>
          <div class="panel-element">
            SPTC pending
            <div class="light">
              {{ format(points.pending_points) }}
            </div>
          </div>
          <div
            class="panel-element"
            v-if="userIsMapMaker"
          >
            <a v-on:click="openRedeemModal()" v-bind:class="{ disabled: !canRedeem }">
              Redeem SPTC
              <template v-if="!canRedeem">
                ({{ format(reference.redeem_min_usd * reference.points_per_dollar) }} SPTC minimum required)
              </template>
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="panel">
          <div class="panel-element title">
            Error Rate Breakdown
          </div>
          <div
            class="panel-element"
            v-for="[key, value] in Object.entries(recentContributions)"
            :key="key"
          >
            {{ key[0].toUpperCase() + key.slice(1).split('_').join(' ') }}
            <div class="light">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
      <Modal
        ref="editUserModal"
        title="Account"
        negative-action="Cancel"
        v-on:negative-action="closeUserEditModal"
        positive-action="Update"
        v-on:positive-action="doUpdateUser"
      >
        <template v-slot:body>
          <Input label="First name" v-model="userForm.first_name"/>
          <Input label="Last name" v-model="userForm.last_name"/>
        </template>
      </Modal>
      <Modal
        ref="redeemModal"
        :title="[null, 'loading'].includes(redeemStatus) ? `Redeem ${points.nb_points} SPTC` : ''"
        :negative-action="redeemStatus === null ? 'Cancel' : null"
        v-on:negative-action="$refs.redeemModal.closeModal()"
        :positive-action="redeemModalPositiveLabel"
        v-on:positive-action="doRedeem"
        :positiveActionDisabled="redeemInfo === null || redeemInfo === 'loading'"
      >
        <template v-slot:body>
          <div id="redeemModalBody" v-if="redeemStatus === null">
            <img :src="require('@/assets/images/dollars.svg')" alt="dollars" width="120"/>
            <Input
              label="Email"
              v-model="user.email"
              type="static"
            />
            <Input
              label="Points"
              v-model="points.nb_points"
              type="static"
            />
            <Input
              label="USD"
              v-model="pointsUSD"
              type="static"
            />
            <Select
              label="Currency"
              :options="reference.redeem_currencies"
              v-model="redeemCurrency"
            />
            <template v-if="redeemInfoError !== null">
              <p class="red">
                Error:
                {{ redeemInfoError.error }}
              </p>
            </template>
            <template v-if="redeemInfo === null"></template>
            <template v-else-if="redeemInfo === 'loading'">
              <div class="center">
                <Spinner/>
              </div>
            </template>
            <template v-else>
              <Input
                label="Change rate"
                v-model="redeemInfo.rate"
                type="static"
              />
              <Input
                label="Fee percentage"
                v-model="feePercentage"
                type="static"
              />
              <Input
                :label="redeemCurrency"
                v-model="redeemInfo.targetAmount"
                type="static"
              />
            </template>
          </div>
          <div id="redeemModalBody" v-else-if="redeemStatus === 'loading'">
            <div class="center">
              <Spinner/>
            </div>
          </div>
          <div id="redeemModalBody" v-else-if="redeemStatus === 'error'">
            Something went wrong, please try again.
          </div>
          <div id="redeemModalBody" v-else-if="redeemStatus === 'ok'">
            <img :src="require('@/assets/images/sent.svg')" alt="sent" width="120"/>
            <h3>
              Transfer sent.
            </h3>
            <p>
              You should get an email from Wise to set up the transfer details soon.
            </p>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ApiHelper from '@/utils/apiHelper';
import Modal from '@/components/Modal';
import Input from '@/components/form/Input';
import Select from '@/components/form/Select';
import Spinner from '@/components/Spinner';
import AuthHelper from '@/utils/authHelper';
import MapDataHelper from '@/utils/mapDataHelper';

const numberFormat = new Intl.NumberFormat('en-US');

export default {
  components: {
    Modal,
    Input,
    Select,
    Spinner,
  },
  data() {
    return {
      state: 'loading', // 'loading' | 'error' | 'ok'
      user: null,
      points: null,
      reference: null,
      recentContributions: null,
      userForm: null,
      redeemCurrency: null, // null | string
      redeemInfo: null, // null | 'loading' | { rate, feePercentage, targetAmount }
      redeemStatus: null, // null | 'loading' | 'error' | 'ok'
    };
  },
  async mounted() {
    try {
      const [points, userResponse, reference] = await Promise.all([
        ApiHelper.requestAsync('get', '/api/v3/users/points'),
        ApiHelper.requestAsync('get', '/api/v3/users/me/editor'),
        ApiHelper.requestAsync('get', '/api/v3/reference/editor'),
      ]);
      AuthHelper.handleUser(userResponse.user);
      this.user = userResponse.user;
      this.points = points;
      this.reference = reference;
      this.recentContributions = (await MapDataHelper.requestAsync(
        'get',
        '/me/recent_contributions',
      )).contributions;
      delete this.recentContributions.user_id;
      this.state = 'ok';
    } catch (error) {
      console.error(error);
      this.state = 'error';
    }
  },
  computed: {
    ...mapGetters(
      'editor',
      [
        'apiReference',
      ],
    ),
    feePercentage() {
      return `${(this.redeemInfo.feePercentage * 100).toFixed(2)}%`;
    },
    pointsUSD() {
      return `$${(this.points.nb_points / this.reference.points_per_dollar).toFixed(2)}`;
    },
    redeemModalPositiveLabel() {
      if (this.redeemStatus === null) {
        return 'Redeem';
      }
      if (this.redeemStatus === 'loading') {
        return null;
      }
      return 'Ok';
    },
    canRedeem() {
      return this.points.nb_points >= this.reference.redeem_min_usd * this.reference.points_per_dollar;
    },
    userIsMapMaker() {
      const newbieMapMakerRole = this.apiReference.roles.find(
        (role) => role.slug === 'newbie-map-maker',
      );
      const user = AuthHelper.getUser();
      return user.role.slug === 'blocked-map-maker' || user.role.rank >= newbieMapMakerRole.rank;
    },
  },
  watch: {
    redeemCurrency() {
      this.onRedeemCurrencySelect();
    },
  },
  methods: {
    format(n) {
      return numberFormat.format(n);
    },
    openRedeemModal() {
      if (!this.canRedeem) {
        return;
      }
      this.redeemCurrency = null;
      this.redeemInfo = null;
      this.redeemInfoError = null;
      this.redeemStatus = null;
      this.$refs.redeemModal.openModal();
    },
    resetUserForm() {
      this.userForm = {
        username: this.user.username,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
      };
    },
    async onRedeemCurrencySelect() {
      if (this.redeemCurrency === null) {
        this.redeemInfo = null;
      } else {
        this.redeemInfo = 'loading';
        try {
          this.redeemInfo = await ApiHelper.requestAsync(
            'post',
            '/api/v3/users/me/redeem',
            {
              currency: this.redeemCurrency,
              pretend: true,
            },
          );
        } catch (error) {
          this.redeemInfoError = error.response.body;
          this.redeemInfo = null;
        }
      }
    },
    async doRedeem() {
      if (['ok', 'error'].includes(this.redeemStatus)) {
        this.$refs.redeemModal.closeModal();
        return;
      }
      this.redeemStatus = 'loading';
      try {
        await ApiHelper.requestAsync(
          'post',
          '/api/v3/users/me/redeem',
          {
            currency: this.redeemCurrency,
            pretend: false,
          },
        );
        this.points = await ApiHelper.requestAsync('get', '/api/v3/users/points');
        this.redeemStatus = 'ok';
      } catch (error) {
        this.redeemStatus = 'error';
      }
    },
    async doUpdateUser() {
      try {
        const response = await ApiHelper.requestAsync(
          'patch',
          '/api/v3/users/me',
          this.userForm,
        );
        this.state = 'ok';
        this.user = response.user;
      } catch (error) {
        this.state = 'error';
      }
      this.$refs.editUserModal.closeModal();
    },
    showEditUserModal() {
      this.resetUserForm();
      this.$refs.editUserModal.openModal();
    },
    closeUserEditModal() {
      this.$refs.editUserModal.closeModal();
    },
  },
};
</script>

<style scoped>

#container {
  width: 100%;
  overflow: auto;
}

.center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
}

#main {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 40px;
}

.panel {
  background-color: white;
  border: 1px solid #CED6DE;
  border-radius: 10px;
  width: 400px;
  margin-top: 40px;
}

.panel-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
}

.panel-element a {
  cursor: pointer;
}

.panel-element:not(:last-child) {
  border-bottom: 1px solid #CED6DE;
}

.title {
  font-weight: bold;
}

.light {
  color: #545E69;
}

.red {
  color: #FF4541;
}

.red:hover {
  color: #FF4541;
}

#redeemModalBody {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 400px;
}

#redeemModalBody img {
  margin-left: auto;
  margin-right: auto;
}

#redeemModalBody p, #redeemModalBody h3 {
  text-align: center;
}

a.disabled {
  cursor: not-allowed;
}
</style>
