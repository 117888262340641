<template>
  <div class="sheet-card">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'SheetCard',
};
</script>

<style scoped>

.sheet-card {
  width: 100%;
  max-width: 375px;
  background-color: white;
  margin: 12px 0 0 0;
  border-top: 1px solid #CED6DE;
}

</style>
