<template>
  <div id="garage-monthly-rates">
    <ItemCard v-for="(rate, i) in rates" :key="rate.id" title="Rate" v-on:copy="copyRate(i)" v-on:delete="deleteRate(i)">
      <Input type="number" label="Price" v-model="rate.price" :step="1"/>
      <Input type="number" label="Deposit" v-model="rate.deposit" :step="1"/>
      <Input type="number" label="One time fee" v-model="rate.one_time_fee" :step="1"/>
      <Select label="Type" :options="monthlyTypes" label-key="label" value-key="id" v-model="rate.monthly_type_id"/>
      <Input type="textarea" label="Description" v-model="rate.description"/>
      <Input type="textarea" label="Checkout msg" v-model="rate.checkout_msg"/>
      <Select label="Availability" :options="['Yes', 'No']" v-model="rate.available"/>
    </ItemCard>
    <MaterialButton label="Add monthly rate" v-on:click="addRate"/>
  </div>
</template>

<script>

import ItemCard from '@/components/editing/ItemCard';
import MapDataHelper from '@/utils/mapDataHelper';
import Select from '@/components/form/Select';
import Input from '@/components/form/Input';
import MaterialButton from '@/components/MaterialButton';
import Toast from '@/utils/toast';

export default {
  name: 'GarageMonthlyRates',
  components: {
    MaterialButton,
    Input,
    Select,
    ItemCard,
  },
  props: {
    value: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      rates: MapDataHelper.assignRandomIds(this.value),
      monthlyTypes: [],
    };
  },
  mounted() {
    MapDataHelper.getMonthlyTypes((request, response) => { this.monthlyTypes = response.monthly_types; }, () => Toast.danger('Error loading monthly types'));
  },
  watch: {
    rates(rates) {
      this.$emit('input', rates);
    },
  },
  methods: {
    addRate() {
      this.rates.push(MapDataHelper.newGarageMonthlyRate());
    },
    copyRate(i) {
      this.rates.push(MapDataHelper.copyItemWithId(this.rates[i]));
    },
    deleteRate(i) {
      this.rates.splice(i, 1);
    },
  },
};

</script>

<style scoped>

#garage-monthly-rates {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#garage-monthly-rates > * {
  margin-bottom: 8px;
}

#garage-monthly-rates > *:last-child {
  margin-bottom: 32px;
}

</style>
