<template>
  <div id="meter-payment">
    <ItemCard v-for="(details, i) in allDetails" :key="details.id" title="Provider" v-on:copy="copyDetails(i)" v-on:delete="deleteDetails(i)">
      <div class="editing-field">
        <vue-multiselect
          v-model="details.provider_id"
          :options="providerIds"
          placeholder="Payment provider"
          :custom-label="providerLabel"
        />
      </div>
      <div class="editing-field" v-for="(_, j) in details.payment_id" :key="j">
        <input v-focus-on-create.empty type="text" v-model="details.payment_id[j]"/>
        <ImageButton v-if="j === 0" :src="require('@/assets/images/add-black-24dp.svg')" title="Add payment id" v-on:click="addPaymentId(i)"/>
        <ImageButton v-if="j > 0" :src="require('@/assets/images/delete-black-24dp.svg')" title="Delete payment id" v-on:click="deletePaymentId(i, j)"/>
      </div>
    </ItemCard>
    <MaterialButton label="Add payment details" v-on:click="addDetails"/>
  </div>
</template>

<script>

import vueMultiselect from 'vue-multiselect';
import ItemCard from '@/components/editing/ItemCard';
import ImageButton from '@/components/ImageButton';
import focusOnCreate from '@/utils/focusOnCreate';
import MapDataHelper from '@/utils/mapDataHelper';
import Toast from '@/utils/toast';
import MaterialButton from '@/components/MaterialButton';

export default {
  name: 'MeterPayment',
  components: {
    ImageButton,
    vueMultiselect,
    ItemCard,
    MaterialButton,
  },
  directives: {
    focusOnCreate,
  },
  props: {
    value: null,
  },
  data() {
    return {
      providers: (this.value || []).map((d) => ({
        id: d.provider_id,
        provider_name: d.provider_name,
      })),
      allDetails: MapDataHelper.assignRandomIds(this.value || []),
    };
  },
  computed: {
    providerIds() {
      return this.providers.map((p) => p.id);
    },
  },
  watch: {
    allDetails: {
      handler(allDetails) {
        this.$emit('input', allDetails);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadProviders();
  },
  methods: {
    loadProviders() {
      MapDataHelper.getMeterProviders(this.onGotMeterProviders, this.onMeterProvidersError);
    },
    onGotMeterProviders(request, result) {
      this.providers = result.providers;
    },
    onMeterProvidersError() {
      Toast.danger('Error getting meter providers');
    },
    providerLabel(id) {
      return this.providers.find((p) => p.id === id).provider_name;
    },
    addDetails() {
      this.allDetails.push(MapDataHelper.newMeterPaymentDetails());
    },
    copyDetails(i) {
      this.allDetails.push(MapDataHelper.copyItemWithId(this.allDetails[i]));
    },
    deleteDetails(i) {
      this.allDetails.splice(i, 1);
    },
    addPaymentId(i) {
      this.allDetails[i].payment_id.push('');
    },
    deletePaymentId(i, j) {
      this.allDetails[i].payment_id.splice(j, 1);
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style scoped>

#meter-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#meter-payment > * {
  margin-bottom: 8px;
}

#meter-payment > *:last-child {
  margin-bottom: 32px;
}

</style>
