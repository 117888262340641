import Vue from 'vue/dist/vue.esm';

class Toast {
  static DURATION_INDEFINITE = Object.freeze(0);

  static info(message, duration = 3000) {
    return Vue.toasted.show(message, {
      className: 'sa-toast',
      duration,
    });
  }

  static danger(message, action) {
    return Vue.toasted.show(message, {
      className: 'sa-toast sa-toast-danger',
      duration: 3000,
      action,
    });
  }
}

export default Toast;
