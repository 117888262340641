<template>
  <div id="sv" ref="sv">
    <div id="sv-hint" v-if="this.streetViewCamera.position === null">
      <span>
        CTRL+Click anywhere on the map (CMD+Click on MacOS) to set Street View position
      </span>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import gmapsInit from '@/utils/gmaps';

export default {
  name: 'StreetView',
  data() {
    return {
      google: null,
      sv: null,
    };
  },
  computed: {
    ...mapGetters('editor', ['streetViewCamera', 'streetViewPositionClick']),
  },
  async mounted() {
    this.google = await gmapsInit();
    this.sv = new this.google.maps.StreetViewPanorama(
      this.$refs.sv,
      {
        position: this.streetViewCamera.position,
        zoomControlOptions: {
          position: this.google.maps.ControlPosition.LEFT_TOP,
        },
        panControlOptions: {
          position: this.google.maps.ControlPosition.LEFT_TOP,
        },
      },
    );

    this.sv.addListener('position_changed', this.onPositionChanged);
    this.sv.addListener('pov_changed', this.onPovChanged);
  },
  watch: {
    streetViewPositionClick({ lng, lat }) {
      this.onMapClicked(lng, lat);
    },
  },
  methods: {
    resize() {
      if (this.google !== null && this.sv !== null) {
        this.google.maps.event.trigger(this.sv, 'resize');
      }
    },
    onMapClicked(lng, lat) {
      const currentPosition = this.sv.getPosition();
      if (
        currentPosition !== null
        && Math.abs(lng - currentPosition.lng()) < 0.00001
        && Math.abs(lat - currentPosition.lat()) < 0.00001
      ) {
        return;
      }
      this.sv.setPosition({ lng, lat });
      this.sv.setPov({
        ...this.sv.getPov(),
        heading: this.streetViewCamera.heading || this.sv.getPov().heading,
      });
      this.$store.dispatch('editor/streetViewPosition', { lng, lat });
    },
    onPositionChanged() {
      const position = this.sv.getPosition();
      this.$store.dispatch('editor/streetViewPosition', {
        lng: position.lng(),
        lat: position.lat(),
      });
    },
    onPovChanged() {
      this.$store.dispatch('editor/streetViewHeading', this.sv.getPov().heading);
    },
  },
};

</script>

<style scoped>

#sv {
  width: 100%;
  height: 100%;
  background: black;
}

#sv-hint {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  pointer-events: none;
}

#sv-hint > span {
  width: 80%;
}

</style>
