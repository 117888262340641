<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal-backdrop" v-on:click="closeModal()"/>
      <div class="modal-dialog">
        <div class="modal-title">
          <span>{{ title }}</span>
          <button type="button" class="modal-close" v-on:click="closeModal()">
            <img :src="require('@/assets/images/close-black-24dp.svg')" alt="Close modal"/>
          </button>
        </div>
        <div class="modal-body">
          <template v-if="message">
            {{ message }}
          </template>
          <template v-else>
            <slot name="body"/>
          </template>
        </div>
        <div class="modal-actions">
          <MaterialButton v-if="negativeAction" :label="negativeAction" v-on:click="$emit('negative-action', $event)" :disabled="actionsDisabled"/>
          <MaterialButton v-if="positiveAction" :label="positiveAction" v-on:click="$emit('positive-action', $event)" :disabled="actionsDisabled || positiveActionDisabled" :color="positiveActionColor"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import MaterialButton from '@/components/MaterialButton';

export default {
  name: 'Modal',
  components: { MaterialButton },
  props: {
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    positiveAction: {
      type: String,
      default: null,
    },
    negativeAction: {
      type: String,
      default: null,
    },
    actionsDisabled: {
      type: Boolean,
      default: false,
    },
    positiveActionDisabled: {
      type: Boolean,
      default: false,
    },
    positiveActionColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    },
  },
};

</script>

<style scoped>

.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1337;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1335;
}

.modal-dialog {
  background-color: #ffffff;
  position: relative;
  width: 600px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 1336;
}

.modal-title {
  padding: 16px 16px 16px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.modal-close {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-close:hover {
  opacity: 1;
}

.modal-body {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.modal-actions {
  padding: 10px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/*noinspection CssUnusedSymbol*/
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

/*noinspection CssUnusedSymbol*/
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
