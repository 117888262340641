<template>
  <div id="regulations-editing" :style="{'pointer-events': saving ? 'none' : 'unset'}">
    <div id="regulations-summaries">
      <SheetTitle back-button="back" :title="title" action="Save" v-on:back-clicked="cancel" v-on:action-clicked="saveChanges" :action-disabled="loading"/>
      <template v-if="loading">
        Loading...
      </template>
      <template v-else>
        <div>
          <SheetCard v-for="regulation in activeRegulations" :key="regulation" class="regulation-card">
            <SheetTitle :title="regulation" :title-class="regulationTitleClass(regulation)" :action="regulation === 'No Data' ? (updatedRegulation(regulation) === null ? null : 'Delete') : 'Edit'" v-on:action-clicked="onActiveRegulationClicked(regulation)"/>
            <Calendar :initial-regulation="initialRegulation(regulation)" :updated-regulation="updatedRegulation(regulation)" section="months"/>
            <Calendar :initial-regulation="initialRegulation(regulation)" :updated-regulation="updatedRegulation(regulation)" section="prices"/>
          </SheetCard>
        </div>
        <SheetCard>
          <SheetCardRow v-if="activeRegulations.length === 0 || activeRegulations.length === 1 && activeRegulations[0] === 'No Data'">
            <MaterialButton label="Tow Away Anytime" v-on:click="addAnytimeRegulation('TowAway')"/>
            <MaterialButton label="No Parking Anytime" v-on:click="addAnytimeRegulation('NoParking')"/>
            <MaterialButton v-if="typeof selection.id === 'string'" label="No Data" v-on:click="addAnytimeRegulation('NoData')"/>
          </SheetCardRow>
          <SheetCardRow>
            <MaterialButton
              v-for="regulation in unusedRegulations"
              :key="regulation"
              v-on:click="addRegulation(regulation)"
              :label="REGULATIONS[regulation]"
            />
          </SheetCardRow>
          <SheetCardRow id="regulation-copy">
            <div id="regulation-copy-form">
              <label id="regulation-copy-form-segment-id">Copy regulations from
                <input type="number" ref="sourceSegmentId" placeholder="segment id" min="1"/></label>
              <Checkbox v-model="copyMeterPaymentIds" label="Include meter payment ids"/>
            </div>
            <MaterialButton label="Copy regulations" v-on:click="copyFromSegment($refs.sourceSegmentId.value)"/>
          </SheetCardRow>
        </SheetCard>
        <SheetCard>
          <SheetTitle title="Comment"/>
          <div style="padding: 16px">
            <Input type="textarea" v-model="spotData.comment" :full-width="true"/>
          </div>
        </SheetCard>
      </template>
    </div>
    <div v-if="selectedRegulation !== null" id="regulation-form">
      <span class="selected-regulation-title">
        {{ REGULATIONS[selectedRegulation] }}
        <ImageButton :src="require('@/assets/images/close-black-24dp.svg')" title="Close" v-on:click="selectedRegulation = null"/>
      </span>
      <ItemCard v-for="(regulation, index) in selectedRegulations" :key="`${selectedRegulation}-${index}`" title="Schedule" v-on:copy="copySchedule(index)" v-on:delete="deleteSchedule(index)">
        <Schedule :regulation="regulation" :segment-id="typeof selection.id === 'string'  ? null : selection.id" :lng-lat="selection.features.length > 0 ? selection.features[0].geometry.coordinates[0] : null"/>
      </ItemCard>
      <MaterialButton label="Add schedule" v-on:click="addSchedule"/>
      <template v-if="selectedRegulation === 'MeterHour'">
        <div class="divider"/>
        <span class="selected-regulation-title">Prices</span>
        <MeterPrices ref="meterPrices" v-model="meterPrices"/>
        <MaterialButton label="Add price" v-on:click="$refs.meterPrices.addPrice()"/>
        <div class="divider"/>
        <span class="selected-regulation-title">Payment</span>
        <MeterPayment v-model="spotData.payment_details"/>
      </template>
    </div>
  </div>
</template>

<script>
import MapDataHelper from '@/utils/mapDataHelper';
import SheetCard from '@/components/SheetCard';
import SheetCardRow from '@/components/SheetCardRow';
import SheetTitle from '@/components/SheetTitle';
import Toast from '@/utils/toast';
import Checkbox from '@/components/form/Checkbox';
import Schedule from '@/components/editing/Schedule';
import ItemCard from '@/components/editing/ItemCard';
import MeterPrices from '@/components/editing/MeterPrices';
import ImageButton from '@/components/ImageButton';
import Calendar from '@/components/editing/Calendar';
import MeterPayment from '@/components/editing/MeterPayment';
import EditSheetMixin from '@/components/editing/EditSheetMixin';
import MaterialButton from '@/components/MaterialButton';
import Input from '@/components/form/Input';

export default {
  name: 'EditOnStreetSheet',
  mixins: [
    EditSheetMixin,
  ],
  components: {
    Input,
    MaterialButton,
    MeterPayment,
    SheetCard,
    SheetCardRow,
    SheetTitle,
    Checkbox,
    Schedule,
    ItemCard,
    MeterPrices,
    Calendar,
    ImageButton,
  },
  props: {
    title: null,
  },
  data() {
    return {
      REGULATIONS: MapDataHelper.REGULATIONS,
      selectedRegulation: null,
      copyMeterPaymentIds: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'spotData.regulations': function (regulations) {
      if (regulations.length > 1 && regulations.find((r) => r.name === 'NoData') !== undefined) {
        this.spotData.regulations = regulations.filter((r) => r.name !== 'NoData');
      }
    },
  },
  computed: {
    selectedRegulations() {
      return this.spotData.regulations.filter((r) => r.name === this.selectedRegulation);
    },
    activeRegulations() {
      return [...new Set(this.initialCalendar.map((item) => item.name).concat(this.updatedCalendar.map((item) => item.name)))];
    },
    unusedRegulations() {
      return Object.keys(MapDataHelper.REGULATIONS).filter((k) => k !== 'NoData' && this.initialCalendar.find((item) => item.name === MapDataHelper.REGULATIONS[k]) === undefined && this.updatedCalendar.find((item) => item.name === MapDataHelper.REGULATIONS[k]) === undefined);
    },
    meterPrices: {
      get() {
        // First, group prices by days and hours to have all steps of after_duration together
        const mergedDurationPrices = {};
        for (const price of this.spotData.meters_prices) {
          const cmpKey = `${price.day}-${price.from_hour}-${price.from_min}-${price.to_hour}-${price.to_min}`;
          if (cmpKey in mergedDurationPrices) {
            const existingPrice = mergedDurationPrices[cmpKey];

            let pos = -1;
            if (price.after_duration === null) {
              pos = 0;
            } else {
              let j = existingPrice.after_durations.length;
              for (; j > 0; j--) {
                if (existingPrice.after_durations[j - 1] === null || existingPrice.after_durations[j - 1] < price.after_duration) {
                  break;
                }
              }

              pos = j;
            }

            existingPrice.rates.splice(pos, 0, price.rate);
            existingPrice.after_durations.splice(pos, 0, price.after_duration);
          } else {
            const priceCopy = JSON.parse(JSON.stringify(price));
            priceCopy.rates = [priceCopy.rate];
            delete priceCopy.rate;
            priceCopy.after_durations = [priceCopy.after_duration];
            delete priceCopy.after_duration;
            mergedDurationPrices[cmpKey] = priceCopy;
          }
        }

        // Then, group merged prices by hours to have all days together
        const mergedPrices = {};
        for (let i = 0; i < Object.values(mergedDurationPrices).length; i++) {
          let price = Object.values(mergedDurationPrices)[i];
          const cmpKey = `${price.from_hour}-${price.from_min}-${price.to_hour}-${price.to_min}-${price.rates}-${price.after_durations}`;
          if (Object.prototype.hasOwnProperty.call(mergedPrices, cmpKey)) {
            mergedPrices[cmpKey].days.push(price.day);
          } else {
            price = JSON.parse(JSON.stringify(price));
            price.days = [price.day];
            delete price.day;
            mergedPrices[cmpKey] = price;
          }
        }

        const prices = Object.values(mergedPrices);
        prices.sort((a, b) => {
          const dayDiff = a.days[0] - b.days[0];
          return dayDiff === 0 ? a.from_hour - b.from_hour : dayDiff;
        });

        return prices;
      },
      set(mergedPrices) {
        const metersPrices = [];
        mergedPrices.forEach((mergedPrice) => {
          mergedPrice.days.forEach((day) => {
            for (let i = 0; i < mergedPrice.rates.length; i++) {
              const price = JSON.parse(JSON.stringify(mergedPrice));
              price.day = day;
              delete price.days;
              price.rate = mergedPrice.rates[i];
              delete price.rates;
              price.after_duration = mergedPrice.after_durations[i];
              delete price.after_durations;
              metersPrices.push(price);
            }
          });
        });

        this.spotData.meters_prices = metersPrices;
      },
    },
  },
  methods: {
    regulationTitleClass(regulation) {
      const isInInitial = this.initialRegulation(regulation) !== null;
      const isInUpdated = this.updatedRegulation(regulation) !== null;
      return {
        'item-added': !isInInitial && isInUpdated,
        'item-removed': isInInitial && !isInUpdated,
      };
    },
    initialRegulation(regulation) {
      return this.initialCalendar.find((item) => item.name === regulation) || null;
    },
    updatedRegulation(regulation) {
      return this.updatedCalendar !== null ? this.updatedCalendar.find((item) => item.name === regulation) || null : null;
    },
    onActiveRegulationClicked(regulation) {
      if (regulation === 'No Data') {
        this.spotData.regulations = this.spotData.regulations.filter((r) => r.name !== 'NoData');
      } else {
        this.selectedRegulation = Object.keys(MapDataHelper.REGULATIONS).find((k) => MapDataHelper.REGULATIONS[k] === regulation);
      }
    },
    addRegulation(regulation) {
      this.selectedRegulation = regulation;
      this.addSchedule();
    },
    addAnytimeRegulation(regulation) {
      this.spotData.regulations.push(MapDataHelper.newSchedule(regulation, {
        startHour: 0,
        startMinute: 0,
        endHour: 24,
        endMinute: 0,
      }));
    },
    copyFromSegment(segmentIdStr) {
      const segmentId = parseInt(segmentIdStr, 10);
      if (Number.isNaN(segmentId) || segmentId <= 0) {
        Toast.danger('Invalid segment id');
        return;
      }

      MapDataHelper.getSpotData(segmentId, this.onGotSpotDataForCopy, this.onSpotDataForCopyError);
    },
    onGotSpotDataForCopy(request, result) {
      const updates = {
        regulations: result.data.regulations,
        meters_prices: result.data.meters_prices,
      };

      if (this.copyMeterPaymentIds) {
        updates.payment_details = result.data.payment_details;
      }

      this.spotData = { ...this.spotData, ...updates };
    },
    onSpotDataForCopyError(request, result) {
      Toast.danger(`Could not get other segment data for copy: ${result.status}`);
    },
    addSchedule() {
      if (MapDataHelper.isNoData(this.spotData)) {
        this.spotData.regulations = [MapDataHelper.newSchedule(this.selectedRegulation)];
      } else {
        this.spotData.regulations.push(MapDataHelper.newSchedule(this.selectedRegulation));
      }
    },
    copySchedule(n) {
      let j = 0;
      for (let i = 0; i < this.spotData.regulations.length; i++) {
        const r = this.spotData.regulations[i];
        if (r.name === this.selectedRegulation) {
          if (j++ === n) {
            this.spotData.regulations.push(JSON.parse(JSON.stringify(r)));
            return;
          }
        }
      }

      throw new Error('wrong index given for regulation to copy');
    },
    deleteSchedule(n) {
      let i = 0;
      this.spotData.regulations = this.spotData.regulations.filter((r) => {
        // Remove the Nth regulation of the selected type
        if (r.name === this.selectedRegulation) {
          if (i++ === n) {
            return false;
          }
        }
        return true;
      });
      this.selectedRegulations.splice(i, 1);
    },
  },
};
</script>

<style scoped>

#regulations-editing {
  height: 100%;
  display: flex;
  flex-direction: row;
}

#regulations-summaries {
  width: 375px;
  height: 100%;
  z-index: 1;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
}

.selected-regulation-title {
  font-size: 16px;
  font-weight: bold;
  padding: 0 0 0 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.regulation-card {
  margin-top: 0;
}

.regulation-card:first-child {
  margin-top: 12px;
}

.regulation-card:last-child {
  border-bottom: 1px solid #CED6DE;
}

#regulation-form {
  width: 375px;
  padding: 16px;
  user-select: none;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

#regulation-form::after {
  content: "";
  display: inline-table;
  height: 120px;
  width: 100%;
}

#regulation-form > button {
  margin-top: 12px;
}

#regulation-copy {
  flex-direction: row;
}

#regulation-copy-form {
  min-width: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

#regulation-copy-form > * {
  margin-top: 8px;
}

#regulation-copy-form > *:first-child {
  margin-top: 0;
}

#regulation-copy-form-segment-id {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

#regulation-copy-form input {
  min-width: 0;
  flex-basis: 0;
  flex-grow: 1;
  margin-left: 8px;
}

input[type=number] {
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background: white;
  height: 32px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: none;
  padding: 0 8px;
}

input[type=number]:focus {
  border: 1px solid #1C9BE6;
}

</style>

<!--suppress CssUnusedSymbol -->
<style>

#regulations-editing .select-wrapper {
  position: relative;
}

#regulations-editing .select-wrapper::after {
  content: "";
  background-image: url('~@/assets/images/arrows-select-black.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 50%;
  display: inline-block;
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
  pointer-events: none;
}

#regulations-editing .vue__time-picker.time-picker::after {
  content: "";
  background-image: url('~@/assets/images/arrows-select-black.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 49%;
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
  pointer-events: none;
}

#regulations-editing .select-wrapper.disabled::after {
  opacity: 0.5;
}

#regulations-editing select {
  background-color: white;
  border: 1px solid #CED6DE;
  width: 100%;
  height: 32px;
  font-size: 14px;
  border-radius: 7px;
  padding: 0 4px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
}

#regulations-editing select:focus {
  border: 1px solid #1C9BE6;
}

#regulations-editing select:disabled {
  color: #545E69;
  border: 1px solid #CED6DE;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #CED6DE;
  margin: 8px 0 16px 0;
  flex-shrink: 0;
}

.editing-form > * {
  margin-bottom: 8px;
}

.editing-form > *:last-child {
  margin-bottom: 0;
}

.editing-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editing-field > span {
  margin: 0 8px;
}

.editing-field > span:first-child {
  margin-left: 0;
}

.editing-field > span:last-child {
  margin-right: 0;
}

.editing-field .editing-datepicker-wrapper {
  flex-basis: 0;
  flex-grow: 1;
}

.editing-field .editing-datepicker-input {
  border: 1px solid #CED6DE;
  border-radius: 7px;
  height: 32px;
  color: #212529;
  width: 100%;
  padding: 0 8px 2px 8px;
  background-color: white;
  right: 0;
  cursor: default;
}

.editing-field .editing-datepicker-input:focus {
  border: 1px solid #1C9BE6;
}

.editing-field .editing-datepicker-input:disabled {
  border: 1px solid #CED6DE;
}

.editing-field .editing-datepicker-calendar {
  right: 0;
}

.editing-field .multiselect {
  min-height: 32px;
}

.editing-field .multiselect__tags {
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editing-field .multiselect__tags:focus {
  border: 1px solid #1C9BE6;
}

.editing-field .multiselect__tags:disabled, .editing-field .multiselect__tags:disabled:hover {
  border: 1px solid #CED6DE;
}

.editing-field .multiselect__tags-wrap {
  display: flex;
  flex-direction: row;
}

.editing-field .multiselect__tags-wrap span {
  margin: 0 4px 0 0;
}

.editing-field .multiselect__select {
  height: 32px;
}

.editing-field .multiselect__placeholder {
  margin: 0;
  padding: 0;
}

.editing-field .multiselect__input {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.editing-field .multiselect__single {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.editing-field .vue__time-picker.time-picker {
  width: 100%;
}

.editing-field input.display-time {
  width: 100%;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background-color: white;
}

.editing-field input.display-time:focus {
  border: 1px solid #1C9BE6;
}

.editing-field > input[type=text] {
  width: 100%;
  height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background-color: white;
  padding: 0 6px;
}

.editing-field > input[type=text]:focus {
  border: 1px solid #1C9BE6;
}

/*noinspection CssUnusedSymbol*/
.item-removed {
  color: #ff4541;
  text-decoration: line-through;
}

/*noinspection CssUnusedSymbol*/
.item-added {
  color: #1dcf32;
}

</style>
