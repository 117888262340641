<template>
  <div id="garage-schedule">
    <ItemCard v-for="(regulation, i) in regulations" :key="regulation.id" title="Opening hours" v-on:copy="copyRegulation(i)" v-on:delete="deleteRegulation(i)" :deletable="regulations.length > 1">
      <Schedule :regulation="regulation" :segment-id="typeof selection.id === 'string' ? null : selection.id" :lng-lat="selection.features.length > 0 ? selection.features[0].geometry.coordinates[0] : null"/>
    </ItemCard>
    <MaterialButton label="Add opening hours" v-on:click="addRegulation"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ItemCard from '@/components/editing/ItemCard';
import Schedule from '@/components/editing/Schedule';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';

export default {
  name: 'GarageSchedule',
  components: {
    MaterialButton,
    Schedule,
    ItemCard,
  },
  props: {
    value: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      regulations: MapDataHelper.assignRandomIds(this.value),
    };
  },
  computed: {
    ...mapGetters('editor', ['selection']),
  },
  watch: {
    regulations(regulations) {
      this.$emit('input', regulations);
    },
  },
  methods: {
    addRegulation() {
      this.regulations.push(MapDataHelper.newSchedule('Garage'));
    },
    copyRegulation(i) {
      this.regulations.push(MapDataHelper.copyItemWithId(this.regulations[i]));
    },
    deleteRegulation(i) {
      this.regulations.splice(i, 1);
    },
  },
};

</script>

<style scoped>

#garage-schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#garage-schedule > * {
  margin-bottom: 8px;
}

#garage-schedule > *:last-child {
  margin-bottom: 32px;
}

</style>
