<template>
  <div id="garage-deals">
    <ItemCard v-for="(deal, i) in deals" :key="deal.id" title="Deal" v-on:copy="copyDeal(i)" v-on:delete="deleteDeal(i)">
      <Select label="Display name" :options="DEAL_DISPLAY_NAMES" v-model="deal.display_name"/>
      <DaySelection label="Days" :value="getSelectedDays(deal)" v-on:input="setSelectedDays($event, deal)"/>
      <TimeSelection :range="true" label="In from" :value="getInTimes(deal)" v-on:input="setInTimes($event, deal)" :optional="true"/>
      <Checkbox v-model="deal.in_is_next_day" label="In to is next day" :form-style="true"/>
      <TimeSelection label="Out by" :value="getOutByTimes(deal)" v-on:input="setOutByTimes($event, deal)" :optional="true"/>
      <TimeSelection label="Out after" :value="getOutAfterTimes(deal)" v-on:input="setOutAfterTimes($event, deal)" :optional="true"/>

      <DurationSelection
        label="Min stay"
        v-model="deal.min_stay"
      />

      <DurationSelection
        label="Max stay"
        v-model="deal.max_stay"
      />

      <Input type="number" label="Price" v-model="deal.price" :step=".1"/>
    </ItemCard>
    <MaterialButton label="Add deal" v-on:click="addDeal"/>
  </div>
</template>

<script>

import Checkbox from '@/components/form/Checkbox';
import DaySelection from '@/components/form/DaySelection';
import DurationSelection from '@/components/form/DurationSelection';
import Input from '@/components/form/Input';
import ItemCard from '@/components/editing/ItemCard';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';
import Select from '@/components/form/Select';
import TimeSelection from '@/components/form/TimeSelection';

export default {
  name: 'GarageDeals',
  components: {
    Checkbox,
    DaySelection,
    DurationSelection,
    Input,
    ItemCard,
    MaterialButton,
    Select,
    TimeSelection,
  },
  props: {
    value: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      DEAL_DISPLAY_NAMES: MapDataHelper.DEAL_DISPLAY_NAMES,
      deals: MapDataHelper.assignRandomIds(this.value),
    };
  },
  watch: {
    deals(deals) {
      this.$emit('input', deals);
    },
  },
  methods: {
    getSelectedDays(deal) {
      const selectedDays = [];
      for (let i = 0; i < 7; i++) {
        if (deal[MapDataHelper.WEEKDAYS[i]]) {
          selectedDays.push((i + 1) % 7);
        }
      }

      return selectedDays;
    },
    setSelectedDays(rawSelectedDays, deal) {
      for (let i = 0; i < 7; i++) {
        deal[MapDataHelper.WEEKDAYS[i]] = rawSelectedDays.indexOf((i + 1) % 7) !== -1;
      }
    },
    getInTimes(deal) {
      return {
        start_hour: deal.in_from ? parseInt(deal.in_from.substr(0, 2), 10) : null,
        start_minute: deal.in_from ? parseInt(deal.in_from.substr(3, 5), 10) : null,
        end_hour: deal.in_to ? parseInt(deal.in_to.substr(0, 2), 10) : null,
        end_minute: deal.in_to ? parseInt(deal.in_to.substr(3, 5), 10) : null,
      };
    },
    setInTimes(rawInTimes, deal) {
      deal.in_from = rawInTimes.start_hour ? `${rawInTimes.start_hour.toString().padStart(2, '0')}:${rawInTimes.start_minute.toString().padStart(2, '0')}:00` : null;
      deal.in_to = rawInTimes.end_hour ? `${rawInTimes.end_hour.toString().padStart(2, '0')}:${rawInTimes.end_minute.toString().padStart(2, '0')}:00` : null;
    },
    getOutByTimes(deal) {
      return {
        start_hour: deal.out_by ? parseInt(deal.out_by.substr(0, 2), 10) : null,
        start_minute: deal.out_by ? parseInt(deal.out_by.substr(3, 5), 10) : null,
      };
    },
    setOutByTimes(rawInTimes, deal) {
      deal.out_by = rawInTimes.start_hour ? `${rawInTimes.start_hour.toString().padStart(2, '0')}:${rawInTimes.start_minute.toString().padStart(2, '0')}:00` : null;
    },
    getOutAfterTimes(deal) {
      return {
        start_hour: deal.out_after ? parseInt(deal.out_after.substr(0, 2), 10) : null,
        start_minute: deal.out_after ? parseInt(deal.out_after.substr(3, 5), 10) : null,
      };
    },
    setOutAfterTimes(rawInTimes, deal) {
      deal.out_after = rawInTimes.start_hour ? `${rawInTimes.start_hour.toString().padStart(2, '0')}:${rawInTimes.start_minute.toString().padStart(2, '0')}:00` : null;
    },
    addDeal() {
      this.deals.push(MapDataHelper.newGarageDeal());
    },
    copyDeal(i) {
      this.deals.push(MapDataHelper.copyItemWithId(this.deals[i]));
    },
    deleteDeal(i) {
      this.deals.splice(i, 1);
    },
  },
};

</script>

<style scoped>

#garage-deals {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#garage-deals > * {
  margin-bottom: 8px;
}

#garage-deals > *:last-child {
  margin-bottom: 32px;
}

</style>
