export function getHash(key, fallback) {
  const params = new URLSearchParams(window.location.hash.substring(1));
  const value = params.get(key);
  return value === null ? fallback : value;
}

export function setHash(key, value, clear = false) {
  const params = clear ? new URLSearchParams() : new URLSearchParams(window.location.hash.substring(1));
  params.set(key, value);
  window.location.hash = `#${params.toString()}`;
}
