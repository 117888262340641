<template>
  <div id="container">
    <div class="center" v-if="state == 'loading'">
      <Spinner/>
    </div>
    <div v-if="state == 'error'">
      Something went wrong, try reloading this page.
    </div>
    <div id="main" v-if="state == 'ok'">
      <div class="col">
        <div class="panel">
          <div class="panel-element title">
            Activity
          </div>
          <div class="panel-element-activity" v-for="activity in activities" :key="activity.id">
            <img :src="require(`@/assets/images/${activityIcon(activity)}.svg`)" width="50"/>
            <div class="activity-container">
              <div class="activity-title">
                {{ formatActionSlug(activity.action_slug) }}
              </div>
              <div class="activity-address">
                {{ activity.address }}
              </div>
              <div class="activity-date">
                {{ new Date(activity.created_at.replace(' ', 'T') + 'Z').toLocaleString() }}
              </div>
              <div :class="activitySptcClass(activity)">
                {{ activity.nb_points < 0 ? '' : '+' }}{{ format(activity.nb_points) }} SPTC
                <template v-if="activity.status === 'standby'">
                  pending validation
                </template>
                <template v-else-if="activity.action_slug === 'changeset_rejected'">
                  penalty
                </template>
              </div>
            </div>
            <a
              v-if="activity.picture_url !== ''"
              :href="activity.picture_url"
              target="_blank"
            >
              <img
                :src="activity.picture_url"
                width="60"
                class="activity-photo"
              />
            </a>
          </div>
          <div class="pagination">
            <MaterialButton
              label="Previous page"
              :disabled="page === 0"
              v-on:click="page -= 1"
            />
            <div>
              {{ page + 1 }} / {{ Math.ceil(count / pageSize) }}
            </div>
            <MaterialButton
              label="Next page"
              :disabled="(page + 1) * pageSize >= count"
              v-on:click="page += 1"
            />
          </div>
        </div>
      </div>

      <div class="col" v-if="communityPool !== null">
        <div class="panel">
          <div class="panel-element title">
            Community Pool ({{ new Date(communityPool.moment).toLocaleString('en-US', { month: 'long' }) }})
          </div>
          <div class="panel-element">
            SPTC available this month
            <div class="light">
              {{ format(parseFloat(communityPool.allocated) + parseFloat(communityPool.leftover)) }}
            </div>
          </div>
          <div class="panel-element">
            SPTC earned this month
            <div class="light">
              {{ format(parseFloat(communityPool.spent)) }}
            </div>
          </div>
          <div class="panel-element">
            SPTC still available
            <div class="light">
              {{ format(Math.max(0, parseFloat(communityPool.allocated) + parseFloat(communityPool.leftover) - parseFloat(communityPool.spent))) }}
            </div>
          </div>
          <div class="panel-element">
            Community reward multiplier
            <div class="light">
              × {{ communityPool.multiplier }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiHelper from '@/utils/apiHelper';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';
import Spinner from '@/components/Spinner';
import { getHash, setHash } from '@/utils/url';

const numberFormat = new Intl.NumberFormat('en-US');

const ICONS = {
  changeset_rejected: 'edit-not-approved',
  changeset_submitted: 'edit-approved',
  changeset_ticket_incorrectly_processed: 'edit-not-approved',
  changeset_ticket_processed: 'edit-approved',
  marked_as_inaccurate_ticket_processed: 'edit-approved',
  picture_ticket_processed: 'edit-approved',
  qa_ticket_incorrectly_processed: 'edit-not-approved',
  qa_ticket_processed: 'edit-approved',
  refunded_penalties: 'dollars',
  redeem: 'dollars',
};

function getCommunityPoolPromise() {
  return new Promise((resolve, reject) => {
    MapDataHelper.getCommunityPool(
      (request, result) => {
        resolve(result);
      },
      (request, error) => {
        reject(error);
      },
    );
  });
}

export default {
  components: {
    MaterialButton,
    Spinner,
  },
  data() {
    return {
      state: 'loading', // 'loading' | 'error' | 'ok'
      page: null,
      count: null,
      activities: null,
      communityPool: null,
    };
  },
  watch: {
    async page() {
      setHash('page', this.page);
      this.state = 'loading';
      try {
        const { count, pageSize, activities } = await ApiHelper.requestAsync(
          'get',
          `/api/v3/users/me/points_history?page=${this.page}`,
        );
        this.activities = activities;
        this.count = count;
        this.pageSize = pageSize;
        this.state = 'ok';
      } catch (error) {
        this.state = 'error';
      }
    },
  },
  async mounted() {
    this.hashChange();
    window.addEventListener('hashchange', this.hashChange, false);
    this.communityPool = await getCommunityPoolPromise();
  },
  methods: {
    format(n) {
      return numberFormat.format(n);
    },
    hashChange() {
      this.page = parseInt(getHash('page', 0), 10);
    },
    activityIcon(activity) {
      if (activity.status === 'standby') {
        return 'edit-standby';
      }
      if (activity.status === 'rejected') {
        return 'edit-not-approved';
      }
      return ICONS[activity.action_slug] || 'happy-angel';
    },
    formatActionSlug(slug) {
      const result = slug.split('_').join(' ');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    activityIsRejected(activity) {
      return activity.status === 'rejected' || activity.action_slug === 'changeset_rejected';
    },
    activitySptcClass(activity) {
      if (activity.status === 'standby') {
        return 'activity-standby';
      }
      if (activity.status === 'accepted') {
        if (activity.nb_points < 0) {
          return 'activity-rejected-or-negative';
        }
        return 'activity-accepted';
      }
      return 'activity-rejected-or-negative';
    },
  },
};
</script>

<style scoped>

#container {
  width: 100%;
  overflow: auto;
}

.center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
}

#main {
  display: flex;
  justify-content: space-evenly;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 40px;
}

.panel {
  background-color: white;
  border: 1px solid #CED6DE;
  border-radius: 10px;
  width: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.panel-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #CED6DE;
}

.panel-element-activity {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
}

.panel-element a {
  cursor: pointer;
}

.panel-element-activity:not(:last-child) {
  border-bottom: 1px solid #CED6DE;
}

.activity-container {
  margin-left: 18px;
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
}

.activity-title {
  font-weight: bold;
}

.activity-date {
  color: #8293A3;
}

.activity-accepted {
  color: #FFCC00;
}

.activity-rejected-or-negative {
  color: #FF4541;
}

.activity-standby {
  color: #CED6DE;
}

.title {
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.light {
  color: #545E69;
}

</style>
