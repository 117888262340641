/* eslint-disable max-classes-per-file */
import { TypedError } from 'typed-error';

import AuthHelper from '@/utils/authHelper';

class HttpStatusError extends TypedError {
}

class ServicesHelper {
  static get(url, success, error, headers = {}) {
    return this.doFetch('GET', url, null, success, error, headers);
  }

  static post(url, body, success, error, headers = {}) {
    return this.doFetch('POST', url, body, success, error, headers);
  }

  static patch(url, body, success, error, headers = {}) {
    return this.doFetch('PATCH', url, body, success, error, headers);
  }

  static put(url, body, success, error, headers = {}) {
    return this.doFetch('PUT', url, body, success, error, headers);
  }

  static delete(url, body, success, error, headers = {}) {
    return this.doFetch('DELETE', url, body, success, error, headers);
  }

  static async doFetch(method, rawUrl, body, success, error, headers) {
    // Calls success with either:
    //  * undefined when the response is a 204;
    //  * the data from the response if the status code is 2XX and the body can be parsed as json;
    // Calls error with either:
    //  * { body: { error_message } } when the request fails;
    //  * { status, body: { error_message } } when parsing the json fails;
    //  * { status, body } if the response status is not 2XX and the body can be parsed as json.
    // The first argument of success and error is always the request that was sent.
    const request = ServicesHelper.createRequest(method, rawUrl, body, headers);
    let response;
    try {
      response = await fetch(request);
    } catch (err) {
      error(request, { body: { error_message: err.toString() } });
      return;
    }
    if (response.status === 204) {
      success(request);
      return;
    }
    let json;
    try {
      json = await response.json();
    } catch (err) {
      error(request, { status: response.status, body: { error_message: err.toString() } });
      return;
    }
    if (response.ok) {
      success(request, json);
    } else {
      error(request, { status: response.status, body: json });
    }
  }

  static createRequest(method, rawUrl, body, headers) {
    let url = rawUrl;
    if (!Object.prototype.hasOwnProperty.call(headers, 'Authorization')) {
      const loginToken = AuthHelper.getLoginToken();
      if (loginToken !== null) {
        const urlObj = new URL(rawUrl);
        urlObj.searchParams.set('login_token', loginToken);
        url = urlObj.toString();
      }
    }
    const params = {
      method,
      headers: new Headers(headers),
    };
    if (body !== null) {
      if (body instanceof FormData) {
        params.body = body;
      } else {
        params.headers.append('Content-Type', 'application/json');
        params.body = JSON.stringify(body);
      }
    }
    return new Request(url, params);
  }

  static async doFetchAsync(method, url, params = {}, body = {}, headers = {}) {
    let urlWithParams = url;
    const encodedParams = (new URLSearchParams(params)).toString();
    if (encodedParams !== '') {
      urlWithParams = `${url}?${encodedParams}`;
    }
    const headersObj = new Headers(headers);
    let actualBody;
    if (!(body instanceof FormData)) {
      headersObj.append('Content-Type', 'application/json');
      actualBody = JSON.stringify(body);
    }
    const requestParams = {
      headers: headersObj,
    };
    if (!['get', 'head'].includes(method.toLowerCase())) {
      requestParams.body = actualBody;
    }
    const request = new Request(urlWithParams, requestParams);
    const response = await fetch(request);
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    throw new HttpStatusError(response.status);
  }
}

export default ServicesHelper;
