<template>
  <div>
    <div v-for="regulation in spotInfo.calendar" :key="regulation.name" class="regulation">
      <SheetTitle :title="regulation.name" :key="regulation.name"/>
      <template v-for="category in ['months', 'prices']">
        <div v-if="Object.prototype.hasOwnProperty.call(regulation, category) && regulation[category].length > 0" class="months" :key="category">
          <div v-for="(month, i) in regulation[category]" :key="i" class="month">
            <div v-if="month.title || hasTitledMonth(regulation)" class="month-title">{{ month.title || 'All year' }}</div>
            <div v-for="day in month.days" :key="day.title" class="day">
              <div class="title" v-html="day.title"/>
              <div class="values">
                <div v-for="hours in day.hours" :key="hours" v-html="hours"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import SheetTitle from '@/components/SheetTitle';

export default {
  name: 'SpotRegulations',
  components: {
    SheetTitle,
  },
  props: {
    spotInfo: null,
  },
  methods: {
    hasTitledMonth(regulation) {
      return regulation.months.some((m) => !!m.title);
    },
  },
};

</script>

<style scoped>

.regulation {
  border-bottom: 1px solid #CED6DE;
}

.months {
  padding: 16px 16px 0 16px;
}

.months:last-child {
  padding-bottom: 16px;
}

.month {
  padding-bottom: 16px;
}

.month:last-child {
  padding-bottom: 0;
}

.month-title {
  font-weight: bold;
}

.day {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

</style>
