import * as turf from '@turf/turf';
import Toast from '@/utils/toast';
import randomHexa from '@/utils/randomHexa';

class GeoJsonFileControl {
  constructor(sourceUrl) {
    this.sourceUrl = sourceUrl;
    this.featureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    this.pendingFiles = 0;
    this.pendingBbox = null;
    this.loadingToast = null;

    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.controlContainer.classList.add('mapboxgl-ctrl');

    this.input = document.createElement('input');
    this.input.type = 'file';
    this.input.multiple = true;
    this.input.hidden = true;
    this.input.onchange = () => this.onInputChange();
    this.controlContainer.appendChild(this.input);

    this.button = document.createElement('button');
    this.button.title = 'Upload GeoJSON file';
    this.button.classList.add('mapboxgl-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon-upload');
    this.button.addEventListener('click', () => this.input.click());
    this.controlContainer.appendChild(this.button);
  }

  onAdd(map) {
    this.map = map;
    return this.controlContainer;
  }

  onRemove() {
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    this.map = null;
  }

  onInputChange() {
    this.pendingFiles += this.input.files.length;
    for (const file of this.input.files) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          this.onFileLoaded(file, reader);
        } catch (e) {
          Toast.danger(`Error loading ${file.name}: ${e.message}`, 5000);
        } finally {
          this.onFileProcessed();
        }
      };
      reader.readAsText(file);
    }

    if (this.loadingToast === null) {
      this.loadingToast = Toast.info('Loading files...', Toast.DURATION_INDEFINITE);
    }
  }

  onFileLoaded(file, reader) {
    const collection = JSON.parse(reader.result);

    let newFeatures;
    if (collection.type === 'GeometryCollection') {
      newFeatures = (collection.geometries || []).map((g) => ({ type: 'Feature', geometry: g, properties: {} }));
    } else if (collection.type === 'FeatureCollection') {
      newFeatures = collection.features || [];
    } else {
      throw new Error('unknown GeoJSON type, please upload either a FeatureCollection or GeometryCollection');
    }

    if (newFeatures.length === 0) {
      throw new Error('no features or geometries found');
    }

    const color = `#${randomHexa(6)}`;
    for (const feature of newFeatures) {
      if (!('properties' in feature)) {
        feature.properties = {};
      }

      feature.properties.color = color;
      feature.properties.file = file.name;
      this.featureCollection.features.push(feature);

      const bbox = turf.bbox(feature);
      if (this.pendingBbox == null) {
        this.pendingBbox = bbox;
      } else {
        if (this.pendingBbox[0] > bbox[0]) this.pendingBbox[0] = bbox[0];
        if (this.pendingBbox[1] > bbox[1]) this.pendingBbox[1] = bbox[1];
        if (this.pendingBbox[2] < bbox[2]) this.pendingBbox[2] = bbox[2];
        if (this.pendingBbox[3] < bbox[3]) this.pendingBbox[3] = bbox[3];
      }
    }

    this.map.getSource(this.sourceUrl).setData(this.featureCollection);
  }

  onFileProcessed() {
    this.pendingFiles -= 1;
    if (this.pendingFiles > 0) {
      return;
    }

    if (this.pendingBbox !== null) {
      this.map.fitBounds(
        [[this.pendingBbox[0], this.pendingBbox[1]], [this.pendingBbox[2], this.pendingBbox[3]]],
        {
          animate: false,
          padding: 50,
        },
      );
      this.pendingBbox = null;
    }

    if (this.loadingToast !== null) {
      this.loadingToast.goAway(0);
      this.loadingToast = null;
    }
  }
}

export default GeoJsonFileControl;
