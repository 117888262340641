import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import drawStyle from '@mapbox/mapbox-gl-draw/src/lib/theme';

import SnappedLineStringMode from '@/utils/snappedLineStringMode';
import MapDataHelper from '@/utils/mapDataHelper';

import BusStop from '@/assets/images/bus-stop.svg';
import Meter from '@/assets/images/meter.svg';
import FireHydrant from '@/assets/images/fire-hydrant.svg';
import ParkingSign from '@/assets/images/parking-sign.svg';

mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

class MapboxHelper {
  constructor(containerId, mapSettings) {
    this.isReady = false;
    this.map = new mapboxgl.Map({
      style: `${mapSettings.styleUrl}?fresh=true`,
      container: containerId,
      logoPosition: 'bottom-right',
      maxZoom: 22,
      doubleClickZoom: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');

    this.map.on('load', (e) => {
      this.onMapReady(e);
    });
    this.map.on('style.load', () => {
      this.onStyleLoaded();
    });

    this.onMapReadyListener = null;
    this.onMapClickListener = null;
    this.onMapCtrlClickListener = null;
    this.onMapDoubleClickListener = null;
    this.onIdleListener = null;
    this.onDrawModeChangeListener = null;
    this.onDrawListener = null;
    this.onTemporaryDrawListener = null;
    this.temporaryFeatureId = null;
    this.customCursor = null;

    this.mapSettings = mapSettings;
    this.baseFilters = {};
    this.lastClickTime = 0;

    // Add draw layers that show the individual points of linestrings
    const extraDrawStyle = [];
    for (const layer of drawStyle) {
      if (!layer.id.startsWith('gl-draw-point-')) continue;

      const newLayer = JSON.parse(JSON.stringify(layer));
      newLayer.id = layer.id.replace('gl-draw-point-', 'gl-draw-linepoint-');
      for (let i = 0; i < newLayer.filter.length; i++) {
        const filter = newLayer.filter[i];
        if (Array.isArray(filter) && filter.length === 3 && filter[0] === '==' && filter[1] === '$type') {
          filter[2] = 'LineString';
        }
      }

      extraDrawStyle.push(newLayer);
    }

    this.draw = new MapboxDraw({
      modes: Object.assign(MapboxDraw.modes, {
        draw_snapped_line_string: SnappedLineStringMode,
        // draw_fuel_station: FuelStationMode,
      }),
      styles: drawStyle.concat(extraDrawStyle),
      displayControlsDefault: false,
      controls: {
        line_string: true,
        polygon: true,
      },
    });

    this.map.addControl(this.draw, 'bottom-right');
    // this.map.addControl(new FuelStationControl(this.draw), 'bottom-right');
    this.map.on('draw.modechange', (e) => this.onDrawModeChange(e));
    this.map.on('draw.create', (e) => this.onDraw(e));
    this.map.on('draw.update', (e) => this.onDraw(e));
    this.map.on('draw.delete', (e) => this.onDraw(e));

    window.addEventListener('keyup', (e) => this.onKeyUp(e));
  }

  addMapillaryLayer(source, sourceLayer, imageId, filter) {
    this.map.addLayer({
      id: imageId,
      filter,
      source,
      'source-layer': sourceLayer,
      type: 'symbol',
      layout: {
        'icon-image': imageId,
      },
    });
  }

  async loadImage(marker, src, width, height) {
    const img = new Image(width, height);
    await new Promise((resolve, reject) => {
      img.addEventListener('load', resolve);
      img.addEventListener('error', reject);
      img.src = src;
    });
    this.map.addImage(marker, img);
  }

  async loadMapillary() {
    await Promise.all([
      this.loadImage('mapillary-bus-stop', BusStop, 30, 30),
      this.loadImage('mapillary-fire-hydrant', FireHydrant, 30, 30),
      this.loadImage('mapillary-parking-meter', Meter, 30, 30),
      this.loadImage('mapillary-parking-sign', ParkingSign, 30, 30),
    ]);

    this.map.addLayer(
      {
        id: 'mapillary-coverage',
        type: 'line',
        source: 'mapillaryCoverage',
        'source-layer': 'sequence',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-opacity': 0.6,
          'line-color': 'rgb(53, 175, 109)',
          'line-width': 2,
        },
      },
    );
    this.addMapillaryLayer(
      'mapillaryPoints',
      'point',
      'mapillary-fire-hydrant',
      ['==', ['get', 'value'], 'object--fire-hydrant'],
    );
    this.addMapillaryLayer(
      'mapillaryPoints',
      'point',
      'mapillary-parking-meter',
      ['==', ['get', 'value'], 'object--parking-meter'],
    );
    this.addMapillaryLayer(
      'mapillaryTrafficSigns',
      'traffic_sign',
      'mapillary-parking-sign',
      ['in', '-parking-', ['get', 'value']],
    );
    this.addMapillaryLayer(
      'mapillaryTrafficSigns',
      'traffic_sign',
      'mapillary-bus-stop',
      ['in', '-bus-stop-', ['get', 'value']],
    );
  }

  static newVectorSource(path, maxZoom) {
    return {
      type: 'vector',
      maxzoom: maxZoom,
      tiles: process.env.VUE_APP_REGULATIONS_CDN_BASE_URLS.split(',').map((url) => url + path),
    };
  }

  static newGeoJsonSource(features = []) {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    };
  }

  static newPoint(lngLat, props = {}, id = undefined) {
    return {
      id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: Array.isArray(lngLat) ? lngLat : [lngLat.lng, lngLat.lat],
      },
      properties: props,
    };
  }

  static isEditedFeature(feature) {
    return 'layer' in feature && feature.layer.source.startsWith('mapbox-gl-draw-');
  }

  static mbClient = null; // Set by Header to reuse client

  static reverseGeocode(lngLat, success, error) {
    this.mbClient.reverseGeocode({ query: [lngLat.lng, lngLat.lat] })
      .send()
      .then((response) => success(response.body))
      .catch(() => error());
  }

  resize() {
    this.map.resize();
  }

  addControl(control) {
    this.map.addControl(control, 'bottom-right');
  }

  setDrawListeners(onDrawModeChangeListener, onDrawListener) {
    this.onDrawModeChangeListener = onDrawModeChangeListener;
    this.onDrawListener = onDrawListener;
  }

  onDraw(e) {
    if (this.onTemporaryDrawListener !== null) {
      if (e.type === 'draw.create') {
        this.temporaryFeatureId = e.features[0].id;
      }
      this.onTemporaryDrawListener(e);
    } else if (this.onDrawListener !== null) {
      this.onDrawListener(e);
    }
  }

  onDrawModeChange(e) {
    let mode = e.mode;
    if (this.mapSettings.drawSnapLayer !== null && mode === 'draw_line_string') {
      this.draw.changeMode('draw_snapped_line_string', { snapLayer: this.mapSettings.drawSnapLayer });
      mode = 'draw_snapped_line_string';
    } else {
      this.draw.changeMode(mode);
    }

    this.onDrawModeChangeListener(mode);
  }

  isEditing() {
    return this.draw.getMode() === 'direct_select';
  }

  isDrawing() {
    return [
      'draw_snapped_line_string',
      'draw_polygon',
      'draw_fuel_station',
    ].includes(this.draw.getMode());
  }

  addDrawnFeature(feature) {
    this.draw.add(feature);
  }

  deleteDrawnFeature(featureId) {
    this.draw.delete(featureId);
  }

  setDrawnFeatureSelected(feature) {
    const drawnFeature = this.draw.get(feature.id);
    if (drawnFeature && drawnFeature.geometry.type === 'LineString' && MapDataHelper.isGarage(drawnFeature)) {
      // Turn garage linestring into polygon for better editing.
      this.draw.set({
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          properties: feature.properties,
          id: feature.id,
          geometry: {
            type: 'Polygon',
            coordinates: [feature.geometry.coordinates],
          },
        }],
      });
    }

    if (feature.geometry.type !== 'Point') {
      this.draw.changeMode('direct_select', { featureId: feature.id });
    }
  }

  drawTemporaryFeature(type, onTemporaryDrawListener) {
    this.onTemporaryDrawListener = onTemporaryDrawListener;

    if (type === 'Polygon') {
      this.draw.changeMode('draw_polygon');
    } else if (type === 'LineString') {
      this.draw.changeMode('draw_snapped_line_string');
    } else {
      throw new Error(`unhandled feature type '${type}' for drawing`);
    }
  }

  stopDrawingTemporaryFeature() {
    this.onTemporaryDrawListener = null;

    if (this.temporaryFeatureId !== null) {
      this.draw.delete(this.temporaryFeatureId);
    }
    this.draw.changeMode('simple_select');
    this.onDrawModeChangeListener('simple_select');
  }

  setCustomCursor(cursor) {
    this.customCursor = cursor;
    if (cursor) this.map.getCanvas().style.cursor = cursor;
  }

  setOnMapReadyListener(listener) {
    this.onMapReadyListener = listener;
  }

  setOnMapClickListener(listener) {
    this.onMapClickListener = listener;
  }

  setOnMapCtrlClickListener(listener) {
    this.onMapCtrlClickListener = listener;
  }

  setOnMapDoubleClickListener(listener) {
    this.onMapDoubleClickListener = listener;
  }

  setOnIdleListener(listener) {
    this.onIdleListener = listener;
  }

  enableDoubleClickZoom() {
    this.map.doubleClickZoom.enable();
  }

  disableDoubleClickZoom() {
    this.map.doubleClickZoom.disable();
  }

  setSourceFeatures(id, features) {
    this.map.getSource(id).setData({
      type: 'FeatureCollection',
      features: features || [],
    });
  }

  refreshSource(sourceName) {
    const sourceUrl = `other:${this.mapSettings.sources[sourceName].url}`;
    // eslint-disable-next-line no-underscore-dangle
    this.map.style._sourceCaches[sourceUrl].clearTiles(sourceUrl);
    // eslint-disable-next-line no-underscore-dangle
    this.map.style._sourceCaches[sourceUrl].update(this.map.transform);
    this.map.triggerRepaint();
  }

  setSourceLayersVisible(sourceId, visible) {
    const styleLayers = this.map.getStyle().layers;
    for (const layer of styleLayers) {
      if (layer.source === sourceId) {
        this.map.setLayoutProperty(layer.id, 'visibility', visible ? 'visible' : 'none');
      }
    }
  }

  jumpTo(target, zoom = 16.0) {
    if (target.length === 4 || Array.isArray(target[0])) {
      this.map.fitBounds(target, { linear: true, padding: 16 });
    } else {
      this.map.easeTo({
        center: target,
        zoom,
      });
    }
  }

  querySourceFeatures(source, sourceLayer, filter = null) {
    return this.map.querySourceFeatures(source, { sourceLayer, filter });
  }

  setSourceLayersFilter(sourceId, filter) {
    const styleLayers = this.map.getStyle().layers;
    for (const layer of styleLayers) {
      if (layer.source === sourceId) {
        if (!Object.prototype.hasOwnProperty.call(this.baseFilters, layer.id)) {
          this.baseFilters[layer.id] = this.map.getFilter(layer.id);
        }

        const baseFilter = this.baseFilters[layer.id];
        let finalFilter;
        if (filter === null) {
          finalFilter = baseFilter;
        } else if (baseFilter === undefined) {
          finalFilter = filter;
        } else {
          finalFilter = ['all', baseFilter, filter];
        }

        this.map.setFilter(layer.id, finalFilter);
      }
    }
  }

  showPopup(lngLat, html) {
    const popup = new mapboxgl.Popup()
      .setLngLat(lngLat)
      .setHTML(html);
    popup.addTo(this.map);
  }

  /*
   Private methods, please don't call from outside
   */

  onMapReady() {
    this.isReady = true;
    this.map.on('mousemove', (e) => { this.onMouseMove(e); });
    this.map.on('click', (e) => { this.onMapClick(e); });
    this.map.on('idle', (e) => this.onIdle(e));

    if (this.onMapReadyListener !== null) {
      this.onMapReadyListener();
    }
  }

  onMouseMove(e) {
    if (this.onMapClickListener === null || this.mapSettings.clickableLayers.length === 0 || this.isDrawing() || this.customCursor) {
      return;
    }

    this.map.getCanvas().style.cursor = this.findFeatureAt(e) !== null ? 'pointer' : '';
  }

  onMapClick(e) {
    if (this.isDrawing() || this.isEditing() || this.onTemporaryDrawListener !== null) {
      return;
    }

    // We handle double click manually because the default double click handling sends two click events in addition to the double click one
    // which is annoying to filter out
    const clickTime = new Date().valueOf();
    if (clickTime - this.lastClickTime < 500) {
      this.onMapDoubleClick(e);
      this.lastClickTime = 0; // So that third click is not registered as double click but new click
      return;
    }

    this.lastClickTime = clickTime;

    if ((e.originalEvent.ctrlKey || e.originalEvent.metaKey) && this.onMapCtrlClickListener !== null) {
      this.onMapCtrlClickListener(e.lngLat);
      return;
    }

    if (this.onMapClickListener === null || this.mapSettings.clickableLayers.length === 0) {
      return;
    }

    const feature = this.findFeatureAt(e);
    if (this.onMapClickListener(e.lngLat, feature !== null && feature.properties.id !== this.temporaryFeatureId ? feature : null)) {
      e.preventDefault();
    }
  }

  onMapDoubleClick(e) {
    if (this.onMapDoubleClickListener === null || this.isDrawing()) {
      return;
    }

    if (this.onMapDoubleClickListener(e.lngLat)) {
      e.preventDefault();
    }
  }

  onIdle(e) {
    if (this.onIdleListener !== null) {
      const center = e.target.getCenter();
      this.onIdleListener([center.lng, center.lat], e.target.getZoom());
    }
  }

  onKeyUp(e) {
    if (e.key === 'Escape' && this.onTemporaryDrawListener !== null) {
      this.stopDrawingTemporaryFeature();
      return;
    }

    if (e.target.tagName.toUpperCase() !== 'CANVAS' || !e.target.classList.contains('mapboxgl-canvas')) {
      return;
    }

    if ((e.key === 'Delete' || e.key === 'Backspace') && this.isEditing()) {
      const feature = this.draw.getSelected().features[0];
      if ((feature.geometry.type === 'LineString' && feature.geometry.coordinates.length > 2)
        || (feature.geometry.type === 'Polygon' && feature.geometry.coordinates[0].length > 4)) {
        this.draw.trash();
      }
    }
  }

  findFeatureAt(e) {
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ];

    let features = [];
    for (const layersGroup of this.mapSettings.clickableLayers) {
      features = this.map.queryRenderedFeatures(bbox, { layers: Object.keys(layersGroup) });
      if (features.length > 0) {
        break;
      }
    }
    let closestFeature = null;
    let minDist = Number.MAX_SAFE_INTEGER;

    const turfPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      let dist;

      if (feature.geometry.type === 'Point') {
        dist = turf.distance(feature, turfPoint) * 0.25; // x0.25 give higher priority to labels
      } else if (feature.geometry.type === 'LineString') {
        dist = turf.pointToLineDistance(turfPoint, feature);
      } else if (feature.geometry.type === 'Polygon') {
        dist = turf.within(turfPoint, feature) ? 0 : Number.MAX_SAFE_INTEGER;
      }

      if (dist < minDist) {
        closestFeature = feature;
        minDist = dist;
      }
    }

    if (closestFeature) {
      // For some reason, mapbox draw layers lose properties of drawn features
      // so reassign them here manually
      const drawnFeature = this.draw.get(closestFeature.id || closestFeature.properties.id);
      if (drawnFeature) {
        closestFeature.properties = {
          ...closestFeature.properties,
          ...drawnFeature.properties,
        };
      }
    }

    return closestFeature;
  }

  onStyleLoaded() {
    for (const [id, source] of Object.entries(this.mapSettings.sources)) {
      if (source.replace) {
        this.replaceSource(
          source.url,
          source.vector ? MapboxHelper.newVectorSource(source.tiles_path, source.max_zoom) : MapboxHelper.newGeoJsonSource(),
        );
      } else {
        this.map.addSource(id, source);
      }
    }
  }

  replaceSource(id, source) {
    const styleLayers = this.map.getStyle().layers;
    const removedLayers = [];
    const aboveLayerIds = [];

    // Store position of the source's layers in the style
    for (let i = 0; i < styleLayers.length; i++) {
      const layer = styleLayers[i];
      if (layer.source === id) {
        removedLayers.push(layer);
        if (i < styleLayers.length - 1) {
          aboveLayerIds.push(styleLayers[i + 1].id);
        } else {
          aboveLayerIds.push(-1);
        }
      }
    }

    // Actually remove the layers
    for (let i = 0; i < removedLayers.length; i++) {
      this.map.removeLayer(removedLayers[i].id);
    }

    // Replace the source
    this.map.removeSource(id);
    this.map.addSource(id, source);

    // Restore the layers in their original position
    for (let i = removedLayers.length - 1; i >= 0; i--) {
      const aboveLayerId = aboveLayerIds[i];
      const layer = removedLayers[i];

      if (source.type === 'geojson') {
        delete layer['source-layer'];
      }

      if (aboveLayerId !== -1) {
        this.map.addLayer(layer, aboveLayerId);
      } else {
        this.map.addLayer(layer);
      }
    }
  }
}

export default MapboxHelper;
