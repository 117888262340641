<template>
  <div class="form-input" :class="{'full-width': fullWidth}">
    <div class="form-input-label-container" v-if="!fullWidth">
      <span class="form-input-label">{{ label }}</span>
      <span v-if="hint" class="form-input-hint">{{ hint }}</span>
    </div>
    <template v-if="type === 'textarea'">
      <textarea ref="input" :pattern="pattern" :placeholder="placeholder" v-model="innerValue"/>
    </template>
    <template v-else-if="type === 'static'">
      <span class="form-input-static">{{ innerValue }}</span>
    </template>
    <template v-else-if="type === 'href'">
      <span class="form-input-static">
        <a :href="href" target="_blank">{{ innerValue }}</a>
      </span>
    </template>
    <template v-else>
      <input
        ref="input"
        v-focus-on-create :focus-on-create="focusOnCreate"
        :type="type"
        :pattern="pattern"
        :value="innerValue"
        v-on:input="innerValue = $event.target.value === '' ? null : $event.target.value"
        :placeholder="placeholder"
        :step="step"
        :min="min"
        :max="max"
        v-on:focus="onFocus"
        v-on:blur="$emit('blur', $event)"
        :class="{'with-action': action !== null}"
      />
    </template>
    <div class="error" v-if="errors !== null">
      {{ errors.join(', ') }}
    </div>
    <MaterialButton class="action-btn" v-if="action" :label="action" v-on:click="$emit('action-clicked', innerValue)"/>
  </div>
</template>

<script>

import MaterialButton from '@/components/MaterialButton';

export default {
  name: 'Input',
  components: { MaterialButton },
  props: {
    id: null,
    type: {
      type: String,
      default: 'text',
    },
    pattern: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    label: null,
    placeholder: null,
    value: null,
    step: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    focusOnCreate: {
      type: Boolean,
      default: false,
    },
    selectAllOnFocus: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
  },
  directives: {
    focusOnCreate: {
      inserted(el, binding, vnode) {
        if (vnode.context.focusOnCreate && (!binding.modifiers.empty || !el.value)) {
          el.focus();
        }
      },
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
    innerValue(innerValue) {
      this.$emit('input', this.type === 'number' && innerValue !== null ? parseFloat(innerValue) : innerValue);
    },
  },
  methods: {
    onFocus($event) {
      if (this.selectAllOnFocus) {
        $event.target.select();
      }
      this.$emit('focus', $event);
    },
    checkValidity() {
      return this.$refs.input.checkValidity();
    },
  },
};

</script>

<style scoped>

.form-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-input .form-input-label-container {
  width: 30%;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.form-input .form-input-label {
}

.form-input .form-input-hint {
  font-size: 12px;
  font-style: italic;
  color: #8293a3;
}

.form-input > input, .form-input > textarea {
  width: 70%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background: white;
  padding: 6px;
  font-size: 14px !important;
}

/*noinspection CssUnusedSymbol*/
.form-input > input.with-action, .form-input > textarea.with-action {
  flex-basis: 0;
  flex-grow: 1;
}

.action-btn {
  margin-left: 8px;
}

.form-input.full-width .form-input-label {
  display: none;
}

.form-input.full-width > input, .form-input.full-width > textarea {
  width: 100%;
}

.form-input > input:disabled {
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid #e0e6ea;
  cursor: not-allowed;
}

.form-input > textarea {
  resize: vertical;
}

.form-input > textarea:disabled {
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid #e0e6ea;
  cursor: not-allowed;
  resize: none;
}

.form-input > input:focus, .form-input > textarea:focus {
  border: 1px solid #1c9be6;
}

.form-input > input:invalid, .form-input > textarea:invalid {
  border: 1px solid #ff4541;
}

.form-input .form-input-static {
  width: 70%;
  height: 32px;
  padding: 0 7px;
  display: flex;
  align-items: center;
}

.error {
  color: #ff4541;
  margin-left: 0.5em;
}

</style>
