import * as turf from '@turf/turf';

import { createComparator, minBy } from '@/utils/sort';

const accessToken = process.env.VUE_APP_MAPILLARY_ACCESS_TOKEN;

const CAMERA_TYPE_ORDER = {
  spherical: 1,
  equirectangular: 1,
  fisheye: 2,
  perspective: 3,
};

export async function api(endpoint, params) {
  let url = `https://graph.mapillary.com/${endpoint}`;
  if (params !== undefined) {
    url = `${url}?${new URLSearchParams(params).toString()}`;
  }
  const request = new Request(
    url,
    {
      method: 'get',
      headers: new Headers({
        authorization: `Bearer ${accessToken}`,
      }),
    },
  );
  const response = await fetch(request);
  const json = await response.json();
  if (json.error) {
    const error = new Error(`${json.error.message}: ${JSON.stringify(json.error)}`);
    error.data = json.error;
    throw error;
  }
  return json;
}

export async function getClosestImage(lng, lat, radius, heading) {
  const circle = turf.circle(
    [lng, lat],
    radius,
    { steps: 5, units: 'meters' },
  );
  const bbox = turf.bbox(circle);
  const images = await api(
    'images',
    {
      bbox,
      fields: [
        'id',
        'computed_geometry',
        'camera_type',
        'captured_at',
        'computed_compass_angle',
      ],
    },
  );
  return minBy(
    images.data,
    createComparator(
      (image) => CAMERA_TYPE_ORDER[image.camera_type] ?? Infinity,
    ),
    createComparator(
      (image) => (heading != null ? Math.abs(image.computed_compass_angle - heading) : 1),
    ),
    createComparator(
      (image) => turf.distance(
        [lng, lat],
        image.computed_geometry,
        { units: 'meters' },
      ),
    ),
    createComparator((image) => -image.captured_at),
  );
}
