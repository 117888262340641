<template>
  <ItemCard>
    <div class="poi-url" v-for="(_, i) in poiUrls" :key="i">
      <Input type="text" pattern="/.+" class="poi-url-input" placeholder="/city-name/poi-name" full-width v-model="poiUrls[i]"/>
      <ImageButton class="action-button" :src="require('@/assets/images/content_copy-black-24dp.svg')" title="Copy URL" v-on:click="copyURL(i)"/>
      <ImageButton class="action-button" :src="require('@/assets/images/delete-black-24dp.svg')" title="Delete URL" v-on:click="deleteURL(i)"/>
    </div>
    <MaterialButton label="Add URL" v-on:click="addURL"/>
  </ItemCard>
</template>

<script>

import MaterialButton from '@/components/MaterialButton';
import ImageButton from '@/components/ImageButton';
import focusOnCreate from '@/utils/focusOnCreate';
import ItemCard from '@/components/editing/ItemCard';
import Input from '@/components/form/Input';

export default {
  name: 'GaragePoiUrls',
  components: {
    Input,
    ItemCard,
    MaterialButton,
    ImageButton,
  },
  directives: {
    focusOnCreate,
  },
  props: {
    value: null,
  },
  data() {
    return {
      poiUrls: this.value,
    };
  },
  watch: {
    poiUrls(poiUrls) {
      this.$emit('input', poiUrls);
    },
  },
  methods: {
    addURL() {
      this.poiUrls.push('');
    },
    copyURL(i) {
      this.poiUrls.push(`${this.poiUrls[i]}`);
    },
    deleteURL(i) {
      this.poiUrls.splice(i, 1);
    },
  },
};

</script>

<style scoped>

.action-button {
  margin-left: 8px;
}

.poi-url {
  display: flex;
  flex-direction: row;
}

.poi-url .poi-url-input {
  margin-bottom: 0;
  flex-grow: 1;
}

</style>
