<script>

import { mapGetters } from 'vuex';
import { eventBus, events } from '@/constants/eventBus';
import MapDataHelper from '@/utils/mapDataHelper';
import RegulationsHelper from '@/utils/regulationsHelper';
import Toast from '@/utils/toast';

export default {
  name: 'EditSheetMixin',
  props: {
    initialSpotData: null,
  },
  data() {
    return {
      saving: false,
      spotData: null,
      initialCalendar: null,
      updatedCalendar: null,
      refreshTimerId: null,
      lastSavedSegmentId: null,
    };
  },
  computed: {
    ...mapGetters('editor', ['selection', 'pendingSpotData', 'featureChanges']),
    loading() {
      return this.spotData === null || this.initialCalendar === null;
    },
  },
  mounted() {
    this.lastSavedSegmentId = parseInt(window.localStorage.getItem('EditSheetMixin.lastSavedSegmentId'), 10);
    this.handleInitialSpotData(this.initialSpotData);
  },
  watch: {
    initialSpotData(initialSpotData) {
      this.handleInitialSpotData(initialSpotData);
    },
    spotData: {
      handler(spotData) {
        if (spotData === null) {
          this.calendar = null;
        } else {
          this.$store.dispatch('editor/setPendingSpotData', {
            id: this.selection.id,
            data: spotData,
          });
          this.$emit('input', this.spotData);
        }
      },
      deep: true,
    },
    'spotData.regulations': {
      handler(regulations, prevRegulations) {
        if (prevRegulations !== undefined) {
          this.loadCalendar(this.spotData, false);
        }
      },
      deep: true,
    },
    'spotData.meters_prices': {
      handler(metersPrices, prevMetersPrices) {
        if (prevMetersPrices !== undefined) {
          this.loadCalendar(this.spotData, false);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleInitialSpotData(initialSpotData) {
      if (initialSpotData === null) {
        this.initialCalendar = null;
        this.spotData = null;
      } else {
        this.loadCalendar(initialSpotData, true);
        if (this.selection.id in this.pendingSpotData) {
          this.spotData = this.pendingSpotData[this.selection.id];
        } else {
          this.spotData = JSON.parse(JSON.stringify(initialSpotData));
        }
      }
    },
    loadCalendar(spotData, isInitial) {
      if (spotData === null) {
        return;
      }

      if (MapDataHelper.isNoData(spotData)) {
        this.onGotCalendar(null, { calendar: [{ name: 'No Data' }] }, isInitial);
      } else {
        const featureChanges = this.featureChanges[this.selection.id];
        let geometry = 42;
        if (featureChanges !== undefined) {
          geometry = featureChanges.feature.geometry;
        } else {
          if (spotData.segment === undefined) {
            // Not sure where this spotData.segment should be coming from
            return;
          }
          geometry = JSON.parse(spotData.segment.geom);
        }

        let lng;
        let lat;
        if (geometry.type === 'LineString') {
          lng = geometry.coordinates[0][0];
          lat = geometry.coordinates[0][1];
        } else {
          lng = geometry.coordinates[0][0][0];
          lat = geometry.coordinates[0][0][1];
        }

        RegulationsHelper.computeCalendar(
          spotData.regulations,
          spotData.meters_prices,
          lng, lat,
          (request, result) => this.onGotCalendar(request, result, isInitial),
          this.onCalendarError,
        );
      }
    },
    onGotCalendar(request, result, isInitial) {
      if (isInitial) {
        this.initialCalendar = result.calendar;
      }

      this.updatedCalendar = result.calendar;
    },
    onCalendarError(/* request, response */) {
      // this.error = response.status;
      // this.loading = false;
    },
    saveChanges() {
      this.saving = true;

      const selectionId = this.selection.id;
      const featureChange = this.featureChanges[selectionId];
      if (featureChange !== undefined && MapDataHelper.hasFeatureChanged(featureChange)) {
        MapDataHelper.saveChangeset(featureChange.feature, this.spotData, (request, response) => this.onChangesetSavedWithFeature(selectionId, response.data.segment_id), this.onSaveChangesetError);
      } else if (MapDataHelper.hasDataChanges(this.initialSpotData, this.spotData)) {
        MapDataHelper.saveChangeset(selectionId, this.spotData, this.onChangesetSaved, this.onSaveChangesetError);
      } else {
        this.onChangesetSaved();
      }
    },
    onChangesetSavedWithFeature(temporarySegmentId, segmentId) {
      if (temporarySegmentId !== segmentId) {
        this.$store.dispatch('editor/featureSaved', { oldId: temporarySegmentId, newId: segmentId });
        this.$store.dispatch('editor/selection', { ...this.selection, tmpId: this.selection.id, id: segmentId });
      } else {
        this.$store.dispatch('editor/featureSaved', segmentId);
      }

      this.onChangesetSaved();
    },
    onChangesetSaved() {
      this.lastSavedFuelStationId = this.selection.id;
      window.localStorage.setItem('EditSheetMixin.lastSavedSegmentId', this.selection.id);

      this.$store.dispatch('editor/removePendingSpotData', this.selection.id);
      this.saving = false;
      this.$emit('updated', this.spotData);
      this.refreshNewFeatures();
      Toast.info('Changes saved');
    },
    onSaveChangesetError(request, response) {
      this.saving = false;
      Toast.danger(
        `Error saving spot data changes: ${response.body.error_message}`,
        !('developer_message' in response.body) ? null : {
          text: 'More info',
          onClick: () => {
            this.$alertDialog.show({
              title: 'Spot data issue',
              message: response.body.developer_message,
            });
          },
        },
      );
    },
    cancel() {
      this.$emit('cancel');
    },
    refreshNewFeatures() {
      if (this.refreshTimerId !== null) {
        clearTimeout(this.refreshTimerId);
      }

      this.refreshTimerId = setTimeout(() => {
        eventBus.$emit(events.refreshNewFeaturesRequested);
        this.refreshTimerId = null;
      }, 500);
    },
  },
};

</script>
