<template>
  <div id="container-pending-tickets">
    <TicketsCategory
      ref="categories"
      v-for="category in ticketCategories"
      :key="category.id"
      :category="category"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapDataHelper from '@/utils/mapDataHelper';
import TicketsCategory from '@/components/editing/TicketsCategory';
import { eventBus, events } from '@/constants/eventBus';
import AuthHelper from '@/utils/authHelper';

export default {
  name: 'TicketsList',
  components: { TicketsCategory },
  data() {
    return {
      TICKET_CATEGORIES: MapDataHelper.TICKET_CATEGORIES,
      tickets: {},
      ticketsCount: 0,
    };
  },
  created() {
    eventBus.$on(events.ticketProcessed, this.onTicketProcessed);
  },
  mounted() {
    for (const category of this.$refs.categories) {
      this.$watch(
        () => category.ticketsCount,
        () => { this.ticketsCount = this.$refs.categories.reduce((acc, c) => acc + c.ticketsCount, 0); },
      );
    }
  },
  computed: {
    ...mapGetters('editor', ['selection', 'selectedTicket']),
    ticketCategories() {
      const user = AuthHelper.getUser();
      return MapDataHelper.TICKET_CATEGORIES.filter((c) => (
        user.role.map_data_validate_others
        && (!c.requiresAdmin || user.role.slug === 'admin')
        && (c.requiresPermissions.every((p) => user.role[p]))
      ));
    },
  },
  watch: {
    selection(selection) {
      if (
        this.selectedTicket !== null
        && (
          selection.id === null
          || (selection.type === 'Regulation' && selection.id !== this.selectedTicket.segment_id)
          || (selection.type === 'FuelStation' && selection.id !== this.selectedTicket.fuel_station_id)
        )
      ) {
        this.$store.dispatch('editor/selectedTicket', null);
      }
    },
  },
  methods: {
    onTicketProcessed(ticketId) {
      if (this.selectedTicket !== null && this.selectedTicket.id === ticketId) {
        this.$store.dispatch('editor/selectedTicket', null);
      }
    },
  },
};
</script>

<style scoped>

#container-pending-tickets {
  height: 100%;
  font-size: 14px;
  color: #8293a3;
  background-color: #F5F7F8;
}

</style>
