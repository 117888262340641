<template>
  <SheetCard class="changesets-list" :style="{'margin-bottom': this.changesetHistory.changesets.length === 0 ? '0' : '48px'}">
    <SheetCardRow class="changeset-container" title="Version history" h-padding="0px">
      <div class="changeset" :class="{'selected': selectedChangeset !== null && selectedChangeset.id === changeset.id}" v-for="changeset in this.changesetHistory.changesets" :key="changeset.id" @click="onChangesetSelected(changeset)">
        <div :class="{'changeset-dot': true, 'changeset-dot-active': changeset.is_active, 'changeset-dot-pending': changeset.is_pending}"></div>
        <div class="changeset-info">
          <span>
            {{ (new Date(changeset.submitted_at)).toLocaleDateString('en-US', { 'month': 'short', 'day': 'numeric', 'year': 'numeric' }) }} -
            {{ (new Date(changeset.submitted_at)).toLocaleTimeString('en-US', { 'hour': 'numeric', 'minute': 'numeric' }) }}
          </span>
          <span class="changeset-info-id">#{{ changeset.id }}</span>
        </div>
        <div class="changeset-statuses">
          <div :class="{'changeset-status': true, 'changeset-status-active': changeset.is_active}"></div>
          <div :class="{'changeset-status': true, 'changeset-status-pending': changeset.is_pending}"></div>
        </div>
      </div>
    </SheetCardRow>
  </SheetCard>
</template>

<script>
import { mapGetters } from 'vuex';
import MapDataHelper from '@/utils/mapDataHelper';
import SheetCard from '@/components/SheetCard';
import SheetCardRow from '@/components/SheetCardRow';

export default {
  name: 'ChangesetList',
  components: {
    SheetCardRow,
    SheetCard,
  },
  computed: {
    ...mapGetters('editor', ['selection', 'changesetHistory', 'selectedChangeset']),
  },
  mounted() {
    this.onSelectionChanged(this.selection, null);
  },
  watch: {
    selection(selection, prevSelection) {
      this.onSelectionChanged(selection, prevSelection);
    },
  },
  methods: {
    onSelectionChanged(selection, prevSelection) {
      if (selection.id === null) {
        this.$store.dispatch('editor/changesetHistory', null);
        this.$store.dispatch('editor/selectedChangeset', null);
      } else if (prevSelection === null || selection.id !== prevSelection.id) {
        this.$store.dispatch('editor/changesetHistory', null);
        this.$store.dispatch('editor/selectedChangeset', null);
        const segmentId = selection.id;
        MapDataHelper.getSpotChangesets(segmentId, (request, result) => this.onGotChangesets(segmentId, result), this.onGetChangesetsError);
      }
    },
    onGotChangesets(segmentId, result) {
      if (segmentId !== this.selection.id) return;
      this.$store.dispatch('editor/changesetHistory', { segmentId, changesets: result.changesets });
    },
    onGetChangesetsError() {
      // TODO
    },
    onChangesetSelected(changeset) {
      this.$store.dispatch('editor/selectedChangeset', changeset);
    },
  },
};
</script>

<style scoped>

.changeset-container {
  padding-bottom: 0;
}

.changeset {
  height: 48px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #CED6DE;
}

.changeset.selected {
  background: #E6F3FA;
}

.changeset:nth-child(2) {
  border-top: 1px solid #CED6DE;
}

.changeset:last-child {
  border-bottom: none;
}

.changeset:hover {
  background-color: #E6F3FA;
}

.changeset-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 100%;
  background: #545E69;
}

.changeset-dot-active {
  background: #1DCF32;
}

.changeset-dot-pending {
  background: #1C9BE6;
}

.changeset-dot-active.changeset-dot-pending {
  background: linear-gradient(90deg, #1DCF32, #1DCF32, #1C9BE6, #1C9BE6);
}

.changeset-info {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.changeset-info-id {
  font-size: 10px;
  color: #545E69;
}

.changeset-statuses {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.changeset-status {
  content: none;
  font-size: 12px;
  width: max-content;
}

.changeset-status-active {
  color: #1DCF32;
}

.changeset-status-active::before {
  content: 'Active';
}

.changeset-status-pending {
  color: #1C9BE6;
}

.changeset-status-pending::before {
  content: 'Pending validation';
}

</style>
