<template>
  <div class="editing-form">
    <Checkbox v-if="regulation.name !== 'MeterHour' && regulation.name !== 'Garage'" :form-style="true" v-model="isTemporary" label="Temporary regulation "/>
    <DateSelection
      v-model="dates"
      label="From"
      :format="regulation.is_temporary ? 'MMM dsu yyyy' : 'MMM dsu'"
      :maximum-view="regulation.is_temporary ? 'year' : 'month'"/>
    <DaySelection label="Days" v-model="selectedDays"/>
    <Select label="Repeat" :options="[1, 2, 3, 4, 5]" :custom-label="weekLabel" v-model="selectedWeeks" :multiple="true" :searchable="false">
      <template slot="selection" slot-scope="{ values }">
        <span class="multiselect__single" v-if="values.length === 5">Every week</span>
      </template>
      <template slot="tag" slot-scope="week">
          <span :style="{order: week.option}">
            {{ weekLabel(week.option, true) }}
          </span>
      </template>
    </Select>

    <DurationSelection
      v-if="regulation.name === 'TimeLimit' || regulation.name === 'MeterHour' || regulation.name === 'Garage'"
      label="Max. duration"
      v-model="regulation.duration"
    />

    <TimeSelection label="Times" :range="true" :start-minute-range="[0,1,15,30,40,45]" :end-minute-range="[0,1,15,30,40,45,59]" v-model="times"/>
    <Permits v-model="regulation.id_permits" :segment-id="segmentId" :lng-lat="lngLat"/>
  </div>
</template>

<script>

import Checkbox from '@/components/form/Checkbox';
import DateSelection from '@/components/form/DateSelection';
import DaySelection from '@/components/form/DaySelection';
import DurationSelection from '@/components/form/DurationSelection';
import Permits from '@/components/Permits';
import Select from '@/components/form/Select';
import TimeSelection from '@/components/form/TimeSelection';

export default {
  name: 'Schedule',
  components: {
    Checkbox,
    DateSelection,
    DaySelection,
    DurationSelection,
    Permits,
    Select,
    TimeSelection,
  },
  props: {
    regulation: null,
    segmentId: null,
    lngLat: null,
  },
  data() {
    return {
      DAYS: Object.freeze(['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']),
      selectedWeeks: [1, 2, 3, 4, 5],
      selectedWeeksBits: '11111',
    };
  },
  computed: {
    isTemporary: {
      get() {
        return this.regulation.is_temporary;
      },
      set(isTemporary) {
        this.regulation.is_temporary = isTemporary;
        if (isTemporary) {
          const now = new Date();
          this.regulation.start_year = now.getFullYear();
          this.regulation.end_year = now.getFullYear();
        } else {
          this.regulation.start_year = null;
          this.regulation.end_year = null;
        }
      },
    },
    dates: {
      get() {
        return {
          startDate: Date.UTC(this.regulation.start_year || new Date().getFullYear(), this.regulation.start_month - 1, this.regulation.day_start_month),
          endDate: Date.UTC(this.regulation.end_year || new Date().getFullYear(), this.regulation.end_month - 1, this.regulation.day_end_month),
        };
      },
      set(dates) {
        this.regulation.start_year = this.regulation.is_temporary ? dates.startDate.getFullYear() : null;
        this.regulation.start_month = dates.startDate.getMonth() + 1;
        this.regulation.day_start_month = dates.startDate.getDate();
        this.regulation.end_year = this.regulation.is_temporary ? dates.endDate.getFullYear() : null;
        this.regulation.end_month = dates.endDate.getMonth() + 1;
        this.regulation.day_end_month = dates.endDate.getDate();
      },
    },
    startDate: {
      get() {
        return Date.UTC(this.regulation.start_year || new Date().getFullYear(), this.regulation.start_month - 1, this.regulation.day_start_month);
      },
      set(date) {
        this.regulation.start_year = this.regulation.is_temporary ? date.getFullYear() : null;
        this.regulation.start_month = date.getMonth() + 1;
        this.regulation.day_start_month = date.getDate();
      },
    },
    endDate: {
      get() {
        return Date.UTC(this.regulation.end_year || new Date().getFullYear(), this.regulation.end_month - 1, this.regulation.day_end_month);
      },
      set(date) {
        this.regulation.end_year = this.regulation.is_temporary ? date.getFullYear() : null;
        this.regulation.end_month = date.getMonth() + 1;
        this.regulation.day_end_month = date.getDate();
      },
    },
    times: {
      get() {
        return {
          start_hour: this.regulation.start_hour,
          start_minute: this.regulation.start_minute,
          end_hour: this.regulation.end_hour,
          end_minute: this.regulation.end_minute,
        };
      },
      set(times) {
        this.regulation.start_hour = times.start_hour;
        this.regulation.start_minute = times.start_minute;
        this.regulation.end_hour = times.end_hour;
        this.regulation.end_minute = times.end_minute;
      },
    },
    selectedDays: {
      get() {
        const selectedDays = [];
        this.DAYS.forEach((day, idx) => {
          if (this.regulation[day] !== '00000') {
            selectedDays.push(idx);
          }
        });

        return selectedDays;
      },
      set(selectedDays) {
        this.DAYS.forEach((day, idx) => {
          this.regulation[day] = selectedDays.indexOf(idx) !== -1 ? this.selectedWeeksBits : '00000';
        });
      },
    },
  },
  watch: {
    selectedWeeks(selectedWeeks) {
      let weekBits = '';
      for (let i = 1; i <= 5; i++) {
        weekBits += selectedWeeks.indexOf(i) === -1 ? '0' : '1';
      }
      this.selectedWeeksBits = weekBits;
    },
    selectedWeeksBits() {
      // Force selected days update
      this.selectedDays = JSON.parse(JSON.stringify(this.selectedDays));
    },
  },
  methods: {
    weekLabel(week, withComma) {
      let suffix;
      switch (week) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          suffix = 'th';
      }
      return week + suffix + (!withComma || week === Math.max(...this.selectedWeeks) ? '' : ',');
    },
  },
};

</script>
