const mapillaryAccessToken = process.env.VUE_APP_MAPILLARY_ACCESS_TOKEN;

const MAP_SETTINGS = {
  styleUrl: `mapbox://styles/hamzao/ckgknl14k0eb919o1kazrthv9${process.env.NODE_ENV === 'production' ? '' : '/draft'}`,
  sources: {
    regulations: {
      url: 'mapbox://hamzao.9qk29p1o',
      replace: true,
      vector: true,
      tiles_path: '/api/v4/tiles/editor/{z}/{x}/{y}.mvt',
      max_zoom: 16,
    },
    newRegulations: {
      url: 'mapbox://hamzao.ckhw1chfz0hhs25k2pydoobky-9rcq9',
      replace: true,
      vector: true,
      tiles_path: '/api/v4/tiles/pending/{z}/{x}/{y}.mvt',
      max_zoom: 16,
    },
    blockfaces: {
      url: 'mapbox://hamzao.0gwc9pj2',
      replace: true,
      vector: true,
      tiles_path: '/api/v4/tiles/blockface/{z}/{x}/{y}.mvt',
      max_zoom: 16,
    },
    spotInfo: {
      url: 'mapbox://hamzao.31hpokxd',
      replace: true,
    },
    searchedPlace: {
      url: 'mapbox://hamzao.cj9rxiu8767zq2ylgfrrmsfkv-9hjpn',
      replace: true,
    },
    svPin: {
      url: 'mapbox://hamzao.ckirnu4p00bke2bm5d3vare41-6lpze',
      replace: true,
    },
    cities: {
      url: 'mapbox://hamzao.cities_centroids-1chaho',
      replace: true,
      vector: true,
      tiles_path: '/api/v4/tiles/cities/centroids/{z}/{x}/{y}.mvt',
      max_zoom: 13,
    },
    uploadedFeatures: {
      url: 'mapbox://hamzao.ckiszw7152iui2alnfd0fl2wt-7l1bw',
      replace: true,
    },
    fuelStations: {
      url: 'mapbox://hamzao.3sj6fjty',
      replace: true,
      vector: true,
      tiles_path: '/api/v4/tiles/fuel-stations/{z}/{x}/{y}.mvt',
      max_zoom: 16,
    },
    pois: {
      url: 'mapbox://hamzao.7r0hkrib',
      replace: true,
      vector: true,
      tiles_path: '/api/v4/tiles/pois/{z}/{x}/{y}.mvt',
      max_zoom: 16,
    },
    areasToMap: {
      url: 'mapbox://hamzao.cl56shws803mb27ptrvnciy61-5g9kv',
      replace: true,
    },
    mapillaryCoverage: {
      type: 'vector',
      tiles: [
        `https://tiles.mapillary.com/maps/vtp/mly1_public/2/{z}/{x}/{y}?access_token=${mapillaryAccessToken}`,
      ],
      minzoom: 6,
      maxzoom: 14,
    },
    mapillaryPoints: {
      type: 'vector',
      tiles: [
        `https://tiles.mapillary.com/maps/vtp/mly_map_feature_point/2/{z}/{x}/{y}?access_token=${mapillaryAccessToken}`,
      ],
      minzoom: 14,
      maxzoom: 14,
    },
    mapillaryTrafficSigns: {
      type: 'vector',
      tiles: [
        `https://tiles.mapillary.com/maps/vtp/mly_map_feature_traffic_sign/2/{z}/{x}/{y}?access_token=${mapillaryAccessToken}`,
      ],
      minzoom: 14,
      maxzoom: 14,
    },
  },
  clickableLayers: [
    { 'cities-with-icons': 0.0 },
    {
      cities: 0.0,
    },
    {
      'fuel-stations': 14.0,
      pois: 14.0,
      labels: 14.0,
    },
    { // Mapbox Draw layers
      'gl-draw-line-active.cold': 0.0,
      'gl-draw-line-active.hot': 0.0,
      'gl-draw-line-inactive.cold': 0.0,
      'gl-draw-line-inactive.hot': 0.0,
      'gl-draw-line-static.cold': 0.0,
      'gl-draw-line-static.hot': 0.0,
      'gl-draw-linepoint-active.cold': 0.0,
      'gl-draw-linepoint-active.hot': 0.0,
      'gl-draw-linepoint-inactive.cold': 0.0,
      'gl-draw-linepoint-inactive.hot': 0.0,
      'gl-draw-linepoint-point-stroke-inactive.cold': 0.0,
      'gl-draw-linepoint-point-stroke-inactive.hot': 0.0,
      'gl-draw-linepoint-static.cold': 0.0,
      'gl-draw-linepoint-static.hot': 0.0,
      'gl-draw-linepoint-stroke-active.cold': 0.0,
      'gl-draw-linepoint-stroke-active.hot': 0.0,
      'gl-draw-point-active.cold': 0.0,
      'gl-draw-point-active.hot': 0.0,
      'gl-draw-point-inactive.cold': 0.0,
      'gl-draw-point-inactive.hot': 0.0,
      'gl-draw-point-point-stroke-inactive.cold': 0.0,
      'gl-draw-point-point-stroke-inactive.hot': 0.0,
      'gl-draw-point-static.cold': 0.0,
      'gl-draw-point-static.hot': 0.0,
      'gl-draw-point-stroke-active.cold': 0.0,
      'gl-draw-point-stroke-active.hot': 0.0,
      'gl-draw-polygon-and-line-vertex-inactive.cold': 0.0,
      'gl-draw-polygon-and-line-vertex-inactive.hot': 0.0,
      'gl-draw-polygon-and-line-vertex-stroke-inactive.cold': 0.0,
      'gl-draw-polygon-and-line-vertex-stroke-inactive.hot': 0.0,
      'gl-draw-polygon-fill-active.cold': 0.0,
      'gl-draw-polygon-fill-active.hot': 0.0,
      'gl-draw-polygon-fill-inactive.cold': 0.0,
      'gl-draw-polygon-fill-inactive.hot': 0.0,
      'gl-draw-polygon-fill-static.cold': 0.0,
      'gl-draw-polygon-fill-static.hot': 0.0,
      'gl-draw-polygon-midpoint.cold': 0.0,
      'gl-draw-polygon-midpoint.hot': 0.0,
      'gl-draw-polygon-stroke-active.cold': 0.0,
      'gl-draw-polygon-stroke-active.hot': 0.0,
      'gl-draw-polygon-stroke-inactive.cold': 0.0,
      'gl-draw-polygon-stroke-inactive.hot': 0.0,
      'gl-draw-polygon-stroke-static.cold': 0.0,
      'gl-draw-polygon-stroke-static.hot': 0.0,
    },
    {
      'uploaded-points': 0.0,
      'uploaded-linestrings': 0.0,
    },
    {
      segments: 14.0,
      'segments-no-data': 14.0,
      'segments-no-parking': 14.0,
      'new-garages': 14.0,
      'new-segments': 14.0,
      'new-segments-no-data': 14.0,
      'new-segments-no-parking': 14.0,
      garages: 14.0,
    },
    {
      'uploaded-polygons': 0.0,
    },
  ],
  drawSnapLayer: 'blockfaces',
};

export default MAP_SETTINGS;
