<template>
  <div id="poi-editing" :style="{'pointer-events': saving ? 'none' : 'unset'}">
    <SheetTitle :back-button="editing ? 'back' : 'close'"
                :title="error !== null || loading ? 'POI' : poi.name"
                :action="loading || !canEdit || error !== null ? null : (editing ? 'Save' : 'Edit')"
                :action-disabled="saving || (editing && JSON.stringify(poi) === JSON.stringify(lastSavedPoi))"
                v-on:back-clicked="onBackClicked()"
                v-on:action-clicked="editing ? save() : edit()"/>
    <SheetCard>
      <template v-if="error">
        <SheetCardRow>
          <p>
            Error getting POI: {{ error }}
          </p>
          <MaterialButton v-on:click="getPoi" label="Retry"/>
        </SheetCardRow>
      </template>
      <template v-else-if="!loading">
        <SheetCardRow>
          <Input :type="editing ? 'text' : 'static'" label="Name" v-model="poi.name"/>
          <Input :type="editing ? 'text' : 'static'" label="Type" v-model="poi.format"/>

          <Input
            :type="editing ? 'text' : 'href'"
            label="TicketMaster ID"
            :href="poi.ticket_master_id ? `https://www.ticketmaster.com/venue/${poi.ticket_master_id}` : ''"
            :value="poi.ticket_master_id"
            v-on:input="poi.ticket_master_id = $event"
          />

          <Input
            :type="poi.osm_id ? 'href' : 'static'"
            label="OSM id"
            :href="`https://openstreetmap.org/${poi.osm_type}/${poi.osm_id}`"
            :value="poi.osm_id"
          />
        </SheetCardRow>

        <SheetCardAction
          :icon="require('@/assets/images/location_on-blue-18dp.svg')"
          action="Go to POI"
          v-on:click="this.jumpToPoi"
          :disabled="poi === null"/>

      </template>
    </SheetCard>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import { eventBus, events } from '@/constants/eventBus';
import Input from '@/components/form/Input';
import MaterialButton from '@/components/MaterialButton';
import SheetCard from '@/components/SheetCard';
import SheetCardAction from '@/components/SheetCardAction';
import SheetCardRow from '@/components/SheetCardRow';
import SheetTitle from '@/components/SheetTitle';
import MapDataHelper from '@/utils/mapDataHelper';
import focusOnCreate from '@/utils/focusOnCreate';
import Toast from '@/utils/toast';
import AuthHelper from '@/utils/authHelper';

export default {
  name: 'PoiSheet',
  components: {
    Input,
    MaterialButton,
    SheetCard,
    SheetCardAction,
    SheetCardRow,
    SheetTitle,
  },
  directives: {
    focusOnCreate,
  },
  data() {
    return {
      poi: null,
      lastSavedPoi: null,
      loading: true,
      error: null,
      editing: false,
      saving: false,
      shouldJumpToPoi: false,
      canEdit: false,
    };
  },
  computed: {
    ...mapGetters('editor', ['selection']),
  },
  mounted() {
    this.canEdit = AuthHelper.getUser().role.map_data_edit_pois;
    this.getPoi();
  },
  watch: {
    selection(selection, prevSelection) {
      if (selection.id !== prevSelection.id) {
        this.getPoi();
      }
    },
  },
  methods: {
    onBackClicked() {
      if (this.editing) {
        this.poi = JSON.parse(JSON.stringify(this.lastSavedPoi));
        this.editing = false;
      } else {
        this.dismiss();
      }
    },
    getPoi() {
      this.loading = true;
      this.error = null;
      MapDataHelper.getPoi(this.selection.id, this.onGotPoi, this.onGetPoiError);
    },
    onGotPoi(request, result) {
      if (result.poi.id !== this.selection.id) {
        return;
      }

      this.poi = result.poi;
      this.lastSavedPoi = JSON.parse(JSON.stringify(this.poi));
      this.$store.dispatch('editor/poi', this.poi);

      this.loading = false;

      if (this.shouldJumpToPoi) {
        this.jumpToPoi();
        this.shouldJumpToPoi = false;
      }
    },
    onGetPoiError(request, response) {
      this.error = response.body.error_message || response.code || 'Unknown error';
      this.loading = false;
    },
    jumpToPoi() {
      eventBus.$emit(events.jumpToRequested, [this.poi.lng, this.poi.lat], 20.0);
    },
    edit() {
      this.editing = true;
    },
    save() {
      this.saving = true;
      MapDataHelper.savePoi(this.poi, this.onPoiSaved, this.onSavePoiError);
    },
    onPoiSaved(request, result) {
      this.poi = result.poi;
      this.lastSavedPoi = JSON.parse(JSON.stringify(this.poi));
      this.$store.dispatch('editor/poi', this.poi);
      this.saving = false;
      this.editing = false;
    },
    onSavePoiError(request, response) {
      this.saving = false;
      Toast.danger(
        `Error saving POI: ${response.body.error_message}`,
        !('developer_message' in response.body) ? null : {
          text: 'More info',
          onClick: () => {
            this.$alertDialog.show({
              title: 'POI issue',
              message: response.body.developer_message,
            });
          },
        },
      );
    },
    dismiss() {
      this.$store.dispatch('editor/selection', null);
    },
  },
};
</script>

<style scoped>

#poi-editing {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 375px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #F5F7F8;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, .25);
}

</style>
