import AlertDialog, { alertDialogEventBus } from '@/components/AlertDialog';

export default {
  install(Vue) {
    Vue.component(AlertDialog.name, AlertDialog);
    Vue.prototype.$alertDialog = {
      show(params = {}) {
        alertDialogEventBus.$emit('alert-dialog-show', params);
      },
    };
  },
};
