<template>
  <div id="shuttle-schedule">
    <ItemCard
      v-for="(schedule, i) in schedules"
      :key="schedule.id"
      title="Operating hours"
      v-on:copy="copySchedule(i)"
      v-on:delete="deleteSchedule(i)"
    >

      <div class="editing-form">
        <DaySelection label="Days" :value="getSelectedDays(schedule)" v-on:input="setSelectedDays($event, schedule)"/>
        <TimeSelection label="Hours" :range="true" :optional="true" :value="getSelectedTimes(schedule)" v-on:input="setSelectedTimes($event, schedule)"/>
        <Input type="number" label="Frequency (mins)" v-model="schedule.frequency"/>
        <Checkbox label="On demand" :form-style="true" v-model="schedule.on_demand"/>
      </div>

    </ItemCard>

    <MaterialButton label="Add schedule" v-on:click="addSchedule"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Checkbox from '@/components/form/Checkbox';
import DaySelection from '@/components/form/DaySelection';
import Input from '@/components/form/Input';
import ItemCard from '@/components/editing/ItemCard';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';
import TimeSelection from '@/components/form/TimeSelection';

export default {
  name: 'GarageSchedule',
  components: {
    Checkbox,
    DaySelection,
    Input,
    ItemCard,
    MaterialButton,
    TimeSelection,
  },
  props: {
    value: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      schedules: MapDataHelper.assignRandomIds(this.value),
    };
  },
  computed: {
    ...mapGetters('editor', ['selection']),
  },
  watch: {
    schedules(schedules) {
      this.$emit('input', schedules);
    },
  },
  methods: {
    getSelectedDays(schedule) {
      const selectedDays = [];
      for (let i = 0; i < 7; i++) {
        if (schedule[MapDataHelper.WEEKDAYS[i]]) {
          selectedDays.push((i + 1) % 7);
        }
      }

      return selectedDays;
    },
    setSelectedDays(rawSelectedDays, schedule) {
      for (let i = 0; i < 7; i++) {
        schedule[MapDataHelper.WEEKDAYS[i]] = rawSelectedDays.indexOf((i + 1) % 7) !== -1;
      }
    },
    getSelectedTimes(schedule) {
      return {
        start_hour: schedule.start_time === null ? null : parseInt(schedule.start_time.substr(0, 2), 10),
        start_minute: schedule.start_time === null ? null : parseInt(schedule.start_time.substr(3, 5), 10),
        end_hour: schedule.end_time === null ? null : parseInt(schedule.end_time.substr(0, 2), 10),
        end_minute: schedule.end_time === null ? null : parseInt(schedule.end_time.substr(3, 5), 10),
      };
    },
    setSelectedTimes(rawSelectedTimes, schedule) {
      schedule.start_time = `${rawSelectedTimes.start_hour.toString().padStart(2, '0')}:${rawSelectedTimes.start_minute.toString().padStart(2, '0')}:00`;
      schedule.end_time = `${rawSelectedTimes.end_hour.toString().padStart(2, '0')}:${rawSelectedTimes.end_minute.toString().padStart(2, '0')}:00`;
    },
    addSchedule() {
      this.schedules.push(MapDataHelper.newShuttleSchedule());
    },
    copySchedule(i) {
      this.schedules.push(MapDataHelper.copyItemWithId(this.schedules[i]));
    },
    deleteSchedule(i) {
      this.schedules.splice(i, 1);
    },
  },
};

</script>

<style scoped>

#shuttle-schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#shuttle-schedule > * {
  margin-bottom: 8px;
}

#shuttle-schedule > *:last-child {
  margin-bottom: 32px;
}

</style>
