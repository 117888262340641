<template>
  <div class="duration-selection">
    <span class="duration-selection-label" v-if="label">{{ label }}</span>

    <Input
      class="duration-selection-input"
      type="number"
      :full-width="true"
      :min="0"
      v-model="hours"
    />

    <span class="duration-selection-hours">h</span>

    <Input
      class="duration-selection-input"
      type="number"
      :full-width="true"
      :min="0"
      :max="59"
      v-model="minutes"
    />

    <span class="duration-selection-minutes">m</span>

  </div>
</template>

<script>

import Input from '@/components/form/Input';

export default {
  name: 'DurationSelection',
  components: {
    Input,
  },
  props: {
    label: null,
    optional: {
      type: Boolean,
      default: false,
    },
    value: null,
  },
  data() {
    return {
      hours: this.value === null || Math.trunc(this.value / 60) === 0 ? null : Math.trunc(this.value / 60),
      minutes: this.value === null || this.value % 60 === 0 ? null : this.value % 60,
    };
  },
  watch: {
    hours() {
      this.onChange();
    },
    minutes() {
      this.onChange();
    },
  },
  methods: {
    onChange() {
      this.$emit('input', ((this.hours ?? 0) * 60) + (this.minutes ?? 0));
    },
  },
};

</script>

<style scoped>

.duration-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.duration-selection-label {
  width: 30%;
  text-align: right;
  padding-right: 8px;
}

.duration-selection-hours {
  padding: 0 8px;
}

.duration-selection-minutes {
  padding: 0 0 0 8px;
}

.duration-selection-input {
  flex-basis: 0;
  flex-grow: 1;
}

</style>
