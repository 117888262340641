<template>
  <div ref="geocoderContainer" id="geocoder-container">
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxHelper from '@/utils/mapboxHelper';

export default {
  mounted() {
    this.initGeocoder();
  },
  methods: {
    initGeocoder() {
      this.geocoder = new MapboxGeocoder({
        accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        placeholder: 'Search for a place or segment id',
        localGeocoder: this.localGeocoder,
      });
      this.geocoder.on('result', this.onGeocoderResult);
      this.geocoder.on('clear', this.onGeocoderClear);
      this.geocoder.addTo(this.$refs.geocoderContainer);
      MapboxHelper.mbClient = this.geocoder.geocoderService; // Provide client for global use
      const searchInput = document.querySelector('.mapboxgl-ctrl-geocoder input');
      searchInput.onfocus = () => searchInput.select();
      searchInput.focus();
    },
    localGeocoder(query) {
      const cleanQuery = query.trim().replace(/\s+/g, ' ');

      if (/^\d{5,}$/.test(cleanQuery)) {
        return [{
          type: 'Spot',
          id: parseInt(cleanQuery, 10),
          place_name: `Segment #${cleanQuery}`,
        }];
      }

      // Coordinates regex https://stackoverflow.com/a/18690202/352876
      const groups = /(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/.exec(cleanQuery);
      if (groups !== null) {
        return [{
          type: 'LngLat',
          lngLat: groups.slice(1, 3).reverse(),
          place_name: `Coordinates, ${cleanQuery}`,
        }];
      }

      return [];
    },
    onGeocoderResult(selection) {
      const result = selection.result;
      if (result.type === 'Spot') {
        this.$store.dispatch('editor/selection', { id: result.id, type: 'Regulation' });
        this.$store.dispatch('editor/searchedPlace', null);
      } else if (result.type === 'LngLat') {
        this.$store.dispatch('editor/searchedPlace', { lngLat: result.lngLat, address: result.place_name });
      } else {
        this.$store.dispatch('editor/searchedPlace', { lngLat: result.center, address: result.place_name.split(',')[0] });
      }
    },
    onGeocoderClear() {
      this.$store.dispatch('editor/searchedPlace', null);
    },
  },
};
</script>

<style>
#geocoder-container {
  width: 375px;
  height: 50px;
  margin-left: 30px;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
  height: 100%;
  max-width: none;
  border: 1px solid #CED6DE;
  border-radius: 25px;
  box-shadow: none;
}

.mapboxgl-ctrl-geocoder > svg {
  fill: #1C9BE6;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 14px;
  left: 23px;
}

.mapboxgl-ctrl-geocoder input {
  width: 100%;
  height: 100%;
  padding: 6px 50px;
}

.mapboxgl-ctrl-geocoder input:focus {
  outline: none;
}

.mapboxgl-ctrl-geocoder--pin-right > button {
  top: 9px !important;
  right: 17px !important;
  padding: 6px;
}

.mapboxgl-ctrl-geocoder--pin-right > button:hover {
  background: white !important;
}

.mapboxgl-ctrl-geocoder--pin-right > button:hover > svg {
  fill: #1C9BE6 !important;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin: 0;
}

.suggestions-wrapper .suggestions {
  width: calc(100% - 50px);
  margin: 0 25px;
}

.error-rate {
  display: flex;
  line-height: 18px;
  cursor: help;
}

.tooltip-img {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  z-index: 99999;
  transition: opacity 0.15s ease;
  padding: 2px 8px;
  background: white;
  border: 1px solid #CED6DE;
  border-radius: 8px;
  margin-left: -38px;
  margin-top: 20px;
  pointer-events: none;
}

[data-tooltip]:hover:before {
  opacity: 1;
  pointer-events: initial;
}
</style>
