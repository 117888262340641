<template>
  <div id="fuel-station-editing" :style="{'pointer-events': saving ? 'none' : 'unset'}">
    <SheetTitle :back-button="editing ? 'back' : 'close'"
                :title="error !== null || loading ? 'Fuel station' : fuelStation.name"
                :action="loading || !canEdit || error !== null ? null : (editing ? 'Save' : 'Edit')"
                :action-disabled="saving || (editing && JSON.stringify(fuelStation) === JSON.stringify(lastSavedFuelStation))"
                v-on:back-clicked="onBackClicked()"
                v-on:action-clicked="editing ? save() : edit()"/>
    <SheetCard>
      <template v-if="error">
        <SheetCardRow>
          <p>
            Error getting fuel station: {{ error }}
          </p>
          <MaterialButton v-on:click="getFuelStation" label="Retry"/>
        </SheetCardRow>
      </template>
      <template v-else-if="!loading">
        <SheetTitle title="General information"/>
        <SheetCardRow>
          <Input :type="editing ? 'text' : 'static'" label="Name" v-model="fuelStation.name"/>
          <Input :type="editing ? 'text' : 'static'" label="Phone number" v-model="fuelStation.phone_number"/>

          <Input
            :type="fuelStation.osm_id ? 'href' : 'static'"
            label="OSM id"
            :href="`https://openstreetmap.org/${fuelStation.osm_type}/${fuelStation.osm_id}`"
            :value="fuelStation.osm_id"
          />

          <Input
            :type="editing ? 'text' : 'href'"
            label="GMaps id"
            :href="fuelStation.gmaps_place_id ? `https://www.google.com/maps/place?q=place_id:${fuelStation.gmaps_place_id}` : `https://www.google.com/maps?q=${fuelStation.lat},${fuelStation.lng}`"
            :value="fuelStation.gmaps_place_id"
            v-on:input="fuelStation.gmaps_place_id = $event"
          />

          <Input type="static" label="NREL id" :value="fuelStation.nrel_id"/>
        </SheetCardRow>

        <SheetTitle title="Gas prices"/>
        <SheetCardRow>
          <Input
            ref="regularInput"
            :type="editing ? 'text' : 'static'"
            :select-all-on-focus="true"
            :pattern="priceRegex"
            label="Regular"
            :hint="regularHint"
            v-model="regular"
          />

          <Input
            ref="midgradeInput"
            :type="editing ? 'text' : 'static'"
            :select-all-on-focus="true"
            :pattern="priceRegex"
            label="Midgrade"
            :hint="midgradeHint"
            v-model="midgrade"
          />

          <Input
            ref="premiumInput"
            :type="editing ? 'text' : 'static'"
            :select-all-on-focus="true"
            :pattern="priceRegex"
            label="Premium"
            :hint="premiumHint"
            v-model="premium"
          />

          <Input
            v-show="editing"
            type="number"
            label="Copy prices from"
            action="Copy"
            v-on:action-clicked="copyPrices"
            :value="lastSavedFuelStationId"
          />

        </SheetCardRow>

        <SheetCardRow>
          <Input type="static" label="Last checked" :value="lastChecked"/>
        </SheetCardRow>

        <SheetCardAction
          v-if="canEdit"
          :icon="require('@/assets/images/check-blue-16dp.svg')"
          action="Mark as checked"
          v-on:click="this.markStationAsChecked"
          :disabled="fuelStation === null || saving"/>

        <SheetCardAction
          :icon="require('@/assets/images/location_on-blue-18dp.svg')"
          action="Go to station"
          v-on:click="this.jumpToStation"
          :disabled="fuelStation === null"/>

        <SheetCardAction
          v-if="canEdit"
          :icon="require('@/assets/images/delete-red-24dp.svg')"
          color="#ff4541"
          action="Delete station"
          v-on:click="this.showDeleteStationModal"
          :disabled="fuelStation === null"/>

      </template>
    </SheetCard>
    <Modal
      v-if="error === null && !loading && canEdit"
      ref="deleteModal"
      title="Delete station"
      negative-action="Keep station"
      v-on:negative-action="$refs.deleteModal.closeModal()"
      positive-action="Delete station"
      positive-action-color="#ff4541"
      v-on:positive-action="doDeleteStation"
    >
      <template v-slot:body>
        Delete station '{{ fuelStation.name }}' (id {{ fuelStation.id }})? All related information will be deleted.
      </template>
    </Modal>
  </div>
</template>

<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';

import { eventBus, events } from '@/constants/eventBus';
import ApiHelper from '@/utils/apiHelper';
import Input from '@/components/form/Input';
import MaterialButton from '@/components/MaterialButton';
import Modal from '@/components/Modal';
import SheetCard from '@/components/SheetCard';
import SheetCardAction from '@/components/SheetCardAction';
import SheetCardRow from '@/components/SheetCardRow';
import SheetTitle from '@/components/SheetTitle';
import MapDataHelper from '@/utils/mapDataHelper';
import focusOnCreate from '@/utils/focusOnCreate';
import Toast from '@/utils/toast';
import AuthHelper from '@/utils/authHelper';
import FormatHelper from '@/utils/formatHelper';

function gasPriceObserver(type) {
  return {
    get() {
      return this.fuelStation.gas_prices[type] ? `${this.fuelStation.currency_symbol} ${(this.fuelStation.gas_prices[type].price_cents / 100.0).toFixed(2)}` : null;
    },
    set(newValue) {
      if (newValue === null) {
        Vue.delete(this.fuelStation.gas_prices, type);
        return;
      }

      const priceCents = parseInt(newValue.replace(/\D/g, ''), 10);
      if (Number.isNaN(priceCents)) {
        Vue.delete(this.fuelStation.gas_prices, type);
      } else {
        Vue.set(this.fuelStation.gas_prices, type, { price_cents: priceCents });
      }
    },
  };
}

function gasAgeGetter(type) {
  return {
    get() {
      if (!this.fuelStation.gas_prices[type] || !this.fuelStation.gas_prices[type].reported_at) {
        return null;
      }

      return FormatHelper.shortDateTime(new Date(this.fuelStation.gas_prices[type].reported_at));
    },
  };
}

export default {
  name: 'FuelStationSheet',
  components: {
    Input,
    MaterialButton,
    Modal,
    SheetCard,
    SheetCardAction,
    SheetCardRow,
    SheetTitle,
  },
  directives: {
    focusOnCreate,
  },
  data() {
    return {
      fuelStation: null,
      lastSavedFuelStation: null,
      loading: true,
      error: null,
      editing: false,
      saving: false,
      shouldJumpToStation: false,
      canEdit: false,
      lastSavedFuelStationId: null,
    };
  },
  computed: {
    ...mapGetters('editor', ['selection', 'selectedTicket']),
    regular: gasPriceObserver('regular'),
    regularHint: gasAgeGetter('regular'),
    midgrade: gasPriceObserver('midgrade'),
    midgradeHint: gasAgeGetter('midgrade'),
    premium: gasPriceObserver('premium'),
    premiumHint: gasAgeGetter('premium'),
    lastChecked() {
      if (!this.fuelStation || !this.fuelStation.checked_at) {
        return null;
      }

      return `${FormatHelper.shortDateTime(new Date(this.fuelStation.checked_at))} by ${this.fuelStation.checked_by}`;
    },
    priceRegex() {
      return `${this.fuelStation.currency_symbol.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')} \\d+\\.\\d{2}`;
    },
  },
  mounted() {
    this.canEdit = AuthHelper.getUser().role.map_data_edit_fuel_stations;

    if (this.selectedTicket !== null && this.selectedTicket.category === ApiHelper.TICKET_TYPES.GAS_PRICES_PICTURE && this.selectedTicket.fuel_station_id === this.selection.id) {
      this.editing = true;
      this.shouldJumpToStation = true;
    }

    this.lastSavedFuelStationId = parseInt(window.localStorage.getItem('FuelStationSheet.lastSavedStationId'), 10);

    this.getFuelStation();
  },
  watch: {
    selection(selection, prevSelection) {
      if (selection.id !== prevSelection.id) {
        this.getFuelStation();
      }
    },
  },
  methods: {
    onBackClicked() {
      if (this.editing) {
        this.fuelStation = JSON.parse(JSON.stringify(this.lastSavedFuelStation));
        this.editing = false;
      } else {
        this.dismiss();
      }
    },
    getFuelStation() {
      this.loading = true;
      this.error = null;
      MapDataHelper.getFuelStation(this.selection.id, this.onGotFuelStation, this.onGetFuelStationError);
    },
    onGotFuelStation(request, result) {
      if (result.fuel_station.id !== this.selection.id) {
        return;
      }

      this.fuelStation = result.fuel_station;
      this.lastSavedFuelStation = JSON.parse(JSON.stringify(this.fuelStation));
      this.$store.dispatch('editor/fuelStation', this.fuelStation);
      this.loading = false;

      if (this.shouldJumpToStation) {
        this.jumpToStation();
        this.shouldJumpToStation = false;
      }
    },
    onGetFuelStationError(request, response) {
      this.error = response.body.error_message || response.code || 'Unknown error';
      this.loading = false;
    },
    jumpToStation() {
      eventBus.$emit(events.jumpToRequested, [this.fuelStation.lng, this.fuelStation.lat]);
    },
    edit() {
      this.editing = true;
    },
    copyPrices(stationId) {
      MapDataHelper.getFuelStation(stationId, this.onGotSourceFuelStation, this.onGetSourceFuelStationError);
    },
    onGotSourceFuelStation(request, response) {
      this.fuelStation.gas_prices = response.fuel_station.gas_prices;
      Toast.info('Prices copied');
    },
    onGetSourceFuelStationError(request, response) {
      Toast.danger(`Error copying prices: ${response.body.error_message}`);
    },
    save() {
      if (
        !this.$refs.regularInput.checkValidity()
        || !this.$refs.midgradeInput.checkValidity()
        || !this.$refs.premiumInput.checkValidity()
      ) {
        Toast.danger('Please input valid prices');
        return;
      }

      this.saving = true;

      let selectedTicketId = null;
      if (this.selectedTicket !== null && this.selectedTicket.fuel_station_id === this.fuelStation.id) {
        selectedTicketId = this.selectedTicket.id;
      }

      MapDataHelper.saveFuelStation(this.fuelStation, selectedTicketId, this.onFuelStationSaved, this.onSaveFuelStationError);
    },
    onFuelStationSaved(request, result) {
      this.fuelStation = result.fuel_station;
      this.lastSavedFuelStation = JSON.parse(JSON.stringify(this.fuelStation));
      this.$store.dispatch('editor/fuelStation', this.fuelStation);
      this.saving = false;
      this.editing = false;

      this.lastSavedFuelStationId = this.fuelStation.id;
      window.localStorage.setItem('FuelStationSheet.lastSavedStationId', this.fuelStation.id);

      if (result.processed_ticket_id !== null) {
        eventBus.$emit(events.ticketProcessed, result.processed_ticket_id);
        Toast.info('Ticket processed');
      }
    },
    onSaveFuelStationError(request, response) {
      this.saving = false;
      Toast.danger(
        `Error saving fuel station: ${response.body.error_message}`,
        !('developer_message' in response.body) ? null : {
          text: 'More info',
          onClick: () => {
            this.$alertDialog.show({
              title: 'Fuel station issue',
              message: response.body.developer_message,
            });
          },
        },
      );
    },
    markStationAsChecked() {
      this.saving = true;
      MapDataHelper.markFuelStationAsChecked(this.fuelStation.id, this.onStationMarkedAsChecked, this.onMarkStationAsCheckedError);
    },
    onStationMarkedAsChecked(request, result) {
      this.fuelStation = result.fuel_station;
      this.lastSavedFuelStation = JSON.parse(JSON.stringify(this.fuelStation));
      this.$store.dispatch('editor/fuelStation', this.fuelStation);
      Toast.info('Station marked as checked');
      this.saving = false;
    },
    onMarkStationAsCheckedError(request, response) {
      Toast.danger(`Error marking station as checked: ${response.body.error_message}`);
      this.saving = false;
    },
    showDeleteStationModal() {
      this.$refs.deleteModal.openModal();
    },
    doDeleteStation() {
      const id = this.fuelStation.id;
      MapDataHelper.deleteFuelStation(id, () => this.onFuelStationDeleted(id), this.onDeleteFuelStationError);
    },
    onFuelStationDeleted(id) {
      this.$store.dispatch('editor/deletedFuelStation', id);
      eventBus.$emit(events.fuelStationDeleted, id);
      if (this.selection.type === 'FuelStation' && this.selection.id === id) {
        this.dismiss();
      }
      Toast.info(`Station #${id} successfully deleted`);
    },
    onDeleteFuelStationError(request, response) {
      Toast.danger(`Error deleting fuel station: ${response.body.error_message}`);
    },
    dismiss() {
      this.$store.dispatch('editor/selection', null);
    },
  },
};
</script>

<style scoped>

#fuel-station-editing {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 375px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #F5F7F8;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, .25);
}

</style>
