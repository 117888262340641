import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import AlertDialog from '@/plugins/alertDialog';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(VueLazyload);
Vue.use(AlertDialog);
