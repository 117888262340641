<template>
  <div id="garage-editing" :style="{'pointer-events': saving ? 'none' : 'unset'}">
    <div id="garage-summary-wrapper">
      <SheetTitle :sticky="true" :title="spotInfo !== null ? spotInfo.status.garage_name : 'New garage'" back-button="Back" v-on:back-clicked="cancel" action="Save" v-on:action-clicked="saveChanges" :action-disabled="saving"/>
      <div id="garage-summary">
        <SheetCard>
          <template v-if="loading">
            <SheetCardRow>
              Loading...
            </SheetCardRow>
          </template>
          <template v-else>
            <SheetTitle title="General information"/>
            <SheetCardRow>
              <div class="checkbox-row">
                <div class="checkbox-column">
                  <Checkbox v-model="spotData.garage_properties.customer" label="Customer "/>
                  <Checkbox v-model="spotData.garage_properties.shuttle" label="Shuttle "/>
                  <Checkbox v-model="spotData.garage_properties.airport_parking" label="Airport parking "/>
                  <Checkbox v-model="spotData.garage_properties.promoted" label="Promoted "/>
                  <Checkbox v-model="spotData.garage_properties.overnight_allowed" label="Overnight allowed "/>
                </div>
                <div class="checkbox-column">
                  <Checkbox v-model="spotData.garage_properties.monthly_visible" label="Monthly visible "/>
                  <Checkbox v-model="spotData.garage_properties.monthly_only" label="Monthly only "/>
                  <Checkbox v-model="spotData.garage_properties.monthly_full" label="Monthly full "/>
                  <Checkbox v-model="spotData.garage_properties.license_plate_required" label="License plate required "/>
                </div>
              </div>
              <Input label="Name" v-model="spotData.garage_properties.garage_name"/>
              <Select label="Type" :options="GARAGE_TYPES" v-model="spotData.garage_properties.garage_type"/>
              <Select
                label="Operator"
                :options="operators"
                label-key="operator_name"
                value-key="id"
                v-model="spotData.garage_properties.operator_id"
                v-on:input="onOperatorSelected($event)"
                v-on:search-change="onOperatorSearch($event)"
              />
              <Select label="Owner" :options="GARAGE_OWNERS" v-model="spotData.garage_properties.owner"/>
              <Input label="E-mail" v-model="spotData.garage_properties.email"/>
              <Input label="Phone number" v-model="spotData.garage_properties.phone"/>
              <Input label="Operator URL" v-model="spotData.garage_properties.operator_url"/>
              <Input label="GMaps place ID" v-model="spotData.garage_properties.gmaps_place_id"/>
              <Select label="GMaps status" :options="['Pending', 'Claimed']" v-model="spotData.garage_properties.gmaps_status"/>
              <Input type="number" label="Spot count" v-model="spotData.segment_properties.spot_count"/>
              <Select label="Receive requests" :options="NULLABLE_BOOL_OPTS" label-key="label" value-key="value" v-model="receiveRequests"/>
              <Select label="Monthly discount allowed" :options="NULLABLE_BOOL_OPTS" label-key="label" value-key="value" v-model="monthlyDiscountAllowed"/>
              <Select label="Monthly form" :options="monthlyMetadata.monthly_forms" label-key="form_name" value-key="id" v-model="spotData.garage_properties.monthly_form_id"/>
              <Select label="Monthly email" :options="monthlyMetadata.monthly_email_templates" label-key="template_name" value-key="id" v-model="spotData.garage_properties.monthly_email_template_id"/>
              <Input type="textarea" label="Booking notes" v-model="spotData.garage_properties.monthly_booking_notes"/>
              <Input type="textarea" label="Notes" v-model="spotData.garage_properties.notes"/>
              <Input type="textarea" label="Validation" v-model="spotData.garage_properties.validation_instructions"/>
              <Input type="number" label="Oversize surcharge" v-model="spotData.garage_properties.oversize_surcharge"/>
            </SheetCardRow>

            <SheetTitle :title="`Venues (${garagePoiUrls.length})`" action="Edit" v-on:action-clicked="editingSection = 'SpotAngels venues url'"/>
            <SheetCardRow v-if="garagePoiUrls.length > 0">
              <span v-for="(poi_url, i) in garagePoiUrls" :key="i">{{ poi_url }}</span>
            </SheetCardRow>

            <SheetTitle :title="`Entrances (${spotData.garage_entrances.length})`" action="Edit" v-on:action-clicked="editingSection = 'entrances'"/>
            <SheetCardRow v-if="spotData.garage_entrances.length > 0">
              <span v-for="(entrance, i) in spotData.garage_entrances" :key="i">{{ entrance.address }} - {{ entrance.geom_entrance }}</span>
            </SheetCardRow>

            <SheetTitle :title="`Payment (${spotData.garage_payments.length})`" action="Edit" v-on:action-clicked="editingSection = 'payment'"/>
            <SheetCardRow v-if="spotData.garage_payments.length > 0">
              <span v-for="(payment, i) in spotData.garage_payments" :key="i">
                <template v-if="payment.provider_id && payment.payment_id">
                  {{ paymentProviders.find(p => p.id === payment.provider_id).provider_name }} - {{ payment.payment_id }}
                </template>
              </span>
            </SheetCardRow>

            <SheetTitle title="Opening Hours" action="Edit" v-on:action-clicked="editingSection = 'schedule'"/>
            <div class="opening-hours">
              <Calendar v-if="initialCalendar !== null" :initial-regulation="initialCalendar[0]" :updated-regulation="updatedCalendar[0]" section="months"/>
            </div>

            <SheetTitle :title="`Rates (${spotData.garage_rates.length})`" action="Edit" v-on:action-clicked="editingSection = 'rates'"/>
            <GaragePriceInfo v-if="spotData.garage_rates.length > 0" :value="spotInfo !== null && spotInfo.garage_info !== null ? spotInfo.garage_info.find((i) => i.type === 0) : null"/>

            <SheetTitle :title="`Deals (${spotData.garage_deals.length})`" action="Edit" v-on:action-clicked="editingSection = 'deals'"/>
            <GaragePriceInfo v-if="spotData.garage_deals.length > 0" :value="spotInfo !== null && spotInfo.garage_info !== null ? spotInfo.garage_info.find((i) => i.type === 1) : null"/>

            <SheetCardRow>
              <Input
                type="number"
                label="Copy rates & deals from"
                action="Copy"
                v-on:action-clicked="copyRatesAndDeals"
                :value="lastSavedSegmentId"
              />
            </SheetCardRow>

            <SheetTitle :title="`Monthly rates (${spotData.garage_monthly_rates.length})`" action="Edit" v-on:action-clicked="editingSection = 'monthly rates'"/>
            <SheetCardRow v-if="spotData.garage_monthly_rates.length > 0">
              <span v-for="(rate, i) in spotData.garage_monthly_rates" :key="i">
                {{ (monthlyTypes.find(t => t.id === rate.monthly_type_id) || { label: rate.monthly_type_id }).label }} - ${{ rate.price }}
              </span>
            </SheetCardRow>

            <SheetTitle :title="`Shuttle schedules (${spotData.shuttle_schedules.length})`" action="Edit" v-on:action-clicked="editingSection = 'shuttle schedules'"/>
            <SheetCardRow v-if="spotData.shuttle_schedules.length > 0">
              <span v-for="(schedule, i) in spotData.shuttle_schedules" :key="i">
                {{ shuttleScheduleSummary(schedule) }}
              </span>
            </SheetCardRow>
          </template>
        </SheetCard>
      </div>
    </div>
    <div v-if="editingSection" id="section-editing">
      <div id="section-editing-title">
        <span>{{ editingSection }}</span>
        <ImageButton title="Close" alt="Close" :src="require('@/assets/images/close-black-24dp.svg')" v-on:click="editingSection = null"/>
      </div>
      <GaragePoiUrls v-if="editingSection === 'SpotAngels venues url'" v-model="garagePoiUrls"/>
      <GarageEntrances v-if="editingSection === 'entrances'" v-model="spotData.garage_entrances"/>
      <GaragePayment v-else-if="editingSection === 'payment'" v-model="spotData.garage_payments"/>
      <GarageSchedule v-else-if="editingSection === 'schedule'" v-model="spotData.regulations"/>
      <GarageRates v-else-if="editingSection === 'rates'" v-model="spotData.garage_rates"/>
      <GarageDeals v-else-if="editingSection === 'deals'" v-model="spotData.garage_deals"/>
      <GarageMonthlyRates v-else-if="editingSection === 'monthly rates'" v-model="spotData.garage_monthly_rates"/>
      <ShuttleSchedule v-else-if="editingSection === 'shuttle schedules'" v-model="spotData.shuttle_schedules"/>
    </div>
  </div>
</template>

<script>

import Checkbox from '@/components/form/Checkbox';
import SheetCard from '@/components/SheetCard';
import SheetTitle from '@/components/SheetTitle';
import SheetCardRow from '@/components/SheetCardRow';
import MapDataHelper from '@/utils/mapDataHelper';
import Input from '@/components/form/Input';
import Select from '@/components/form/Select';
import Toast from '@/utils/toast';
import GaragePayment from '@/components/editing/GaragePayment';
import GarageEntrances from '@/components/editing/GarageEntrances';
import EditSheetMixin from '@/components/editing/EditSheetMixin';
import GarageSchedule from '@/components/editing/GarageSchedule';
import Calendar from '@/components/editing/Calendar';
import GarageRates from '@/components/editing/GarageRates';
import GarageMonthlyRates from '@/components/editing/GarageMonthlyRates';
import ImageButton from '@/components/ImageButton';
import GarageDeals from '@/components/editing/GarageDeals';
import GaragePriceInfo from '@/components/editing/GaragePriceInfo';
import GaragePoiUrls from '@/components/editing/GaragePoiUrls';
import ShuttleSchedule from '@/components/editing/ShuttleSchedule';
import FormatHelper from '@/utils/formatHelper';

export default {
  name: 'EditGarageSheet',
  mixins: [
    EditSheetMixin,
  ],
  components: {
    GaragePoiUrls,
    GaragePriceInfo,
    GarageDeals,
    ImageButton,
    GarageRates,
    GarageMonthlyRates,
    Calendar,
    GarageSchedule,
    GarageEntrances,
    GaragePayment,
    Select,
    Input,
    SheetCardRow,
    SheetTitle,
    SheetCard,
    ShuttleSchedule,
    Checkbox,
  },
  props: {
    spotInfo: null,
    garageDetails: null,
  },
  data() {
    return {
      GARAGE_TYPES: MapDataHelper.GARAGE_TYPES,
      GARAGE_OWNERS: MapDataHelper.GARAGE_OWNERS,
      NULLABLE_BOOL_OPTS: [
        { label: 'Yes (overrides operator setting)', value: 'true' },
        { label: 'No (overrides operator setting)', value: 'false' },
      ],
      searchedOperators: [],
      monthlyMetadata: {},
      paymentProviders: [],
      monthlyTypes: [],
      editingSection: null,
    };
  },
  computed: {
    operators: {
      get() {
        const operators = this.searchedOperators;
        if (operators.length === 0 && this.spotData !== null && this.spotData.garage_properties.operator_id && this.garageDetails !== null) {
          return [{ id: this.spotData.garage_properties.operator_id, operator_name: this.garageDetails.operator_name }];
        }

        return operators;
      },
    },
    receiveRequests: {
      get() {
        // Must use true/false as strings because Select is buggy with boolean values
        return this.spotData.garage_properties.receive_requests === null ? null : this.spotData.garage_properties.receive_requests.toString();
      },
      set(receiveRequests) {
        if (receiveRequests === null) {
          this.spotData.garage_properties.receive_requests = null;
        } else {
          this.spotData.garage_properties.receive_requests = receiveRequests === 'true';
        }
      },
    },
    monthlyDiscountAllowed: {
      get() {
        // Must use true/false as strings because Select is buggy with boolean values
        return this.spotData.garage_properties.monthly_discount_allowed === null ? null : this.spotData.garage_properties.monthly_discount_allowed.toString();
      },
      set(receiveRequests) {
        if (receiveRequests === null) {
          this.spotData.garage_properties.monthly_discount_allowed = null;
        } else {
          this.spotData.garage_properties.monthly_discount_allowed = receiveRequests === 'true';
        }
      },
    },
    garagePoiUrls: {
      get() {
        return this.spotData.garage_properties.poi_url ? this.spotData.garage_properties.poi_url.split(',') : [];
      },
      set(garagePoiUrls) {
        this.spotData.garage_properties.poi_url = garagePoiUrls.filter((url) => url !== null && !!url.trim()).join(',');
      },
    },
  },
  created() {
    MapDataHelper.getPaymentProviders((request, response) => { this.paymentProviders = response.providers; }, () => Toast.danger('Error loading payment providers'));
    MapDataHelper.getMonthlyTypes((request, response) => { this.monthlyTypes = response.monthly_types; }, () => Toast.danger('Error loading monthly types'));
  },
  mounted() {
    if (this.spotData && this.spotData.garage_properties && this.spotData.garage_properties.operator_id) {
      MapDataHelper.getOperatorMonthlyMetadata(this.spotData.garage_properties.operator_id, (request, response) => { this.monthlyMetadata = response; }, () => Toast.danger('Error loading operator monthly metadata'));
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'spotData.garage_properties.operator_id': function (operatorId) {
      if (operatorId) {
        MapDataHelper.getOperatorMonthlyMetadata(operatorId, (request, response) => { this.monthlyMetadata = response; }, () => Toast.danger('Error loading operator monthly metadata'));
      }
    },
  },
  methods: {
    onOperatorSearch(query) {
      if (query === '') {
        this.searchedOperators = [];
      } else {
        MapDataHelper.searchOperators(query, (request, response) => { this.searchedOperators = response.operators; }, () => Toast.danger('Error loading operators'));
      }
    },
    onOperatorSelected(operatorId) {
      if (this.garageDetails !== null) {
        this.garageDetails.operator_name = operatorId !== null ? this.operators.find((o) => o.id === operatorId).operator_name : null;
      }
    },
    shuttleScheduleSummary(schedule) {
      let summary = '';
      const selectedDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].filter((day) => schedule[day]);

      if (selectedDays.length === 7) {
        if (schedule.start_time === '00:00:00' && schedule.end_time === '24:00:00') {
          summary += '24/7';
        } else {
          summary += 'Every day';
        }
      } else {
        summary += selectedDays.join(', ');
      }

      if (schedule.start_time === '00:00:00' && schedule.end_time === '24:00:00') {
        if (selectedDays.length < 7) {
          summary += ', all day';
        }
      } else {
        summary += `, from ${FormatHelper.shortTime(new Date(`1970-01-01T${schedule.start_time}`))} to ${FormatHelper.shortTime(new Date(`1970-01-01T${schedule.end_time}`))}`;
      }

      if (schedule.frequency !== null) {
        summary += `, every ${schedule.frequency} mins`;
      }

      if (schedule.on_demand) {
        summary += ', on demand';
      }

      return summary;
    },
    copyRatesAndDeals(input) {
      const segmentId = parseInt(input, 10);
      if (Number.isNaN(segmentId)) {
        Toast.danger('Please enter a valid segment id');
      } else if (segmentId === this.spotInfo.status.id) {
        Toast.danger('Given segment id is the currently selected segment');
      } else {
        MapDataHelper.getSpotData(segmentId, this.onGotSpotDataForCopy, this.onSpotDataForCopyError);
      }
    },
    onGotSpotDataForCopy(request, result) {
      if (result.data.garage_rates.length === 0 && result.data.garage_deals.length === 0) {
        Toast.danger('No rates or deals found for given segment');
      } else {
        this.spotData.garage_rates = result.data.garage_rates;
        this.spotData.garage_deals = result.data.garage_deals;
        Toast.info('Rates and deals copied');
      }
    },
    onSpotDataForCopyError(request, response) {
      Toast.danger(`Error getting spot data for copy: ${response.body.error_message}`);
    },
  },
};

</script>

<style scoped>

#garage-editing {
  height: 100%;
  display: flex;
  flex-direction: row;
}

#garage-summary-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, 0.25);
}

#garage-summary {
  width: 375px;
  height: 100%;
  padding-bottom: 160px;
  overflow-x: hidden;
  overflow-y: auto;
}

.opening-hours {
  border-bottom: 1px solid #CED6DE;
}

#section-editing {
  width: 375px;
  padding: 16px;
  user-select: none;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

#section-editing-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#section-editing-title > span {
  margin-left: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
}

#section-editing > * {
  margin-bottom: 8px;
}

#section-editing > *:last-child {
  margin-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.form-input {
  margin-bottom: 8px;
}

/*noinspection CssUnusedSymbol*/
.form-input:last-child {
  margin-bottom: 0;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.checkbox-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

</style>
