<template>
  <div id="container-settings">
    <div class="panel-title">
      Settings
    </div>
    <div
      v-if="canUseGoogleStreetView"
      class="panel-group"
    >
      <div class="panel-group-title">
        Imagery Source
      </div>
      <StoreRadioButton path="editor/imagerySource" value="Mapillary"/>
      <StoreRadioButton path="editor/imagerySource" value="Google Street View"/>
    </div>
    <div class="panel-group">
      <div class="panel-group-title">
        Map Features
      </div>
      <StoreCheckboxButton path="editor/mapFeatures" value="Parking signs"/>
      <StoreCheckboxButton path="editor/mapFeatures" value="Parking meters"/>
      <StoreCheckboxButton path="editor/mapFeatures" value="Fire hydrants"/>
      <StoreCheckboxButton path="editor/mapFeatures" value="Bus stops"/>
      <StoreCheckboxButton path="editor/mapFeatures" value="Street imagery trace"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AuthHelper from '@/utils/authHelper';

function getPath(obj, path) {
  let result = obj;
  for (const key of path.split('/')) {
    result = result[key];
  }
  return result;
}

const StoreRadioButton = {
  props: ['path', 'value'],
  computed: {
    storeValue() {
      return getPath(this.$store.state, this.path);
    },
    checked() {
      return this.storeValue === this.value;
    },
  },
  methods: {
    onChange() {
      this.$store.commit(this.path, this.value);
    },
  },
  render() {
    const id = this.value.replace(/ /g, '-').toLowerCase();
    return (
      <div>
        <input
          type="radio"
          id={id}
          value={this.value}
          checked={this.checked}
          onChange={this.onChange}
        />
        <label for={id}>{this.value}</label>
      </div>
    );
  },
};

const StoreCheckboxButton = {
  props: ['path', 'value'],
  computed: {
    storeValue() {
      return getPath(this.$store.state, this.path);
    },
    checked() {
      return this.storeValue[this.value] === true;
    },
  },
  methods: {
    onChange(event) {
      const storeValue = { ...this.storeValue };
      storeValue[this.value] = event.target.checked;
      this.$store.commit(this.path, storeValue);
    },
  },
  render() {
    const id = this.value.replace(/ /g, '-').toLowerCase();
    return (
      <div>
        <input
          type="checkbox"
          id={id}
          checked={this.checked}
          onChange={this.onChange}
        />
        <label for={id}>{this.value}</label>
      </div>
    );
  },
};

export default {
  name: 'Settings',
  components: {
    StoreRadioButton,
    StoreCheckboxButton,
  },
  computed: {
    ...mapGetters(
      'editor',
      [
        'apiReference',
      ],
    ),
    canUseGoogleStreetView() {
      const ambassadorRole = this.apiReference.roles.find(
        (role) => role.slug === 'ambassador',
      );
      return AuthHelper.getUser().role.rank >= ambassadorRole.rank;
    },
  },
};
</script>

<style scoped>

#container-settings {
  height: 100%;
  font-size: 16px;
  color: #212529;
  background-color: #F5F7F8;
  line-height: 18px;
}

.panel-title {
  height: 56px;
  background-color: white;
  font-weight: 600;
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid #CED6DE;
}

.panel-group {
  background-color: white;
  margin-top: 24px;
  padding: 16px;
  line-height: 32px;
  border-top: 1px solid #CED6DE;
  border-bottom: 1px solid #CED6DE;
}

.panel-group-title {
  font-weight: 600;
}

</style>
