<template>
  <div id="changeset-sheet">
    <SheetTitle :title="`Changeset #${selectedChangeset.id}`" back-button="close" v-on:back-clicked="close()"/>
    <template v-if="spotInfo !== null" :style="{'visibility': (isLoadingSpotInfo || isLoadingPrevSpotInfo) ? 'hidden' : 'visible'}">
      <div v-for="regulation in activeRegulations" :key="regulation">
        <SheetTitle :title="regulation" :key="regulation" :title-class="regulationTitleClass(regulation)"/>
        <Calendar :initial-regulation="initialRegulation(regulation)" :updated-regulation="updatedRegulation(regulation)" section="months"/>
        <Calendar v-if="regulation.prices" :initial-regulation="initialRegulation(regulation)" :updated-regulation="updatedRegulation(regulation)" section="prices"/>
      </div>
    </template>
    <SheetCardRow title="Additional information">
      <span class="changeset-info-row">
        Submitted {{ (new Date(selectedChangeset.submitted_at)).toLocaleDateString('en-US', { 'month': 'short', 'day': 'numeric', 'year': 'numeric', }) }}
        at {{ (new Date(selectedChangeset.submitted_at)).toLocaleTimeString('en-US', { 'hour': 'numeric', 'minute': 'numeric', }) }}
      </span>
      <span class="changeset-info-row" v-if="selectedChangeset.created_at !== selectedChangeset.submitted_at">
        {{ selectedChangeset.is_active ? 'Restored' : 'Discarded' }} {{ (new Date(selectedChangeset.created_at)).toLocaleDateString('en-US', { 'month': 'short', 'day': 'numeric', 'year': 'numeric', }) }}
        at {{ (new Date(selectedChangeset.created_at)).toLocaleTimeString('en-US', { 'hour': 'numeric', 'minute': 'numeric', }) }}
      </span>
      <span class="changeset-info-row">{{ selectedChangeset.prev_changeset_id ? `Replaces changeset #${selectedChangeset.prev_changeset_id}` : 'Initial changeset' }}</span>
      <span class="changeset-info-row" style="color: #1DCF32" v-if="selectedChangeset.is_active">Active changeset</span>
      <span class="changeset-info-row" style="color: #1C9BE6" v-if="selectedChangeset.is_pending">Pending validation</span>
    </SheetCardRow>
    <div class="changeset-actions">
      <MaterialButton class="changeset-action" :src="require('@/assets/images/check-blue-16dp.svg')" label="Make active" v-on:click="restoreChangeset" :disabled="selectedChangeset.is_active || isRestoringChangeset"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import MaterialButton from '@/components/MaterialButton';
import Calendar from '@/components/editing/Calendar';
import SheetTitle from '@/components/SheetTitle';
import RegulationsHelper from '@/utils/regulationsHelper';
import SheetCardRow from '@/components/SheetCardRow';
import MapDataHelper from '@/utils/mapDataHelper';
import Toast from '@/utils/toast';
import { eventBus, events } from '@/constants/eventBus';

export default {
  name: 'ChangesetSheet',
  components: {
    SheetCardRow,
    Calendar,
    MaterialButton,
    SheetTitle,
  },
  mounted() {
    this.onSelectedChangesetChanged(this.selectedChangeset);
  },
  data() {
    return {
      spotInfo: null,
      prevSpotInfo: null,
      isLoadingSpotInfo: false,
      isLoadingPrevSpotInfo: false,
      isRestoringChangeset: false,
    };
  },
  computed: {
    ...mapGetters('editor', ['selection', 'selectedChangeset']),
    activeRegulations() {
      return [...new Set(this.spotInfo.calendar.map((item) => item.name).concat(this.prevSpotInfo === null ? [] : this.prevSpotInfo.calendar.map((item) => item.name)))];
    },
  },
  watch: {
    selectedChangeset(selectedChangeset) {
      this.onSelectedChangesetChanged(selectedChangeset);
    },
  },
  methods: {
    onSelectedChangesetChanged(selectedChangeset) {
      if (selectedChangeset === null) {
        this.spotInfo = null;
        this.prevSpotInfo = null;
        return;
      }

      // TODO LRU cache to avoid querying regulations too much when clicking back and forth between changesets
      this.isLoadingSpotInfo = true;
      RegulationsHelper.getSpotInfo(this.selection.id, selectedChangeset.is_active ? null : selectedChangeset.id, this.onGotSpotInfo, this.onSpotInfoError);
      if (selectedChangeset.prev_changeset_id !== null) {
        this.isLoadingPrevSpotInfo = true;
        RegulationsHelper.getSpotInfo(this.selection.id, selectedChangeset.prev_changeset_id, this.onGotPrevSpotInfo, this.onPrevSpotInfoError);
      } else {
        this.prevSpotInfo = null;
      }
    },
    onGotSpotInfo(request, result) {
      this.spotInfo = result;
      this.isLoadingSpotInfo = false;
    },
    onSpotInfoError() {
      // TODO
      this.isLoadingSpotInfo = false;
    },
    onGotPrevSpotInfo(request, result) {
      this.prevSpotInfo = result;
      this.isLoadingPrevSpotInfo = false;
    },
    onPrevSpotInfoError() {
      // TODO
      this.isLoadingPrevSpotInfo = false;
    },
    restoreChangeset() {
      this.isRestoringChangeset = true;
      const segmentId = this.selection.id;
      const changesetId = this.selectedChangeset.id;
      MapDataHelper.restoreChangeset(changesetId, (request, result) => this.onChangesetRestored(segmentId, changesetId, result), this.onRestoreChangesetError);
    },
    onChangesetRestored(segmentId, changesetId, result) {
      this.isRestoringChangeset = false;
      if (segmentId === this.selection.id) {
        this.$store.dispatch('editor/changesetHistory', { segmentId, changesets: result.changesets });
        if (changesetId === this.selectedChangeset.id) {
          this.$store.dispatch('editor/selectedChangeset', result.changesets.find((c) => c.id === changesetId));
        }
      }

      eventBus.$emit(events.changesetRestored);
      Toast.info(`Changeset ${changesetId} successfully made active`);
    },
    onRestoreChangesetError(request, result) {
      this.isRestoringChangeset = false;
      Toast.danger(`Error making changeset active: ${result.error_message}`);
    },
    regulationTitleClass(regulation) {
      const isInInitial = this.initialRegulation(regulation) !== null;
      const isInUpdated = this.updatedRegulation(regulation) !== null;
      return {
        'item-added': !isInInitial && isInUpdated,
        'item-removed': isInInitial && !isInUpdated,
      };
    },
    initialRegulation(regulation) {
      return this.prevSpotInfo === null ? null : (this.prevSpotInfo.calendar.find((item) => item.name === regulation) || null);
    },
    updatedRegulation(regulation) {
      return this.spotInfo.calendar.find((item) => item.name === regulation) || null;
    },
    close() {
      this.$store.dispatch('editor/selectedChangeset', null);
    },
  },
};
</script>

<style scoped>

#changeset-sheet {
  width: 375px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.25);
}

.changeset-info-row {
  font-size: 14px;
  line-height: 1.3em;
}

.changeset-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.changeset-action {
  flex-grow: 1;
  border-radius: 0;
}

#changeset-actions button {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0;
}

/*noinspection CssUnusedSymbol*/
.item-removed {
  color: #ff4541;
  text-decoration: line-through;
}

/*noinspection CssUnusedSymbol*/
.item-added {
  color: #1dcf32;
}

</style>
