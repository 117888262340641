const DEFAULT_SELECTION = {
  id: null,
  type: null,
  tmpId: null,
  garage: null,
  features: [],
};

const DEFAULT_SV_CAMERA = {
  position: null,
  heading: null,
};

const DEFAULT_SEARCHED_PLACE = {
  lngLat: null,
  address: null,
};

const DEFAULT_CHANGESET_HISTORY = {
  segmentId: null,
  changesets: [],
};

const DEFAULT_AREAS_TO_MAP = {
  type: 'FeatureCollection',
  features: [],
};

function simpleSetters(keys) {
  const result = {};
  for (const key of keys) {
    result[key] = (state, payload) => {
      localStorage.setItem(key, JSON.stringify(payload));
      state[key] = payload;
    };
  }
  return result;
}

function simpleGetters(keys) {
  const result = {};
  for (const key of keys) {
    result[key] = (state) => state[key];
  }
  return result;
}

function loadFromLocalStorage(key, defaultValue) {
  let v = localStorage.getItem(key);
  if (v === null) {
    v = JSON.stringify(defaultValue);
  }
  let value;
  try {
    value = JSON.parse(v);
  } catch (error) {
    value = defaultValue;
  }
  return {
    [key]: value,
  };
}

export default {
  namespaced: true,
  state: {
    loadingApp: true,
    loadingError: false,
    selectedTicket: null,
    selection: DEFAULT_SELECTION,
    changesetHistory: DEFAULT_CHANGESET_HISTORY,
    selectedChangeset: null,
    spotInfo: null,
    fuelStation: null,
    poi: null,
    featureChanges: {},
    pendingSpotData: {},
    temporaryFeatureDraw: null,
    areasToMap: DEFAULT_AREAS_TO_MAP,
    spotsToCheck: {},
    streetViewEnabled: false,
    settingsEnabled: false,
    streetViewCamera: DEFAULT_SV_CAMERA,
    streetViewPositionClick: null,
    deletedSpotIds: [],
    deletedFuelStationIds: [],
    onMapClickListener: null,
    searchedPlace: DEFAULT_SEARCHED_PLACE,
    apiReference: null,
    baseMap: 'Open Street Map', // 'Open Street Map' | 'Satellite'
    ...loadFromLocalStorage('imagerySource', 'Mapillary'), // 'Mapillary' | 'Google Street View'
    ...loadFromLocalStorage('mapFeatures', {}),
  },
  mutations: {
    ...simpleSetters(['baseMap', 'imagerySource', 'mapFeatures']),
    setLoadingApp(state, payload) {
      state.loadingApp = payload;
    },
    setLoadingError(state, payload) {
      state.loadingError = payload;
    },
    setApiReference(state, payload) {
      state.apiReference = payload;
    },
    setSelectedTicket(state, payload) {
      state.selectedTicket = payload;
      if (payload !== null) {
        state.selectedChangeset = null;
      }
    },
    setSelection(state, payload) {
      state.selection = { ...DEFAULT_SELECTION, ...payload };
    },
    setChangesetHistory(state, payload) {
      state.changesetHistory = payload === null ? DEFAULT_CHANGESET_HISTORY : payload;
    },
    setSelectedChangeset(state, payload) {
      state.selectedChangeset = payload;
      if (payload !== null) {
        state.selectedTicket = null;
      }
    },
    setSpotInfo(state, payload) {
      state.spotInfo = payload;
    },
    setFuelStation(state, payload) {
      state.fuelStation = payload;
    },
    setPoi(state, payload) {
      state.poi = payload;
    },
    setFeatureChange(state, payload) {
      const featureChanges = JSON.parse(JSON.stringify(state.featureChanges));
      featureChanges[payload.id] = payload.change;
      state.featureChanges = featureChanges;
    },
    deleteFeatureChange(state, payload) {
      const featureChanges = JSON.parse(JSON.stringify(state.featureChanges));
      delete featureChanges[payload];
      state.featureChanges = featureChanges;
    },
    setPendingSpotData(state, payload) {
      state.pendingSpotData[payload.id] = payload.data;
    },
    updatePendingSpotDataId(state, payload) {
      state.pendingSpotData[payload.newId] = state.pendingSpotData[payload.oldId];
      delete state.pendingSpotData[payload.oldId];
    },
    removePendingSpotData(state, payload) {
      delete state.pendingSpotData[payload];
    },
    setTemporaryFeatureDraw(state, payload) {
      state.temporaryFeatureDraw = payload;
    },
    setAreasToMap(state, payload) {
      state.areasToMap = payload;
    },
    addAreaToMap(state, payload) {
      state.areasToMap = { ...state.areasToMap, features: state.areasToMap.features.concat(payload) };
    },
    deleteAreaToMap(state, payload) {
      state.areasToMap = { ...state.areasToMap, features: state.areasToMap.features.filter((f) => f.id !== payload.id) };
    },
    setSpotsToCheck(state, payload) {
      state.spotsToCheck = payload;
    },
    setStreetViewEnabled(state, payload) {
      state.streetViewEnabled = payload;
    },
    setSettingsEnabled(state, payload) {
      state.settingsEnabled = payload;
    },
    setStreetViewPosition(state, payload) {
      state.streetViewCamera = {
        ...state.streetViewCamera,
        position: payload,
      };
    },
    setStreetViewHeading(state, payload) {
      state.streetViewCamera = {
        ...state.streetViewCamera,
        heading: payload,
      };
    },
    setStreetViewPositionClick(state, payload) {
      state.streetViewPositionClick = payload;
    },
    addDeletedSpotId(state, payload) {
      state.deletedSpotIds.push(payload);
    },
    addDeletedFuelStationId(state, payload) {
      state.deletedFuelStationIds.push(payload);
    },
    setOnMapClickListener(state, payload) {
      state.onMapClickListener = payload;
    },
    setSearchedPlace(state, payload) {
      state.searchedPlace = { ...DEFAULT_SEARCHED_PLACE, ...payload };
    },
  },
  actions: {
    loadingApp(context, payload) {
      context.commit('setLoadingApp', payload);
    },
    loadingError(context, payload) {
      context.commit('setLoadingError', payload);
    },
    apiReference(context, payload) {
      context.commit('setApiReference', payload);
    },
    selectedTicket(context, payload) {
      context.commit('setSelectedTicket', payload);
    },
    selection(context, payload) {
      context.commit('setSelection', payload);
    },
    changesetHistory(context, payload) {
      context.commit('setChangesetHistory', payload);
    },
    selectedChangeset(context, payload) {
      context.commit('setSelectedChangeset', payload);
    },
    spotInfo(context, payload) {
      context.commit('setSpotInfo', payload);
    },
    fuelStation(context, payload) {
      context.commit('setFuelStation', payload);
    },
    poi(context, payload) {
      context.commit('setPoi', payload);
    },
    addDrawnFeature(context, payload) {
      if (payload.id in context.state.featureChanges) throw new Error(`tried to add new feature with id ${payload.id} already found in changes`);
      context.commit('setFeatureChange', {
        id: payload.id,
        change: {
          originalFeature: null,
          feature: payload,
        },
      });
    },
    addFeatureChange(context, payload) {
      if (payload.id in context.state.featureChanges) throw new Error(`tried to add edited feature with id ${payload.id} already found in changes`);
      context.commit('setFeatureChange', {
        id: payload.id,
        change: {
          originalFeature: payload,
          feature: payload,
        },
      });
    },
    updateFeatureChange(context, payload) {
      if (!(payload.id in context.state.featureChanges)) throw new Error(`tried to update edited feature with id ${payload.id} not found in changes`);
      context.commit('setFeatureChange', {
        id: payload.id,
        change: {
          ...context.state.featureChanges[payload.id],
          feature: payload,
        },
      });
    },
    featureSaved(context, payload) {
      const oldId = Number.isInteger(payload) ? payload : payload.oldId;
      if (!(oldId in context.state.featureChanges)) return;

      context.commit('deleteFeatureChange', oldId);

      if (!Number.isInteger(payload)) {
        context.commit('updatePendingSpotDataId', payload);
      }
    },
    setPendingSpotData(context, payload) {
      context.commit('setPendingSpotData', payload);
    },
    removePendingSpotData(context, payload) {
      context.commit('removePendingSpotData', payload);
    },
    temporaryFeatureDraw(context, payload) {
      context.commit('setTemporaryFeatureDraw', payload);
    },
    areasToMap(context, payload) {
      context.commit('setAreasToMap', payload);
    },
    addAreaToMap(context, payload) {
      context.commit('addAreaToMap', payload);
    },
    setSpotsToCheck(context, payload) {
      context.commit('setSpotsToCheck', payload);
    },
    deleteAreaToMap(context, payload) {
      context.commit('deleteAreaToMap', payload);
    },
    streetViewEnabled(context, payload) {
      context.commit('setStreetViewEnabled', payload);
    },
    settingsEnabled(context, payload) {
      context.commit('setSettingsEnabled', payload);
    },
    streetViewPosition(context, payload) {
      context.commit('setStreetViewPosition', payload);
    },
    streetViewPositionClick(context, payload) {
      context.commit('setStreetViewPositionClick', payload);
    },
    streetViewHeading(context, payload) {
      context.commit('setStreetViewHeading', payload);
    },
    deletedSpot(context, payload) {
      context.commit('addDeletedSpotId', payload);
    },
    deletedFuelStation(context, payload) {
      context.commit('addDeletedFuelStationId', payload);
    },
    onMapClickListener(context, payload) {
      context.commit('setOnMapClickListener', payload);
    },
    searchedPlace(context, payload) {
      context.commit('setSearchedPlace', payload);
    },
  },
  getters: {
    ...simpleGetters([
      'baseMap',
      'mapFeatures',
      'imagerySource',
      'loadingApp',
      'loadingError',
      'selectedTicket',
      'selection',
      'changesetHistory',
      'selectedChangeset',
      'spotInfo',
      'fuelStation',
      'poi',
      'featureChanges',
      'pendingSpotData',
      'temporaryFeatureDraw',
      'areasToMap',
      'spotsToCheck',
      'streetViewEnabled',
      'settingsEnabled',
      'streetViewCamera',
      'streetViewPositionClick',
      'deletedSpotIds',
      'deletedFuelStationIds',
      'onMapClickListener',
      'searchedPlace',
      'apiReference',
    ]),
  },
};
