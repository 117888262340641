import store from '@/store/index';

const SettingsControl = {
  onAdd() {
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.controlContainer.classList.add('mapboxgl-ctrl');

    this.button = document.createElement('button');
    this.button.title = 'Toggle Settings';
    this.button.classList.add('mapboxgl-ctrl-icon');
    this.button.classList.add('disabled');
    this.button.classList.add('mapboxgl-custom-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon-settings');
    this.controlContainer.appendChild(this.button);
    this.button.addEventListener('click', () => this.toggleSettings());

    return this.controlContainer;
  },
  onRemove() {
    this.controlContainer.parentNode.removeChild(this.controlContainer);
  },
  toggleSettings() {
    if (this.button.classList.contains('disabled')) {
      store.dispatch('editor/settingsEnabled', true);
      this.button.classList.remove('disabled');
    } else {
      store.dispatch('editor/settingsEnabled', false);
      this.button.classList.add('disabled');
    }
  },
};

export default SettingsControl;
