import { length } from '@turf/turf';

export default class RulerControl {
  constructor(mapboxHelper, onMeasure) {
    this.mapboxHelper = mapboxHelper;
    this.onMeasure = onMeasure;
  }

  onAdd() {
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.controlContainer.classList.add('mapboxgl-ctrl');

    this.button = document.createElement('button');
    this.button.title = 'Compute distance';
    this.button.classList.add('mapboxgl-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon-ruler');
    this.controlContainer.appendChild(this.button);
    this.button.addEventListener('click', () => this.drawRuler());

    return this.controlContainer;
  }

  onRemove() {
    this.controlContainer.parentNode.removeChild(this.controlContainer);
  }

  drawRuler() {
    this.mapboxHelper.drawTemporaryFeature('LineString', (e) => this.onDraw(e));
  }

  onDraw = (e) => {
    if (e.type === 'draw.create') {
      const l = length(e.features[0].geometry, { units: 'meters' });
      this.onMeasure(l);
      setTimeout(
        () => {
          this.mapboxHelper.stopDrawingTemporaryFeature();
        },
        0,
      );
    }
  };
}
