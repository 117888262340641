<template>
  <div id="container">
    <Header>
      <template slot="header-content">
        <slot name="header-content"/>
      </template>
    </Header>
    <div id="body">
      <slot name="body"/>
      <AlertDialog/>
    </div>
  </div>
</template>

<script>

import Header from '@/components/Header';

export default {
  components: {
    Header,
  },
};

</script>

<style scoped>

html {
  height: 100%;
}

body {
  background-color: #F5F7F8;
  height: 100%;
}

#app {
  height: 100%;
}

#container {
  height: 100%;
}

#body {
  display: flex;
  margin-top: 73px;
  height: calc(100% - 73px);
  position: relative;
}

</style>
