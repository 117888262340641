<template>
  <button
    type="button"
    class="material-button-flat"
    :disabled="disabled"
    :style="style"
    v-on:click="$emit('click', $event)"
  >
    <img v-if="src" :src="src" :alt="label" :class="{ standalone: label === null }"/>
    {{ label }}
  </button>
</template>

<script>

export default {
  name: 'MaterialButton',
  computed: {
    style() {
      const result = {};
      if (!this.disabled) {
        result.color = this.color;
      }
      if (this.uppercase) {
        result.textTransform = 'uppercase';
      }
      return result;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#1C9BE6',
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
  },
};

</script>

<style scoped>

/*Downloaded from https://www.codeseek.co/finnhvman/pure-css-material-flat-button-YeXwNY */
.material-button-flat {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  flex-shrink: 0;
}

.material-button-flat:disabled {
  color: rgba(0, 0, 0, 0.38);
  background-color: transparent;
  cursor: initial;
}

.material-button-flat::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.2s;
}

.material-button-flat:hover::before {
  opacity: 0.04;
}

.material-button-flat:focus::before {
  opacity: 0.12;
}

.material-button-flat:active::before {
  opacity: 0.2;
}

.material-button-flat:disabled::before {
  opacity: 0;
}

.material-button-flat::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 18px;
  border-radius: 50%;
  padding: 50%;
  width: 32px;
  height: 32px;
  background-color: currentColor;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

.material-button-flat:active::after {
  opacity: 0.4;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

.material-button-flat:disabled::after {
  opacity: 0;
}

.material-button-flat img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.material-button-flat:disabled img {
  filter: grayscale(100%);
}

.material-button-flat img.standalone {
  width: 24px;
  height: 24px
}

</style>
