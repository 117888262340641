<template>
  <div id="ticket-sheet">
    <SheetTitle :title="selectedTicket.title" back-button="close" v-on:back-clicked="close()"/>
    <template v-if="category === this.UPDATE_TYPES.PICTURE">
      <div id="ticket-picture" :style="{ backgroundImage: 'url('+ selectedTicket.picture_url + ')' }" v-on:mousemove="this.onPictureMouseMove" v-on:mouseout="this.onPictureMouseOut"/>
      <div class="ticket-actions">
        <div v-if="selectedTicket.user_lat > 0" class="ticket-user-location">
          <span>User GPS:&nbsp;</span><span>{{ selectedTicket.user_lat.toFixed(7) }}, {{ selectedTicket.user_lng.toFixed(7) }}</span>
        </div>
        <div class="ticket-actions-line-break"/>
        <div class="ticket-comments">
          Comments:
          <div v-if="selectedTicket.picture_comments">
            <div v-for="(comment, i) in selectedTicket.picture_comments" :key="i" class="ticket-comment">
              {{ comment }}
            </div>
          </div>
          <p v-else>None</p>
        </div>
        <div class="ticket-actions-line-break"/>
        <MaterialButton class="ticket-action" :src="require('@/assets/images/check-blue-16dp.svg')" label="Approve" v-on:click="approvePicture" :disabled="isProcessingTicket"/>
        <MaterialButton class="ticket-action" :src="require('@/assets/images/close-blue-16dp.svg')" label="Reject" v-on:click="showRejectPictureModal" :disabled="isProcessingTicket"/>
        <div class="ticket-actions-line-break"/>
        <input type="number" id="ticket-picture-segment-id" placeholder="Segment id to re-assign to"/>
        <MaterialButton :src="require('@/assets/images/rotate-blue-16dp.svg')" label="Re-assign" v-on:click="reassignPicture" id="ticket-picture-reassign" :disabled="isProcessingTicket"/>
      </div>
    </template>
    <template v-else-if="category === this.UPDATE_TYPES.SPOT_EDITED">
      <template v-if="prevSpotInfo !== null && spotInfo !== null">
        <div v-for="regulation in activeRegulations" :key="regulation">
          <SheetTitle :title="regulation" :key="regulation" :title-class="regulationTitleClass(regulation)"/>
          <Calendar :initial-regulation="initialRegulation(regulation)" :updated-regulation="updatedRegulation(regulation)" section="months"/>
          <Calendar v-if="regulation.prices" :initial-regulation="initialRegulation(regulation)" :updated-regulation="updatedRegulation(regulation)" section="prices"/>
        </div>
      </template>
      <template v-else>
        <SheetCardRow>Loading...</SheetCardRow>
      </template>
      <div class="ticket-actions">
        <MaterialButton class="ticket-action" :src="require('@/assets/images/check-blue-16dp.svg')" label="Approve" v-on:click="approveChangeset" :disabled="isProcessingTicket"/>
        <MaterialButton class="ticket-action" :src="require('@/assets/images/close-blue-16dp.svg')" label="Reject" v-on:click="showRejectChangesetModal" :disabled="isProcessingTicket"/>
      </div>
    </template>
    <template v-else-if="category === this.UPDATE_TYPES.MARKED_AS_INACCURATE">
      <div class="ticket-actions">
        <SheetCardRow>Check and update regulations if needed before marking as accurate</SheetCardRow>
        <MaterialButton class="ticket-action" :src="require('@/assets/images/check-blue-16dp.svg')" label="Mark as accurate" v-on:click="markAsAccurate" :disabled="isProcessingTicket"/>
      </div>
    </template>
    <template v-else-if="category === this.UPDATE_TYPES.MONTHLY_REQUEST">
      <div class="ticket-actions">
        <SheetCardRow>Check and update monthly info if needed before marking ticket as processed</SheetCardRow>
        <MaterialButton class="ticket-action" :src="require('@/assets/images/check-blue-16dp.svg')" label="Mark as processed" v-on:click="processMonthlyTicket" :disabled="isProcessingTicket"/>
      </div>
    </template>
    <template v-if="category === this.UPDATE_TYPES.GAS_PRICES_PICTURE">
      <div id="ticket-picture" :style="{ backgroundImage: 'url('+ selectedTicket.gas_prices_picture_url + ')' }" v-on:mousemove="this.onPictureMouseMove" v-on:mouseout="this.onPictureMouseOut"/>
      <div class="ticket-actions">
        <div v-if="selectedTicket.user_lat > 0" class="ticket-user-location">
          <span>User GPS:&nbsp;</span><span>{{ selectedTicket.user_lat.toFixed(7) }}, {{ selectedTicket.user_lng.toFixed(7) }}</span>
        </div>
        <p>Validate the picture by updating gas prices</p>
        <div class="ticket-actions-line-break"/>
        <MaterialButton class="ticket-action" :src="require('@/assets/images/close-blue-16dp.svg')" label="Reject" v-on:click="showRejectGasPricesPictureModal" :disabled="isProcessingTicket"/>
      </div>
    </template>

    <Modal ref="pictureRejectionModal" title="Reject picture" :positive-action="pictureRejectionButtonText" v-on:positive-action="rejectPicture" :actions-disabled="selectedStatus === null || isProcessingTicket">
      <template v-slot:body>
        <div id="form-picture-rejection-item-reasons">
          <label for="status-gps"><input type="radio" id="status-gps" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.GPS_ISSUE">GPS issue</label>
          <label for="status-unhandled"><input type="radio" id="status-unhandled" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.NOT_HANDLED">Not handled</label>
          <label for="status-private"><input type="radio" id="status-private" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.PRIVATE">Private garage / driveway</label>
          <label for="status-misunderstanding"><input type="radio" id="status-misunderstanding" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.MISUNDERSTANDING">Misunderstanding</label>
          <label for="status-unusable"><input type="radio" id="status-unusable" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.UNUSABLE">Unusable</label>
          <label for="status-trash"><input type="radio" id="status-trash" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.TRASH">Trash</label>
        </div>
        <textarea class="rejection-item-comments" ref="pictureRejectionComments" placeholder="Comments (optional)" :disabled="selectedStatus === PICTURE_STATUSES.TRASH || isProcessingTicket"></textarea>
      </template>
    </Modal>
    <Modal ref="gasPricesPictureRejectionModal" title="Reject gas prices picture" :positive-action="pictureRejectionButtonText" v-on:positive-action="rejectGasPricesPicture" :actions-disabled="selectedStatus === null || isProcessingTicket">
      <template v-slot:body>
        <div id="form-picture-rejection-item-reasons">
          <label for="status-misunderstanding"><input type="radio" id="status-misunderstanding" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.MISUNDERSTANDING">Misunderstanding</label>
          <label for="status-unusable"><input type="radio" id="status-unusable" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.UNUSABLE">Unusable</label>
          <label for="status-trash"><input type="radio" id="status-trash" name="status" v-model.number="selectedStatus" :value="PICTURE_STATUSES.TRASH">Trash</label>
        </div>
        <textarea class="rejection-item-comments" ref="pictureRejectionComments" placeholder="Comments (optional)" :disabled="selectedStatus === PICTURE_STATUSES.TRASH || isProcessingTicket"></textarea>
      </template>
    </Modal>
    <Modal ref="changesetRejectionModal" title="Reject changeset" positive-action="Reject and send email" v-on:positive-action="rejectChangeset" :actions-disabled="isProcessingTicket" :positive-action-disabled="changesetRejectionComments == null || !changesetRejectionComments.replace(/\s/g, '')">
      <template v-slot:body>
        <textarea class="rejection-item-comments" id="form-changeset-rejection-item-reasons" v-model="changesetRejectionComments" placeholder="Comments (optional)" :disabled="isProcessingTicket"></textarea>
      </template>
    </Modal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { eventBus, events } from '@/constants/eventBus';
import ApiHelper from '@/utils/apiHelper';
import Toast from '@/utils/toast';
import Modal from '@/components/Modal';
import MaterialButton from '@/components/MaterialButton';
import RegulationsHelper from '@/utils/regulationsHelper';
import Calendar from '@/components/editing/Calendar';
import SheetTitle from '@/components/SheetTitle';
import MapDataHelper from '@/utils/mapDataHelper';
import SheetCardRow from '@/components/SheetCardRow';
import AuthHelper from '@/utils/authHelper';

export default {
  name: 'TicketSheet',
  components: {
    SheetCardRow,
    Calendar,
    MaterialButton,
    SheetTitle,
    Modal,
  },
  data() {
    return {
      UPDATE_TYPES: ApiHelper.TICKET_TYPES,
      PICTURE_STATUSES: ApiHelper.PICTURE_STATUSES,
      selectedStatus: null,
      isProcessingTicket: false,
      spotInfo: null,
      prevSpotInfo: null,
      changesetRejectionComments: null,
    };
  },
  created() {
    eventBus.$on(events.spotMarkedAsAccurate, this.onSpotMarkedAsAccurateEvent);
  },
  computed: {
    ...mapGetters('editor', ['selectedTicket']),
    activeRegulations() {
      return [...new Set(this.spotInfo.calendar.map((item) => item.name).concat(this.prevSpotInfo.calendar.map((item) => item.name)))];
    },
    pictureRejectionButtonText() {
      return this.selectedStatus === ApiHelper.PICTURE_STATUSES.TRASH ? 'Trash picture' : 'Reject and send email';
    },
    category() {
      return this.selectedTicket.category === ApiHelper.TICKET_TYPES.TICKET_PROCESSED ? this.selectedTicket.parent_ticket.category : this.selectedTicket.category;
    },
  },
  watch: {
    selectedTicket(selectedTicket) {
      this.prevSpotInfo = null;
      if (selectedTicket !== null && (selectedTicket.category === ApiHelper.TICKET_TYPES.SPOT_EDITED || selectedTicket.category === ApiHelper.TICKET_TYPES.TICKET_PROCESSED)) {
        let changesetId = null;
        let prevChangesetId = null;
        if (selectedTicket.category === ApiHelper.TICKET_TYPES.TICKET_PROCESSED && !selectedTicket.parent_ticket.change_validated) {
          changesetId = selectedTicket.changeset_id;
        } else {
          prevChangesetId = selectedTicket.prev_changeset_id || -1;
        }

        RegulationsHelper.getSpotInfo(this.selectedTicket.segment_id, changesetId, this.onGotSpotInfo, this.onSpotInfoError);
        RegulationsHelper.getSpotInfo(this.selectedTicket.segment_id, prevChangesetId, this.onGotPrevSpotInfo, this.onPrevSpotInfoError);
      }
    },
  },
  methods: {
    onGotSpotInfo(request, result) {
      this.spotInfo = result;
    },
    onSpotInfoError() {
      // TODO
    },
    onGotPrevSpotInfo(request, result) {
      this.prevSpotInfo = result;
    },
    onPrevSpotInfoError() {
      // TODO
    },
    regulationTitleClass(regulation) {
      const isInInitial = this.initialRegulation(regulation) !== null;
      const isInUpdated = this.updatedRegulation(regulation) !== null;
      return {
        'item-added': !isInInitial && isInUpdated,
        'item-removed': isInInitial && !isInUpdated,
      };
    },
    initialRegulation(regulation) {
      return this.prevSpotInfo.calendar.find((item) => item.name === regulation) || null;
    },
    updatedRegulation(regulation) {
      return this.spotInfo.calendar.find((item) => item.name === regulation) || null;
    },
    onPictureMouseMove(e) {
      let offsetX;
      let offsetY;
      if (e.offsetY) {
        offsetY = e.offsetY;
        offsetX = e.offsetX ? e.offsetX : 0;
      } else {
        offsetY = 0;
        offsetX = 0;
      }

      const picture = e.currentTarget;
      const x = (offsetX / picture.offsetWidth) * 100;
      const y = (offsetY / picture.offsetHeight) * 100;
      picture.style.backgroundPosition = `${x}% ${y}%`;
    },
    onPictureMouseOut(e) {
      e.currentTarget.style.backgroundPosition = 'center center';
    },
    approvePicture() {
      if (this.selectedTicket.category !== ApiHelper.TICKET_TYPES.PICTURE) throw new Error('Tried to approve picture for non-picture pending update');

      this.isProcessingTicket = true;
      const ticketId = this.selectedTicket.id;
      ApiHelper.updatePictureStatus(this.selectedTicket.picture_id, ApiHelper.PICTURE_STATUSES.GOLD_DATA, null, null, () => this.onPictureApproved(ticketId), this.onApprovePictureError);
    },
    onPictureApproved(ticketId) {
      MapDataHelper.processTicket(
        this.selectedTicket.id,
        true,
        null,
        () => { this.onApprovePictureTicketProcessed(ticketId); },
        this.onApprovePictureError,
      );
    },
    onApprovePictureError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error approving picture: ${result.body.error_message}`);
    },
    onApprovePictureTicketProcessed(ticketId) {
      eventBus.$emit(events.ticketProcessed, ticketId);
      this.isProcessingTicket = false;
      Toast.info('Picture successfully approved!');
    },
    showRejectPictureModal() {
      this.$refs.pictureRejectionModal.openModal();
    },
    rejectPicture() {
      if (this.selectedStatus === null || Object.values(ApiHelper.PICTURE_STATUSES).indexOf(this.selectedStatus) === -1) throw new Error(`invalid picture status ${this.selectedStatus}`);

      this.isProcessingTicket = true;
      const comments = this.selectedStatus !== ApiHelper.PICTURE_STATUSES.TRASH ? this.$refs.pictureRejectionComments.value : null;
      const ticketId = this.selectedTicket.id;
      ApiHelper.updatePictureStatus(this.selectedTicket.picture_id, this.selectedStatus, comments, null, () => this.onPictureRejected(ticketId), this.onRejectPictureError);
    },
    onPictureRejected(ticketId) {
      MapDataHelper.processTicket(
        this.selectedTicket.id,
        false,
        null,
        () => { this.onRejectPictureTicketProcessed(ticketId); },
        this.onRejectPictureError,
      );
    },
    onRejectPictureError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error rejecting picture: ${result.body.error_message}`);
    },
    onRejectPictureTicketProcessed(ticketId) {
      eventBus.$emit(events.ticketProcessed, ticketId);
      this.$refs.pictureRejectionModal.closeModal();
      this.selectedStatus = null;
      this.isProcessingTicket = false;
      Toast.info('Picture successfully rejected');
    },
    reassignPicture() {
      if (this.selectedTicket.category !== ApiHelper.TICKET_TYPES.PICTURE) throw new Error('Tried to re-assign picture for non-picture pending update');

      const segmentId = document.getElementById('ticket-picture-segment-id').value;
      if (segmentId === '' || Number.isNaN(segmentId)) {
        Toast.danger('Please input a valid segment id');
        return;
      }

      this.isProcessingTicket = true;
      const ticketId = this.selectedTicket.id;
      ApiHelper.updatePictureStatus(this.selectedTicket.picture_id, ApiHelper.PICTURE_STATUSES.GOLD_DATA, null, segmentId, () => this.onPictureReassigned(ticketId), this.onReassignPictureError);
    },
    onPictureReassigned(ticketId) {
      MapDataHelper.processTicket(
        this.selectedTicket.id,
        true,
        null,
        () => { this.onReassignPictureTicketProcessed(ticketId); },
        this.onReassignPictureError,
      );
    },
    onReassignPictureError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error re-assigning picture: ${result.body.error_message}`);
    },
    onReassignPictureTicketProcessed(ticketId) {
      eventBus.$emit(events.ticketProcessed, ticketId);
      this.isProcessingTicket = false;
      Toast.info('Picture successfully re-assigned');
    },
    approveChangeset() {
      this.isProcessingTicket = true;
      const ticketId = this.selectedTicket.id;
      MapDataHelper.processTicket(ticketId, true, null, () => this.onChangesetApproved(ticketId), this.onApproveChangesetError);
    },
    onChangesetApproved(ticketId) {
      if (this.spotInfo) {
        this.$store.dispatch('editor/spotInfo', this.spotInfo);
      }

      this.spotInfo.crowdsource.needs_verification = false;
      this.spotInfo.crowdsource.accuracy_status = `Marked as accurate just now by ${AuthHelper.getUser().username}`;
      eventBus.$emit(events.ticketProcessed, ticketId);
      this.isProcessingTicket = false;
      Toast.info('Changeset successfully marked as valid');
    },
    onApproveChangesetError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error accepting changeset: ${result.body.error_message}`);
    },
    showRejectChangesetModal() {
      this.changesetRejectionComments = this.selectedTicket.category === ApiHelper.TICKET_TYPES.TICKET_PROCESSED ? this.selectedTicket.parent_ticket.comments : null;
      this.$refs.changesetRejectionModal.openModal();
    },
    rejectChangeset() {
      this.isProcessingTicket = true;
      const ticketId = this.selectedTicket.id;
      MapDataHelper.processTicket(ticketId, false, this.changesetRejectionComments, () => this.onChangesetRejected(ticketId), this.onRejectChangesetError);
    },
    onChangesetRejected(ticketId) {
      if (this.prevSpotInfo) {
        this.$store.dispatch('editor/spotInfo', { ...this.spotInfo, calendar: this.prevSpotInfo.calendar });
      }

      eventBus.$emit(events.ticketProcessed, ticketId);
      this.$refs.changesetRejectionModal.closeModal();
      this.isProcessingTicket = false;
      Toast.info('Changeset successfully rejected, please correct the spot data');
    },
    onRejectChangesetError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error rejecting changeset: ${result.body.error_message}`);
    },
    markAsAccurate() {
      this.isProcessingTicket = true;
      const ticketId = this.selectedTicket.id;
      MapDataHelper.processTicket(ticketId, true, null, () => this.onMarkedAsAccurate(ticketId), this.onMarkAsAccurateError);
    },
    onMarkedAsAccurate(ticketId) {
      if (this.spotInfo) {
        this.spotInfo.crowdsource.needs_verification = false;
        this.spotInfo.crowdsource.accuracy_status = `Marked as accurate just now by ${AuthHelper.getUser().username}`;
      }

      eventBus.$emit(events.ticketProcessed, ticketId);
      this.isProcessingTicket = false;
      Toast.info('Spot successfully marked as accurate');
    },
    onMarkAsAccurateError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error marking spot as accurate: ${result.body.error_message}`);
    },
    onSpotMarkedAsAccurateEvent() {
      if (this.selectedTicket.category === this.UPDATE_TYPES.MARKED_AS_INACCURATE) {
        eventBus.$emit(events.ticketProcessed, this.selectedTicket.id);
      }
    },
    processMonthlyTicket() {
      this.isProcessingTicket = true;
      const ticketId = this.selectedTicket.id;
      MapDataHelper.processTicket(ticketId, true, null, () => this.onMonthlyTicketProcessed(ticketId), this.onProcessMonthlyTicketError);
    },
    onMonthlyTicketProcessed(ticketId) {
      eventBus.$emit(events.ticketProcessed, ticketId);
      this.isProcessingTicket = false;
      Toast.info('Ticket successfully marked as processed');
    },
    onProcessMonthlyTicketError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error marking ticket as processed: ${result.body.error_message}`);
    },
    showRejectGasPricesPictureModal() {
      this.$refs.gasPricesPictureRejectionModal.openModal();
    },
    rejectGasPricesPicture() {
      if (this.selectedStatus === null || Object.values(ApiHelper.PICTURE_STATUSES).indexOf(this.selectedStatus) === -1) throw new Error(`invalid picture status ${this.selectedStatus}`);

      this.isProcessingTicket = true;
      const comments = this.selectedStatus !== ApiHelper.PICTURE_STATUSES.TRASH ? this.$refs.pictureRejectionComments.value : null;
      const ticketId = this.selectedTicket.id;
      MapDataHelper.processTicket(ticketId, false, comments, () => this.onGasPricesPictureRejected(ticketId), this.onRejectGasPricesPictureError);
    },
    onGasPricesPictureRejected(ticketId) {
      MapDataHelper.processTicket(this.selectedTicket.id, false, null, () => {}, () => {});
      eventBus.$emit(events.ticketProcessed, ticketId);
      this.$refs.pictureRejectionModal.closeModal();
      this.selectedStatus = null;
      this.isProcessingTicket = false;
      Toast.info('Gas prices picture successfully rejected');
    },
    onRejectGasPricesPictureError(request, result) {
      this.isProcessingTicket = false;
      Toast.danger(`Error rejecting gas prices picture: ${result.body.error_message}`);
    },
    close() {
      this.$store.dispatch('editor/selectedTicket', null);
    },
  },
};
</script>

<style scoped>

#ticket-sheet {
  width: 375px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.ticket-user-location {
  padding: 8px 0;
  font-size: 14px;
  line-height: 17px;
  color: #8293A3;
}

.ticket-user-location span {
  margin-left: 4px;
  float: left;
}

.ticket-comments {
  width: 100%;
  padding: 0 16px;
}

.ticket-comment {
  white-space: pre;
  margin: 4px 0;
}

.ticket-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.ticket-action {
  flex-grow: 1;
}

.ticket-actions-line-break {
  width: 100%;
}

#ticket-actions button {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0;
}

#ticket-picture-segment-id {
  flex-basis: 0;
  flex-grow: 2;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background: white;
  height: 32px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: none;
  margin: 8px;
  padding: 0 8px;
}

#ticket-picture-segment-id:focus {
  border: 1px solid #1C9BE6;
}

#ticket-picture {
  height: 500px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

#ticket-picture:hover {
  background-size: unset;
}

#form-picture-rejection-item-reasons {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

#form-picture-rejection-item-reasons label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
}

#form-picture-rejection-item-reasons label input {
  margin: 0 8px 0 0;
}

.rejection-item-comments {
  width: calc(100% - 32px);
  height: 80px;
  resize: vertical;
  padding: 8px;
  margin: 8px;
  background: white;
  border: 1px solid #212529;
  border-radius: 6px;
}

.rejection-item-comments:disabled {
  border: 1px solid #8293A3;
}

#form-changeset-rejection-item-reasons {
  height: 120px;
}

/*noinspection CssUnusedSymbol*/
.item-removed {
  color: #ff4541;
  text-decoration: line-through;
}

/*noinspection CssUnusedSymbol*/
.item-added {
  color: #1dcf32;
}

</style>
