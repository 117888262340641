const SatelliteLayerControl = {
  map: null,
  onAdd(map) {
    this.map = map;

    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.controlContainer.classList.add('mapboxgl-ctrl');

    this.button = document.createElement('button');
    this.button.title = 'Toggle satellite layer';
    this.button.classList.add('mapboxgl-ctrl-icon');
    this.button.classList.add('disabled');
    this.button.classList.add('mapboxgl-custom-ctrl-icon');
    this.button.classList.add('mapboxgl-custom-ctrl-icon-layers');
    this.button.addEventListener('click', () => this.toggleSatelliteLayer());
    this.controlContainer.appendChild(this.button);

    return this.controlContainer;
  },
  onRemove() {
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    this.map = null;
  },
  toggleSatelliteLayer() {
    if (this.button.classList.contains('disabled')) {
      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
      this.button.classList.remove('disabled');
    } else {
      this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
      this.button.classList.add('disabled');
    }
  },
};

export default SatelliteLayerControl;
