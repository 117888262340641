import Vue from 'vue';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import * as Sentry from '@sentry/browser';

import Editor from '@/views/Editor';
import Account from '@/views/Account';
import Login from '@/views/Login';
import AuthHelper from '@/utils/authHelper';
import ApiHelper from '@/utils/apiHelper';
import store from '@/store/index';

Vue.use(VueRouter);
Vue.use(PortalVue);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    logErrors: true,
    tracingOptions: {
      trackComponents: true,
    },
    tracesSampleRate: 1.0,
  });
}

const routes = [
  {
    path: '/health',
    name: 'HealthCheck',
    component: {
      template: '',
    },
  },
  {
    path: '/login*',
    name: 'SpotAngels Login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'Editor',
    component: Editor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.name === null && to.matched.some((record) => record.meta.requiresAuth)) {
    const loginToken = AuthHelper.getLoginToken();
    if (loginToken === null) {
      next({
        path: '/login',
        query: { r: to.fullPath },
      });
    } else {
      store.dispatch('editor/loadingApp', true);
      const reference = await ApiHelper.requestAsync('get', '/api/v3/reference/editor');
      store.dispatch('editor/apiReference', reference);
      ApiHelper.getUser(
        (request, result) => {
          AuthHelper.handleUser(result.user);
          store.dispatch('editor/loadingError', false);
          store.dispatch('editor/loadingApp', false);
        },
        (request, result) => {
          if (result.status === 401 || result.status === 403) {
            AuthHelper.onLogout();
            next({
              path: '/login',
              query: { r: to.fullPath },
            });
          } else {
            store.dispatch('editor/loadingError', true);
            store.dispatch('editor/loadingApp', false);
          }
        },
      );
      next();
    }
  } else {
    next();
  }
});

export default router;
