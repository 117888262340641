<template>
  <header>
    <div class="menu">
      <a href="/">
        <img :src="require('@/assets/images/spotangels-logo-editor.svg')" class="logo" alt="SpotAngels"/>
      </a>
      <slot name="header-content"/>
      <div class="user-info">
        <a href="/account">
          <Avatar/>
        </a>
        <p class="error-rate" v-if="user.error_rate !== null" :data-tooltip="errorRateTooltip">Error rate: {{ user.error_rate }}%
          <img class="tooltip-img" :src="require('@/assets/images/info-grey-18dp.svg')" :alt="errorRateTooltip"/>
        </p>
      </div>
      <MaterialButton id="btn-logout" label="Log out" v-on:click="showLogoutModal"/>
    </div>
  </header>
</template>

<script>

import * as Sentry from '@sentry/browser';
import MaterialButton from '@/components/MaterialButton';
import Avatar from '@/components/Avatar';
import ApiHelper from '@/utils/apiHelper';
import AuthHelper from '@/utils/authHelper';

export default {
  name: 'Header',
  components: {
    Avatar,
    MaterialButton,
  },
  props: {
    noSearch: Boolean,
  },
  data() {
    return {
      geocoder: null,
    };
  },
  computed: {
    user() {
      return AuthHelper.getUser();
    },
    errorRateTooltip() {
      if (this.user.error_rate_computed_at === null) return null;

      return `Computed ${(new Date(this.user.error_rate_computed_at)).toLocaleString('en-US', {
        month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
      })}`;
    },
  },
  methods: {
    showLogoutModal() {
      this.$alertDialog.show({
        title: 'Log out',
        message: 'Log out from the SpotAngels Editor?',
        positiveAction: {
          text: 'Log out',
          onClick: this.doLogout,
        },
        negativeAction: { text: 'Cancel' },
      });
    },
    doLogout() {
      ApiHelper.postLogout(this.onLoggedOut, this.onLogoutError);
    },
    onLoggedOut() {
      AuthHelper.onLogout();
      this.$router.go(0);
    },
    onLogoutError(request, result) {
      Sentry.captureException(new Error(`log out error: ${result.error}`));
      AuthHelper.onLogout();
      this.$router.go(0);
    },
  },
};

</script>

<style scoped>

header {
  display: flex;
  flex-direction: column;
  background: white;
  position: fixed;
  z-index: 2;
  width: 100%;
  border-bottom: 1px solid #e0e6ea;
  padding: 0 50px;
  top: 0;
}

.menu {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0 10px;
}

.logo {
  width: auto;
  height: 100%;
}

.user-info {
  text-decoration: none;
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
}

.user-info p {
  margin: 0;
  font-size: 14px;
  color: #8293a3;
}

.user-info a {
  text-decoration: none;
}

#btn-logout {
}

</style>
