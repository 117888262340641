<template>
  <div id="welcome-sheet">
    <SheetTitle title="Welcome to the SpotAngels Editor"/>
    <SheetCard>
      <SheetCardRow>
        Start editing by selecting a spot or drawing a new segment
      </SheetCardRow>
    </SheetCard>
    <SheetCard v-if="areasToMap.features.length > 0 || isAdmin">
      <SheetTitle
        title="Areas to map"
        :src="require(areasToMapVisible ? '@/assets/images/visibility_black_24dp.svg' : '@/assets/images/visibility_off_black_24dp.svg')"
        v-on:action-clicked="toggleAreasToMapVisibility"
      />
      <div
        v-for="area in areasToMap.features"
        :key="area.id"
        class="area-to-map"
        v-on:click="goToArea(area)"
      >
        <img :src="require('@/assets/images/location_on-black-18dp.svg')" alt="Location icon"/>
        <div class="area-to-map-text-wrapper">
          <span class="area-to-map-text">{{ area.properties.city_name }}, {{ area.properties.state }}</span>
        </div>
        <ImageButton
          v-if="isAdmin"
          :src="require('@/assets/images/delete-red-24dp.svg')"
          v-on:click="deleteArea(area)"
        />
      </div>
      <SheetCardAction
        v-if="isAdmin && temporaryFeatureDraw === null"
        :icon="require('@/assets/images/add-blue-24dp.svg')"
        action="Add area to map"
        v-on:click="drawArea"
      />
      <SheetCardRow v-else-if="isAdmin">Draw the area on the map or press Esc to cancel</SheetCardRow>
    </SheetCard>
    <SheetCard>
      <SheetTitle
        title="Spots to check"
        :src="require('@/assets/images/refresh-black-24dp.svg')"
        v-on:action-clicked="getSpotsToCheck"
      />
      <SheetCardRow v-if="Object.keys(spotsToCheck).length === 0">
        No spots to check found
      </SheetCardRow>
      <div
        v-for="spot in spotsToCheck"
        :key="spot.segment_id"
        class="spot-to-check"
        v-on:click="goToSpot(spot)"
      >
        <span class="spot-to-check-title">{{ spotToCheckTitle(spot) }}</span>
        <span class="spot-to-check-hint">{{ spotToCheckHint(spot) }}</span>
      </div>
    </SheetCard>
  </div>
</template>

<script>

import AuthHelper from '@/utils/authHelper';
import { eventBus, events } from '@/constants/eventBus';
import ImageButton from '@/components/ImageButton';
import MapDataHelper from '@/utils/mapDataHelper';
import { mapGetters } from 'vuex';
import mapSettings from '@/constants/mapSettings';
import SheetCard from '@/components/SheetCard';
import SheetCardAction from '@/components/SheetCardAction';
import SheetCardRow from '@/components/SheetCardRow';
import SheetTitle from '@/components/SheetTitle';
import Toast from '@/utils/toast';
import * as turf from '@turf/turf';

export default {
  name: 'FuelStationSheet',
  components: {
    ImageButton,
    SheetCard,
    SheetCardAction,
    SheetCardRow,
    SheetTitle,
  },
  data() {
    return {
      isAdmin: false,
      areasToMapVisible: true,
    };
  },
  computed: {
    ...mapGetters('editor', [
      'temporaryFeatureDraw',
      'areasToMap',
      'spotsToCheck',
    ]),
  },
  created() {
    this.isAdmin = AuthHelper.getUser().role.slug === 'admin';
    const storedAreasToMapVisible = window.localStorage.getItem('WelcomeSheet.areasToMapVisible');
    this.areasToMapVisible = storedAreasToMapVisible === null || storedAreasToMapVisible === 'true';
  },
  mounted() {
    eventBus.$emit(events.setSourceLayersVisible, mapSettings.sources.areasToMap.url, this.areasToMapVisible);
    this.getAreasToMap();
    this.getSpotsToCheck();
  },
  methods: {
    getAreasToMap() {
      MapDataHelper.getAreasToMap(this.onGotAreasToMap, this.onGetAreasToMapError);
    },
    onGotAreasToMap(request, result) {
      this.$store.dispatch('editor/areasToMap', result.geojson);
    },
    onGetAreasToMapError() {},
    toggleAreasToMapVisibility() {
      this.areasToMapVisible = !this.areasToMapVisible;
      eventBus.$emit(events.setSourceLayersVisible, mapSettings.sources.areasToMap.url, this.areasToMapVisible);
      window.localStorage.setItem('WelcomeSheet.areasToMapVisible', this.areasToMapVisible);
    },
    goToArea(area) {
      if (!this.areasToMapVisible) {
        this.toggleAreasToMapVisibility();
      }
      eventBus.$emit(events.jumpToRequested, turf.bbox(area), 14.0);
    },
    drawArea() {
      this.$store.dispatch('editor/temporaryFeatureDraw', {
        type: 'Polygon',
        onFeatureDrawn: this.onAreaDrawn,
      });
    },
    onAreaDrawn(feature) {
      MapDataHelper.saveAreaToMap(feature, this.onAreaSaved, this.onSaveAreaError);
    },
    onAreaSaved(request, result) {
      this.$store.dispatch('editor/temporaryFeatureDraw', null);
      this.$store.dispatch('editor/addAreaToMap', result);
      Toast.info('Area to map successfully saved');
    },
    onSaveAreaError() {
      this.$store.dispatch('editor/temporaryFeatureDraw', null);
      Toast.danger('Error saving drawn area, please try again');
    },
    deleteArea(area) {
      this.$alertDialog.show({
        title: 'Delete area to map',
        message: `Delete area to map in ${area.properties.city_name}, ${area.properties.state}?`,
        positiveAction: {
          text: 'Delete',
          color: '#FF4541',
          onClick: () => this.doDeleteArea(area),
        },
        negativeAction: { text: 'Cancel' },
      });
    },
    doDeleteArea(area) {
      MapDataHelper.deleteAreaToMap(area.id, () => this.onAreaDeleted(area), this.onDeleteAreaError);
    },
    onAreaDeleted(area) {
      this.$store.dispatch('editor/deleteAreaToMap', area);
      Toast.info('Area deleted');
    },
    onDeleteAreaError(request, response) {
      Toast.danger(`Error deleting area: ${response.body.error_message}`);
    },
    getSpotsToCheck() {
      MapDataHelper.getSpotsToCheck(this.onGotSpotsToCheck, this.onGetSpotToCheckError);
    },
    onGotSpotsToCheck(request, response) {
      this.$store.dispatch('editor/setSpotsToCheck', response.spots_to_check);
    },
    onGetSpotToCheckError(request, response) {
      Toast.danger(`Error getting spots to check: ${response.body.error_message}`);
    },
    spotToCheckTitle(spot) {
      let title = '';

      if (spot.garage_type) {
        title = spot.garage_type;
      }

      if (spot.garage_name) {
        if (title !== '') {
          title += ' - ';
        }
        title += spot.garage_name;
      }

      if (title === '') {
        title = 'Parking facility';
      }

      return title;
    },
    spotToCheckHint(spot) {
      return `${spot.city_name}, ${spot.state} - ${MapDataHelper.spotToCheckNeeds(spot)}`;
    },
    goToSpot(spot) {
      eventBus.$emit(events.jumpToRequested, { type: 'Regulation', id: spot.segment_id }, 17.0);
      window.location.hash = `#id=${spot.segment_id}`;
    },
  },
};
</script>

<style scoped>

#welcome-sheet {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 375px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #F5F7F8;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, .25);
}

.area-to-map {
  height: 54px;
  padding: 0 16px;
  border-bottom: 1px solid #CED6DE;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.area-to-map:hover {
  background-color: #E6F3FA;
}

.area-to-map img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.area-to-map-text-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  flex-grow: 1;
}

.spot-to-check {
  height: 54px;
  padding: 0 16px;
  border-bottom: 1px solid #CED6DE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.spot-to-check:hover {
  background-color: #E6F3FA;
}

.spot-to-check-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spot-to-check-hint {
  color: #8293A3;
}

</style>
