<template>
  <div id="login-container">
    <div id="login-card">
      <img id="logo-img" :src="require('@/assets/images/happy-angel.svg')" title="SpotAngels" alt="SpotAngels Logo">
      <h1>SpotAngels Map Editor</h1>
      <h2>Become a Map Maker and build your city parking map!</h2>
      <div id="login-form">
        <div v-show="!loading && !success && !loginToken" class="login-fields">
          <button type="button" class="google-button" ref="signInButton" :disabled="loading">
            <span class="google-button__icon">
              <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335"/><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05"/><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4"/><path
                d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853"/></svg>
              </span>
            <span class="google-button__text">Continue with Google</span>
          </button>
          <span class="login-form-hint">- OR -</span>
          <input id="login-email-address" type="email" placeholder="E-mail address" ref="emailInput" :disabled="loading" v-on:keyup.enter="requestMagicLink">
          <span class="span-error" v-if="error !== null">{{ error }}</span>
          <MaterialButton label="Send login link" :disabled="loading" v-on:click="requestMagicLink"/>
        </div>
        <div v-if="!loading && !success && !error && loginToken" class="login-fields">
          <span class="login-form-hint">You are about to log in to the<br>SpotAngels Editor</span>
          <MaterialButton label="Continue" v-on:click="loginWithMagicLink()"/>
        </div>
        <Spinner v-if="loading"/>
        <div v-if="success" id="success-div">
          <h1>Check your email!</h1>
          <span>To log in to the SpotAngels Editor, click the link in the email we sent to <span class="email">{{ email }}</span>.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as Sentry from '@sentry/browser';
import Vue from 'vue/dist/vue.esm';
import Toasted from 'vue-toasted';
import ApiHelper from '@/utils/apiHelper';
import AuthHelper from '@/utils/authHelper';
import MaterialButton from '@/components/MaterialButton';
import Spinner from '@/components/Spinner';
import Toast from '@/utils/toast';

export default {
  components: { MaterialButton, Spinner },
  data() {
    return {
      loading: false,
      success: false,
      email: null,
      error: null,
      loginToken: null,
    };
  },
  mounted() {
    Vue.use(Toasted, {
      position: 'bottom-center',
      duration: 2000,
      keepOnHover: true,
      className: 'sa-toast',
    });

    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({ client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID }).attachClickHandler(
        this.$refs.signInButton,
        {},
        this.onSignedInWithGoogle,
        this.onGoogleSignInError,
      );
    });

    this.loginToken = this.$route.query.token;
    if (this.loginToken && !navigator.userAgent.toLowerCase().includes('bingpreview') && !navigator.userAgent.toLowerCase().includes('slackbot')) {
      this.loginWithMagicLink();
    }
  },
  methods: {
    onSignedInWithGoogle(googleUser) {
      this.loading = true;
      ApiHelper.postGoogleLogin(googleUser, this.onLoggedIn, this.onGoogleLoginError);
    },
    onGoogleSignInError(result) {
      if (result.error !== 'popup_closed_by_user') {
        Toast.danger('Error logging in with Google, please try again');
        Sentry.captureException(new Error(`google sign in error: ${result.error}`));
      }
      this.loading = false;
    },
    requestMagicLink() {
      this.email = this.$refs.emailInput.value.trim();
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        this.error = 'Please enter a valid e-mail address';
        return;
      }

      this.loading = true;
      this.success = false;
      this.error = null;
      ApiHelper.postMagicLinkRequest(
        this.email,
        this.$route.query.r ?? '/',
        this.onMagicLinkRequested,
        this.onMagicLinkRequestError,
      );
    },
    onMagicLinkRequested() {
      this.success = true;
      this.loading = false;
    },
    onMagicLinkRequestError() {
      this.error = 'Error sending a magic link, please try again';
      this.loading = false;
    },
    loginWithMagicLink() {
      this.loading = true;
      ApiHelper.postMagicLinkLogin(this.loginToken, this.onLoggedIn, this.onMagicLinkLogInError);
    },
    onLoggedIn(request, result) {
      AuthHelper.onLogin(result);
      document.location = this.$route.query.r ?? '/';
    },
    onGoogleLoginError() {
      this.error = 'Error logging in with Google, please try again.';
      this.loading = false;
    },
    onMagicLinkLogInError() {
      this.error = 'Error logging in with login link, please request a new one.';
      this.loading = false;
      this.loginToken = null;
      window.history.replaceState({}, document.title, '/login');
    },
  },
};

</script>

<style scoped>

#login-container {
  height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/images/map-blur.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-card {
  background: white;
  border-radius: 7px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

#login-card > * {
  margin-top: 12px;
}

#login-card > *:first-child {
  margin-top: 0;
}

#logo-img {
  width: 82px;
  height: 82px;
}

#login-card > h1 {
  font-size: 22px;
  text-align: center;
}

#login-card h2 {
  font-size: 18px;
  color: #8293a3;
  text-align: center;
}

#login-form {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#login-form > * {
  position: absolute;
}

.login-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-fields > * {
  margin-top: 12px;
}

.login-fields > *:first-child {
  margin-top: 0;
}

.login-fields > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background: white;
  padding: 6px;
}

.login-fields > input:focus {
  border: 1px solid #1C9BE6;
}

.login-form-hint {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #8293A3;
}

#login-form .span-error {
  font-size: 14px;
  text-align: center;
  color: #ff4541;
}

#success-div {
  display: flex;
  flex-direction: column;
}

#success-div > h1 {
  font-size: 20px;
  text-align: center;
}

#success-div > span {
  font-size: 16px;
  text-align: center;
}

#success-div span.email {
  font-weight: bold;
}

.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 7px;
  white-space: nowrap;
  box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}

.google-button:focus, .google-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}

.google-button:active {
  background-color: #e5e5e5;
  box-shadow: none;
  transition-duration: 10ms;
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', arial, sans-serif;
}

</style>
