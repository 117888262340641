<template>
  <div class="date-selection">
    <span class="date-selection-label">{{ label }}</span>
    <vuejs-datepicker
      v-model="dates.startDate"
      :format="format"
      :maximum-view="maximumView"
      wrapper-class="vdp-datepicker-start"
    />
    <template v-if="range">
      <span class="date-selection-divider">to</span>
      <vuejs-datepicker
        v-model="dates.endDate"
        :format="format"
        :maximum-view="maximumView"
        wrapper-class="vdp-datepicker-end"
      />
    </template>
  </div>

</template>

<script>

import vuejsDatepicker from 'vuejs-datepicker';

export default {
  name: 'DateSelection',
  components: {
    vuejsDatepicker,
  },
  props: {
    value: null,
    label: {
      type: String,
      default: 'From',
    },
    range: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: 'MMM dsu yyyy',
    },
    maximumView: {
      type: String,
      default: 'year',
    },
  },
  data() {
    return {
      dates: this.value,
    };
  },
  watch: {
    dates: {
      handler(dates) {
        this.$emit('input', dates);
      },
      deep: true,
    },
  },
};

</script>

<style scoped>

.date-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date-selection-label {
  width: 30%;
  text-align: right;
  padding-right: 8px;
}

.date-selection-divider {
  padding: 0 8px;
}

</style>

<style>

.date-selection .vdp-datepicker {
  flex-basis: 0;
  flex-grow: 1;
}

.date-selection .vdp-datepicker input[type=text] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  background: white;
  padding: 6px;
}

.date-selection .vdp-datepicker input[type=text]:focus {
  border: 1px solid #1C9BE6;
  border-radius: 7px 7px 0 0;
}

.date-selection .vdp-datepicker input[type=text]:disabled {
  border: 1px solid #CED6DE;
}

.date-selection .vdp-datepicker-start .vdp-datepicker__calendar {
  left: -50px;
}

.date-selection .vdp-datepicker-end .vdp-datepicker__calendar {
  right: 0;
}

</style>
