<template>
  <div id="loading-screen">
    <img :src="require('@/assets/images/spotangels.svg')" id="logo" alt="SpotAngels"/>
    <div id="loading-info">
      <Spinner v-if="loadingApp" id="loading-spinner"/>
      <div v-else-if="loadingError" id="loading-error">
        <p id="error-message">
          Error loading the editor, please try again
        </p>
        <MaterialButton id="retry-button" v-on:click="retry" label="Retry"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import MaterialButton from '@/components/MaterialButton';
import Spinner from '@/components/Spinner';

export default {
  name: 'LoadingScreen',
  components: { Spinner, MaterialButton },
  computed: {
    ...mapGetters('editor', ['loadingApp', 'loadingError']),
  },
  methods: {
    retry() {
      this.$router.go(0);
    },
  },
};

</script>

<style scoped>

#loading-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#logo {
  width: 320px;
}

#loading-info {
  margin-top: 48px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loading-spinner {
  width: 40px;
  height: 40px;
}

#loading-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#error-message {
  color: #FF4541;
}

</style>
