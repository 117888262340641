<template>
  <div class="time-selection">
    <span class="time-selection-label" v-if="label">{{ label }}</span>
    <vue-timepicker
      ref="startTimePicker"
      format="HH:mm"
      :minute-range="startMinuteRange"
      v-model="startTime"
      :data-label="timeLabel(startTime)"
      hide-disabled-minutes :hide-clear-button="!optional" auto-scroll
    />
    <template v-if="range">
      <span class="time-selection-divider">to</span>
      <vue-timepicker
        ref="endTimePicker"
        format="HH:mm"
        :minute-range="endMinuteRange"
        v-model="endTime"
        :data-label="timeLabel(endTime)"
        hide-disabled-minutes :hide-clear-button="!optional" auto-scroll
      />
    </template>
  </div>
</template>

<script>

import vueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  name: 'TimeSelection',
  components: {
    vueTimepicker,
  },
  props: {
    label: null,
    range: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    startMinuteRange: null,
    endMinuteRange: null,
    value: null,
  },
  data() {
    return {
      times: this.value,
    };
  },
  computed: {
    startTime: {
      get() {
        if (this.times.start_hour === null) return null;

        return {
          HH: this.times.start_hour.toString().padStart(2, '0'),
          mm: this.times.start_minute.toString().padStart(2, '0'),
        };
      },
      set(time) {
        const newHour = parseInt(time.HH, 10);
        const newMinute = parseInt(time.mm, 10);
        if (this.times.start_hour !== newHour || this.times.start_minute !== newMinute) {
          this.times.start_hour = newHour;
          this.times.start_minute = newMinute;
          this.$emit('input', this.times);
        }
      },
    },
    endTime: {
      get() {
        if (this.times.end_hour === null) return null;

        const endHour = this.times.end_hour < 24 ? this.times.end_hour : 0;
        return {
          HH: endHour.toString().padStart(2, '0'),
          mm: this.times.end_minute.toString().padStart(2, '0'),
        };
      },
      set(time) {
        let newHour = parseInt(time.HH, 10);
        if (newHour === 0) {
          newHour = 24;
        }

        const newMinute = parseInt(time.mm, 10);
        if (this.times.end_hour !== newHour || this.times.end_minute !== newMinute) {
          this.times.end_hour = newHour;
          this.times.end_minute = newMinute;
          this.$emit('input', this.times);
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.startTimePicker.$el.querySelectorAll('ul.hours > li').forEach((el) => this.replaceHourLabel(el));

      if (this.range) {
        this.$refs.endTimePicker.$el.querySelectorAll('ul.hours > li').forEach((el) => this.replaceHourLabel(el));
      }
    });
  },
  methods: {
    timeLabel(time) {
      if (time === null) return '';
      const amPmHour = time.HH % 12;
      return `${(amPmHour !== 0 ? amPmHour : 12).toString()}:${time.mm.toString().padStart(2, '0')} ${time.HH < 12 ? 'AM' : 'PM'}`;
    },
    replaceHourLabel(el) {
      if (!/^[0-9]+$/.test(el.innerHTML)) return;
      const hour = parseInt(el.innerHTML, 10);
      const amPmHour = hour % 12;
      el.innerHTML = `${(amPmHour !== 0 ? amPmHour : 12)} ${hour < 12 ? 'AM' : 'PM'}`;
    },
  },
};

</script>

<style scoped>

.time-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.time-selection-label {
  width: 30%;
  text-align: right;
  padding-right: 8px;
}

.time-selection-divider {
  padding: 0 8px;
}

.time-selection .vue__time-picker.time-picker {
  flex-basis: 0;
  flex-grow: 1;
  min-height: 32px;
}

</style>

<style>

.vue__time-picker input.display-time {
  width: 100%;
  background: white;
  border-radius: 7px;
  border: 1px solid #CED6DE;
}

.vue__time-picker input.display-time:focus {
  border: 1px solid #1c9be6;
}

</style>
