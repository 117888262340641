import ServicesHelper from '@/utils/servicesHelper';
import AuthHelper from '@/utils/authHelper';
import randomHexa from '@/utils/randomHexa';

class MapDataHelper {
  static REGULATIONS = Object.freeze({
    TimeLimit: 'Time Limit',
    MeterHour: 'Meter',
    StreetCleaning: 'Street Cleaning',
    TowAway: 'Tow Away',
    NoParking: 'No Parking',
    NoStanding: 'No Standing',
    NoStopping: 'No Stopping',
    NoData: 'No Data',
  });

  static WEEKDAYS = Object.freeze(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);

  static GARAGE_TYPES = Object.freeze([
    'Surface',
    'Surface - Covered',
    'Subterranean',
    'Garage',
    'Garage & Surface',
    'Valet Stand',
  ]);

  static GARAGE_OWNERS = Object.freeze([
    'City of Berkeley',
    'City of Las Vegas',
    'City of Los Angeles',
    'City of Oakland',
    'City of Santa Monica',
    'SFMTA',
  ]);

  static DEAL_DISPLAY_NAMES = Object.freeze([
    'Daily Max - resets at 12am',
    'Early Bird',
    'Evening',
  ]);

  static TICKET_CATEGORIES = Object.freeze([
    {
      id: 6,
      title: 'Gas Prices Pictures',
      requiresPermissions: [
        'map_data_edit_fuel_stations',
      ],
      requiresAdmin: false,
    },
    {
      id: 4,
      title: 'Monthly Requests',
      requiresPermissions: [],
      requiresAdmin: true,
    },
    {
      id: 0,
      title: 'Pictures',
      requiresPermissions: [
        'map_data_process_picture_tickets',
      ],
      requiresAdmin: false,
    },
    {
      id: 1,
      title: 'Marked as Inaccurate',
      requiresPermissions: [],
      requiresAdmin: false,
    },
    {
      id: 5,
      title: 'QA Tickets',
      requiresPermissions: [
        'map_data_process_qa_tickets',
      ],
      requiresAdmin: false,
    },
    {
      id: 2,
      title: 'Spots edited',
      requiresPermissions: [],
      requiresAdmin: false,
    },
  ]);

  static newSpotData() {
    return {
      regulations: [],
      meters_prices: [],
      payment_details: [],
    };
  }

  static newGarageData() {
    return {
      ...this.newSpotData(),
      regulations: [
        this.newSchedule('Garage', {
          startHour: 0,
          startMinute: 0,
          endHour: 0,
          endMinute: 0,
        }),
      ],
      garage_properties: {
        garage_name: null,
        garage_type: null,
        operator_id: null,
        owner: null,
        email: null,
        phone: null,
        poi_url: null,
        operator_url: null,
        gmaps_place_id: null,
        gmaps_status: null,
        customer: false,
        shuttle: false,
        airport_parking: false,
        promoted: false,
        overnight_allowed: true,
        monthly_visible: true,
        receive_requests: null,
        monthly_only: false,
        monthly_full: false,
        monthly_discount_allowed: null,
        license_plate_required: false,
        monthly_form_id: null,
        monthly_email_template_id: null,
        monthly_booking_notes: null,
        notes: null,
        validation_instructions: null,
        oversize_surcharge: null,
      },
      segment_properties: {
        spot_count: null,
      },
      garage_entrances: [],
      garage_payments: [],
      garage_rates: [],
      garage_monthly_rates: [],
      garage_deals: [],
      shuttle_schedules: [],
    };
  }

  static newEntrance(address = null, geom_entrance = null) {
    return {
      id: randomHexa(16),
      address,
      geom_entrance,
    };
  }

  static newGaragePaymentDetails() {
    return {
      id: randomHexa(16),
      provider_id: null,
      payment_id: null,
      space_id_required: false,
    };
  }

  static newGarageRate() {
    return {
      id: randomHexa(16),
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
      start_time: '00:00:00',
      end_time: '24:00:00',
      is_next_day: false,
      duration: 60,
      price: 0.5,
      proportional: false,
      min_initial_stay: null,
    };
  }

  static newGarageDeal() {
    return {
      id: randomHexa(16),
      deal_name: 'Regular',
      display_name: null,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
      in_from: null,
      in_to: '23:59:59',
      in_is_next_day: false,
      out_by: '23:59:59',
      out_after: null,
      max_stay: null,
      min_stay: null,
      until_day_in: null,
      until_day_out: null,
      price: 0,
    };
  }

  static newGarageMonthlyRate() {
    return {
      id: randomHexa(16),
      price: 300,
      deposit: null,
      one_time_fee: null,
      monthly_type_id: null,
      description: null,
      checkout_msg: null,
      available: 'Yes',
    };
  }

  static newShuttleSchedule() {
    return {
      id: randomHexa(16),
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
      start_time: '00:00:00',
      end_time: '24:00:00',
      frequency: null,
      on_demand: false,
    };
  }

  static newSchedule(regulation, opts = {}) {
    return {
      id: randomHexa(16),
      name: regulation,
      allowed: regulation === 'TimeLimit' || regulation === 'MeterHour' || regulation === 'NoData',
      start_year: null,
      end_year: null,
      start_month: 1,
      day_start_month: 1,
      end_month: 12,
      day_end_month: 31,
      start_hour: Object.prototype.hasOwnProperty.call(opts, 'startHour') ? opts.startHour : 8,
      start_minute: Object.prototype.hasOwnProperty.call(opts, 'startMinute') ? opts.startMinute : 0,
      end_hour: Object.prototype.hasOwnProperty.call(opts, 'endHour') ? opts.endHour : 18,
      end_minute: Object.prototype.hasOwnProperty.call(opts, 'endMinute') ? opts.endMinute : 0,
      nb_days: 0,
      duration: regulation === 'TimeLimit' ? 120 : null,
      mon: '11111',
      tue: '11111',
      wed: '11111',
      thu: '11111',
      fri: '11111',
      sat: '11111',
      sun: '11111',
      id_permits: [],
      active_on_holidays: false,
      is_suspendable: true,
      is_temporary: false,
      npa: false,
    };
  }

  static newMeterPrice() {
    return {
      id: randomHexa(16),
      days: [1, 2, 3, 4, 5],
      from_hour: 8,
      from_min: 0,
      to_hour: 18,
      to_min: 0,
      rates: [2.0],
      after_durations: [null],
    };
  }

  static newMeterPaymentDetails() {
    return {
      id: randomHexa(16),
      provider_id: null,
      payment_id: [''],
    };
  }

  static hasFeatureChanged(change) {
    return JSON.stringify(change.feature) !== JSON.stringify(change.originalFeature);
  }

  static isGarage(feature) {
    return feature.geometry.type === 'Polygon'
      || (
        feature.geometry.type === 'LineString'
        && feature.geometry.coordinates[0][0] === feature.geometry.coordinates[feature.geometry.coordinates.length - 1][0]
        && feature.geometry.coordinates[0][1] === feature.geometry.coordinates[feature.geometry.coordinates.length - 1][1]
      );
  }

  static copyItemWithId(item) {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.id = randomHexa(16);
    return newItem;
  }

  static assignRandomIds(items) {
    return items.map((i) => {
      if (!('id' in i)) { i.id = randomHexa(16); }
      return i;
    });
  }

  static isNoData(spotData) {
    return spotData && spotData.regulations.length === 1 && spotData.regulations[0].name === 'NoData';
  }

  static hasDataChanges(initialSpotData, spotData) {
    return this.isNoData(spotData)
      || JSON.stringify(initialSpotData) !== JSON.stringify(spotData)
      // TODO find better fix for default data segments
      || JSON.stringify(spotData) === JSON.stringify(this.newSpotData())
      || JSON.stringify(spotData) === JSON.stringify(this.newGarageData());
  }

  static spotToCheckNeeds(spot) {
    const needs = [];
    if (spot.needs_entrance) {
      needs.push('entrance');
    }
    if (spot.needs_type) {
      needs.push('type');
    }
    if (spot.needs_onsite_price) {
      needs.push('on-site rates');
    }

    return `Needs ${needs.join(', ')}`;
  }

  static getAreasToMap(success, error) {
    return this.doGet('/areas_to_map', success, error);
  }

  static saveAreaToMap(feature, success, error) {
    return this.doPost('/areas_to_map', { geom: feature.geometry }, success, error);
  }

  static deleteAreaToMap(id, success, error) {
    return this.doDelete(`/areas_to_map/${id}`, null, success, error);
  }

  static getSpotsToCheck(success, error) {
    return this.doGet('/spots_to_check', success, error);
  }

  static getPendingTickets(category, success, error) {
    return this.doGet(`/tickets/pending/category/${category}`, success, error, true);
  }

  static processTicket(ticketId, valid, comments, success, error) {
    return this.doPost(`/tickets/${ticketId}/process`, { valid, comments }, success, error);
  }

  static getSpotData(segmentId, success, error) {
    return this.doGet(`/regulations/segment_id/${segmentId}`, success, error);
  }

  static getSpotChangesets(segmentId, success, error) {
    return this.doGet(`/changesets/segments/${segmentId}`, success, error);
  }

  static getGarageDetails(segmentId, success, error) {
    return this.doGet(`/segments/garage_details/${segmentId}`, success, error);
  }

  static getPermitsBySegmentId(segmentId, success, error) {
    return this.doGet(`/permits/segment_id/${segmentId}`, success, error);
  }

  static getPermitsbyLngLat(lng, lat, success, error) {
    return this.doGet(`/permits/get_permits_by_lat_lng?lng=${lng}&lat=${lat}`, success, error);
  }

  static getMeterProviders(success, error) {
    return this.doGet('/meters/providers', success, error);
  }

  static searchOperators(query, success, error) {
    return this.doGet(`/operators?query=${encodeURIComponent(query)}`, success, error);
  }

  static getOperators(success, error) {
    if (this.OPERATORS !== null) {
      success(null, { operators: this.OPERATORS });
      return null;
    }

    return this.doGet(
      '/operators',
      (request, response) => {
        this.OPERATORS = Object.freeze(response.operators);
        success(request, response);
      },
      error,
    );
  }

  static getOperatorMonthlyMetadata(operatorId, success, error) {
    if (operatorId in this.OPERATORS_MONTHLY_METADATA) {
      success(null, this.OPERATORS_MONTHLY_METADATA[operatorId]);
      return null;
    }

    return this.doGet(
      `/operators/${operatorId}/monthly_metadata`,
      (request, response) => {
        this.OPERATORS_MONTHLY_METADATA[operatorId] = response;
        success(request, response);
      },
      error,
    );
  }

  static getPaymentProviders(success, error) {
    if (this.PAYMENT_PROVIDERS !== null) {
      success(null, { providers: this.PAYMENT_PROVIDERS });
      return null;
    }

    return this.doGet(
      '/meters/providers',
      (request, response) => {
        this.PAYMENT_PROVIDERS = Object.freeze(response.providers);
        success(request, response);
      },
      error,
    );
  }

  static getMonthlyTypes(success, error) {
    if (this.MONTHLY_TYPES !== null) {
      success(null, { monthly_types: this.MONTHLY_TYPES });
      return null;
    }

    return this.doGet(
      '/monthly_types',
      (request, response) => {
        this.MONTHLY_TYPES = Object.freeze(response.monthly_types);
        success(request, response);
      },
      error,
    );
  }

  static getMonthlyForms(success, error) {
    if (this.MONTHLY_FORMS !== null) {
      success(null, { monthly_forms: this.MONTHLY_FORMS });
      return null;
    }

    return this.doGet(
      '/monthly_forms',
      (request, response) => {
        this.MONTHLY_FORMS = Object.freeze(response.monthly_forms);
        success(request, response);
      },
      error,
    );
  }

  static getMonthlyEmailTemplates(success, error) {
    if (this.MONTHLY_EMAIL_TEMPLATES !== null) {
      success(null, { monthly_email_templates: this.MONTHLY_EMAIL_TEMPLATES });
      return null;
    }

    return this.doGet(
      '/monthly_email_templates',
      (request, response) => {
        this.MONTHLY_EMAIL_TEMPLATES = Object.freeze(response.monthly_email_templates);
        success(request, response);
      },
      error,
    );
  }

  static getCommunityPool(success, error) {
    return this.doGet('/community_pool', success, error);
  }

  static saveChangeset(segment, spotData, success, error) {
    if (this.isNoData(spotData)) { // TODO review
      success();
      return;
    }

    // Make a deep copy of data so that we can adapt it for the api without changing the local copy
    const dataCopy = JSON.parse(JSON.stringify(spotData));
    let segmentId = null;
    if (typeof segment === 'number') {
      dataCopy.segment_id = segment;
      segmentId = segment;
    } else {
      dataCopy.segment = segment;
      if (segment.id !== undefined && typeof segment.id !== 'string') {
        segmentId = segment.id;
      } else {
        delete segment.id;
      }
    }

    this.adaptForApi(segmentId, dataCopy);
    this.doPost('/submit_proposal', dataCopy, success, error);
  }

  static restoreChangeset(changesetId, success, error) {
    this.doPost(`/changesets/${changesetId}/restore`, {}, success, error);
  }

  static deleteSpot(id, success, error) {
    return this.doDelete('/segments', { segment_id: id }, success, error);
  }

  /**
   * Fuel stations
   */

  static newFuelStation(feature) {
    return {
      name: '',
      geom: JSON.stringify(feature.geometry),
      gas_prices: {},
    };
  }

  static getFuelStation(id, success, error) {
    this.doGet(
      `/fuel_stations/${id}`,
      (request, result) => {
        const geom = JSON.parse(result.fuel_station.geom);
        result.fuel_station.lng = geom.coordinates[0];
        result.fuel_station.lat = geom.coordinates[1];

        if (success) {
          success(request, result);
        }
      },
      error,
    );
  }

  static deleteFuelStation(id, success, error) {
    this.doDelete(`/fuel_stations/${id}`, null, success, error);
  }

  static saveFuelStation(fuelStation, selectedTicketId, success, error) {
    delete fuelStation.lng;
    delete fuelStation.lat;

    this.doPost(
      '/fuel_changesets',
      {
        fuel_station: fuelStation,
        selected_ticket_id: selectedTicketId,
      },
      success,
      error,
    );
  }

  static markFuelStationAsChecked(id, success, error) {
    this.doPost(`/fuel_stations/${id}/check`, null, success, error);
  }

  static getPoi(id, success, error) {
    this.doGet(`/pois/${id}`, success, error);
  }

  static savePoi(poi, success, error) {
    this.doPost(`/pois/${poi.id}`, poi, success, error);
  }

  /**
   * Private properties and methods, please don't call from outside
   */

  static OPERATORS = null;

  static OPERATORS_MONTHLY_METADATA = {};

  static PAYMENT_PROVIDERS = null;

  static MONTHLY_TYPES = null;

  static async requestAsync(method, path, useMaster = false) {
    const url = useMaster
      ? process.env.VUE_APP_MAP_DATA_MASTER_URL
      : process.env.VUE_APP_MAP_DATA_URL;
    return ServicesHelper.doFetchAsync(
      method,
      url + path,
      {},
      {},
      {
        Authorization: `Bearer ${AuthHelper.getLoginToken()}`,
      },
    );
  }

  static doGet(path, success, error, useMaster = false) {
    return ServicesHelper.get(`${useMaster ? process.env.VUE_APP_MAP_DATA_MASTER_URL : process.env.VUE_APP_MAP_DATA_URL}${path}`, success, error, { Authorization: `Bearer ${AuthHelper.getLoginToken()}` });
  }

  static doPost(path, body, success, error) {
    return ServicesHelper.post(`${process.env.VUE_APP_MAP_DATA_MASTER_URL}${path}`, body, success, error, { Authorization: `Bearer ${AuthHelper.getLoginToken()}` });
  }

  static doDelete(path, body, success, error) {
    return ServicesHelper.delete(`${process.env.VUE_APP_MAP_DATA_MASTER_URL}${path}`, body, success, error, { Authorization: `Bearer ${AuthHelper.getLoginToken()}` });
  }

  static adaptForApi(segmentId, data) {
    if (data === null) return;

    for (const prop of Object.keys(data)) {
      if (prop === 'id_permits' && data[prop].length > 0 && typeof data[prop][0] === 'object') {
        data[prop] = data[prop].map((p) => p.id);
      } else if (prop === 'payment_details') {
        data[prop].forEach((d) => {
          delete d.provider_name;
          delete d.id;
        });
      } else if (typeof data[prop] === 'object') {
        if (prop === 'regulations') {
          for (const regulation of data[prop]) {
            regulation.segment_id = segmentId;
            if (regulation.end_hour < regulation.start_hour) regulation.nb_days = 1;
          }
        } else if (prop === 'garage_rates') {
          for (const rate of data[prop]) {
            if (rate.start_time === null || rate.start_time === '24:00:00') rate.start_time = '00:00:00';
            if (rate.end_time === null || rate.end_time === '24:00:00') rate.end_time = '00:00:00';
          }
        } else if (prop === 'garage_deals') {
          for (const deal of data[prop]) {
            if (deal.display_name === 'Daily Max - resets at 12am') deal.display_name = 'Daily Max';
            if (deal.in_from === '24:00:00') deal.in_from = '00:00:00';
            if (deal.in_to === '24:00:00') deal.in_to = '00:00:00';
            if (deal.out_by === '24:00:00') deal.out_by = '00:00:00';
          }
        } else if (prop === 'shuttle_schedules') {
          for (const schedule of data[prop]) {
            if (schedule.end_time === '24:00:00') schedule.end_time = '00:00:00';
          }
        }

        this.adaptForApi(segmentId, data[prop]);
      } else if (this.WEEKDAYS.includes(prop) && typeof data[prop] === 'string' && data[prop].match(/^[01]{5}$/)) {
        data[`${prop}_bits`] = data[prop];
        delete data[prop];
      }
    }
  }
}

export default MapDataHelper;
