<template>
  <div class="form-select">
    <span>{{ label }}</span>
    <vue-multiselect
      v-model="innerValue"
      :options="scalarOptions"
      :select-label="null"
      deselect-label="Click to deselect "
      :selected-label="null"
      :searchable="searchable"
      :placeholder="placeholder"
      :custom-label="customLabel || defaultCustomLabel"
      :multiple="multiple"
      :close-on-select="!multiple"
      v-on:search-change="$emit('search-change', $event)"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </vue-multiselect>
  </div>
</template>

<script>

import vueMultiselect from 'vue-multiselect';

export default {
  name: 'Select',
  components: {
    vueMultiselect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default() { return []; },
    },
    valueKey: {
      type: String,
      default: null,
    },
    labelKey: {
      type: String,
      default: null,
    },
    customLabel: {
      type: Function,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: null,
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  computed: {
    scalarOptions() {
      return this.valueKey === null ? this.options : this.options.map((o) => o[this.valueKey]);
    },
  },
  watch: {
    innerValue(innerValue) {
      this.$emit('input', innerValue);
    },
  },
  methods: {
    defaultCustomLabel(value) {
      if (this.labelKey === null) {
        return value;
      }

      const option = this.options.find((o) => o[this.valueKey] === value);
      if (option === undefined) {
        return value;
      }

      return option[this.labelKey];
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style scoped>

.form-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.form-select:last-child {
  margin-bottom: 0;
}

.form-select > span {
  width: 30%;
  text-align: right;
  padding-right: 8px;
}

.form-select > div.multiselect {
  width: 70%;
}

</style>

<style>

.form-select .multiselect {
  min-height: 32px;
}

.form-select .multiselect__tags {
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-select .multiselect.multiselect--active .multiselect__tags {
  border: 1px solid #1C9BE6;
}

.form-select .multiselect.multiselect--disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.form-select .multiselect.multiselect--disabled .multiselect__select {
  cursor: not-allowed;
  background: none;
}

.form-select .multiselect__tags:focus {
  border: 1px solid #1C9BE6;
}

.form-select .multiselect__tags:disabled {
  border: 1px solid #CED6DE;
}

.form-select .multiselect__tags-wrap {
  display: flex;
  flex-direction: row;
}

.form-select .multiselect__tags-wrap span {
  margin: 0 2px 0 0;
}

.form-select .multiselect__select {
  height: 32px;
}

.form-select .multiselect__placeholder {
  margin: 0;
  padding: 0;
}

.form-select .multiselect__input {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.form-select .multiselect__single {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

</style>
