<template>
  <div id="garage-payment">
    <ItemCard v-for="(details, i) in allDetails" :key="details.id" title="Payment ID" v-on:copy="copyDetails(i)" v-on:delete="deleteDetails(i)">
      <Select label="Provider" :options="providers" label-key="provider_name" value-key="id" v-model="details.provider_id"/>
      <Input label="ID" v-model="details.payment_id"/>
      <Checkbox v-model="details.space_id_required" label="Space ID required" :form-style="true"/>
    </ItemCard>
    <MaterialButton label="Add payment ID" v-on:click="addDetails"/>
  </div>
</template>

<script>

import ItemCard from '@/components/editing/ItemCard';
import Input from '@/components/form/Input';
import MapDataHelper from '@/utils/mapDataHelper';
import Toast from '@/utils/toast';
import Select from '@/components/form/Select';
import Checkbox from '@/components/form/Checkbox';
import MaterialButton from '@/components/MaterialButton';

export default {
  name: 'MeterPayment',
  components: {
    MaterialButton,
    Checkbox,
    Select,
    Input,
    ItemCard,
  },
  props: {
    value: null,
  },
  data() {
    return {
      providers: [],
      allDetails: MapDataHelper.assignRandomIds(this.value),
    };
  },
  watch: {
    allDetails(allDetails) {
      this.$emit('input', allDetails);
    },
  },
  mounted() {
    MapDataHelper.getPaymentProviders((request, result) => { this.providers = result.providers; }, () => Toast.danger('Error getting payment providers'));
  },
  methods: {
    providerLabel(id) {
      return this.providers.find((p) => p.id === id).provider_name;
    },
    addDetails() {
      this.allDetails.push(MapDataHelper.newGaragePaymentDetails());
    },
    copyDetails(i) {
      this.allDetails.push(MapDataHelper.copyItemWithId(this.allDetails[i]));
    },
    deleteDetails(i) {
      this.allDetails.splice(i, 1);
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style scoped>

#garage-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#garage-payment > * {
  margin-bottom: 8px;
}

#garage-payment > *:last-child {
  margin-bottom: 0;
}

</style>
