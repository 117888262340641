<template>
  <div class="permits">
    <span>Permits</span>
    <vue-multiselect
      v-model="selectedPermits"
      :options="permits"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :hide-selected="true"
      :preserve-search="true"
      placeholder=""
      label="name"
      track-by="name"
      :preselect-first="false"
    />
  </div>
</template>

<script>

import vueMultiselect from 'vue-multiselect';
import MapDataHelper from '@/utils/mapDataHelper';
import Toast from '@/utils/toast';

export default {
  name: 'Permits',
  components: {
    vueMultiselect,
  },
  props: {
    segmentId: null,
    lngLat: null,
    value: null,
  },
  data() {
    return {
      permits: [],
      selectedPermitIds: this.value,
    };
  },
  computed: {
    selectedPermits: {
      get() {
        return this.permits.filter((p) => this.selectedPermitIds.indexOf(p.id) !== -1);
      },
      set(newValue) {
        const selectedPermitIds = newValue.map((p) => p.id);
        this.selectedPermitIds = selectedPermitIds;
        this.$emit('input', selectedPermitIds);
      },
    },
  },
  mounted() {
    if (this.segmentId !== null) {
      MapDataHelper.getPermitsBySegmentId(this.segmentId, this.onGotPermits, this.onPermitsError);
    } else if (this.lngLat !== null) {
      let coords = this.lngLat;
      while (Array.isArray(coords[0])) {
        coords = coords[0];
      }
      MapDataHelper.getPermitsbyLngLat(coords[0], coords[1], this.onGotPermits, this.onPermitsError);
    }
  },
  methods: {
    onGotPermits(request, result) {
      this.permits = result.permits;
    },
    onPermitsError() {
      Toast.danger('Could not load segment permits');
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style scoped>

.permits {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.permits:last-child {
  margin-bottom: 0;
}

.permits > span {
  width: 30%;
  text-align: right;
  padding-right: 8px;
}

.permits > div.multiselect {
  width: 70%;
}

</style>

<style>

.permits .multiselect {
  min-height: 32px;
}

.permits .multiselect__tags {
  min-height: 32px;
  border: 1px solid #CED6DE;
  border-radius: 7px;
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.permits .multiselect.multiselect--active .multiselect__tags {
  border: 1px solid #1C9BE6;
}

.permits .multiselect__tags:focus {
  border: 1px solid #1C9BE6;
}

.permits .multiselect__tags:disabled {
  border: 1px solid #CED6DE;
}

.permits .multiselect__tags-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px 0;
}

.permits .multiselect__tags-wrap span {
  margin: 2px 2px 2px 0;
}

.permits .multiselect__select {
  height: 32px;
}

.permits .multiselect__placeholder {
  margin: 0;
  padding: 0;
}

.permits .multiselect__input {
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 2px;
}

.permits .multiselect__single {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

</style>
