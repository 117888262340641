<template>
  <div class="tiles" ref="tiles">
    <template v-for="(tile, index) in visibleTiles">
      <div
        v-if="index > 0"
        class="tile-resize-handle"
        v-bind:style="{ width: handleWidth + 'px' }"
        @mousedown="onMouseDown($event, index - 1)"
        :key="'handle-' + tile.index"
      >
        <div class="tile-resize-handle-circle">
          ↔
        </div>
      </div>
      <div
        class="tile"
        v-bind:style="{ width: sizes[tile.index] + '%' }"
        :ref="'tile-' + tile.index"
        :key="'tile-' + tile.index"
      >
        <slot :name="'tile_' + tile.index"/>
      </div>
    </template>
  </div>
</template>

<script>

import Sentry from '@sentry/vue/dist/index.bundle';

export default {
  props: {
    handleWidth: {
      type: Number,
      default: 5,
    },
    tiles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sizesPercent: [],
      movingHandle: null,
      resizeObserver: null,
      saveSizesTimerId: null,
    };
  },
  destroyed() {
    this.resizeObserver.disconnect();
  },
  updated() {
    for (const { show, onresize } of this.tiles) {
      if (show && onresize !== undefined) {
        onresize();
      }
    }
  },
  mounted() {
    for (const [i, tile] of this.tiles.entries()) {
      tile.index = i;
    }

    const savedSizes = window.localStorage.getItem('Tiles.sizesPercent');
    if (savedSizes != null) {
      try {
        this.sizesPercent = JSON.parse(savedSizes);
      } catch (e) {
        this.sizesPercent = [];
        Sentry.captureException(e);
      }
    }

    if (this.sizesPercent.length === 0) {
      const size = 1 / this.tiles.length;
      this.sizesPercent = this.tiles.map(() => size);
    }

    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.tiles);
  },
  computed: {
    width() {
      return this.$refs.tiles.clientWidth - (this.sizesPercent.length - 1) * this.handleWidth;
    },
    sizes() {
      const total = this.totalPercentSize();
      return this.sizesPercent.map((p) => (p / total) * 100);
    },
    visibleTiles() {
      return this.tiles.filter(({ show }) => show);
    },
  },
  watch: {
    sizesPercent(sizesPercent) {
      if (this.saveSizesTimerId !== null) {
        clearTimeout(this.saveSizesTimerId);
      }

      this.saveSizesTimerId = setTimeout(() => {
        window.localStorage.setItem('Tiles.sizesPercent', JSON.stringify(sizesPercent));
        this.saveSizesTimerId = null;
      }, 500);
    },
  },
  methods: {
    totalPercentSize() {
      let total = 0;
      for (const [i, tile] of this.tiles.entries()) {
        if (tile.show) {
          total += this.sizesPercent[i];
        }
      }
      return total;
    },
    onMouseDown(event, handle) {
      // preventDefault will prevent text selection
      event.preventDefault();
      this.movingHandle = handle;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    tileAfter(i) {
      const visibleTiles = this.visibleTiles;
      const tileIndex = visibleTiles.findIndex(({ index }) => index === i);
      return visibleTiles[tileIndex + 1].index;
    },
    onMouseMove(event) {
      // [0] because Vue returns arrays if the ref is set in a v-for
      const tileIndex = this.visibleTiles[this.movingHandle].index;
      const tile = this.$refs[`tile-${tileIndex}`][0];
      const nextTileIndex = this.tileAfter(tileIndex);
      const nextTile = this.$refs[`tile-${nextTileIndex}`][0];
      const { left } = tile.getBoundingClientRect();
      const { right } = nextTile.getBoundingClientRect();
      const size = event.clientX - left;
      if (size > 0 && size < right - left) { // TODO: minSize && maxSize
        this.setSize(tileIndex, nextTileIndex, size);
      }
    },
    onMouseUp() {
      this.movingHandle = null;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
    onResize() {
      this.sizesPercent = [...this.sizesPercent];
    },
    setSize(current, next, size) {
      const tps = this.totalPercentSize();
      // eslint-disable-next-line no-mixed-operators
      const p = size / this.$refs.tiles.clientWidth * tps;
      const sizesPercent = [...this.sizesPercent];
      sizesPercent[current] = p;
      const total = sizesPercent.reduce((a, b, idx) => a + ((idx === next) ? 0 : b));
      sizesPercent[next] = 1 - total;
      this.sizesPercent = sizesPercent;
    },
  },
};
</script>

<style>

.tiles {
  display: flex;
  height: 100%;
  width: 100%;
}

.tile {
  overflow: auto;
}

.tile-resize-handle {
  height: 100%;
  background-color: #E0E6EA;
  cursor: ew-resize;
}

.tile-resize-handle-circle {
  position: relative;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #CED6DE;
  color: #545E69;
  background-color: white;
  font-size: 30px;
  line-height: 19px;
  text-align: center;
  z-index: 2;
}
</style>
