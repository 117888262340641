<template>
  <div class="avatar">
    <img
      class="avatar-icon"
      :src="require(`@/assets/images/avatars/${roleSlug}.svg`)"
      :alt="role"
      :title="role"
    />
    <div class="avatar-right">
      <div class="avatar-username">
        {{ username }}
      </div>
      <div class="avatar-points">
        {{ points }} SPTC
      </div>
    </div>
  </div>
</template>

<script>
import AuthHelper from '@/utils/authHelper';

const numberFormat = new Intl.NumberFormat('en-US');
const icons = [
  'baby-angel',
  'angel',
  'guard-angel',
  'knight-angel',
  'royal-angel',
  'ambassador',
  'newbie-map-maker',
  'map-maker',
  'elite-map-maker',
  'map-validator',
];

export default {
  computed: {
    username() {
      return AuthHelper.getUser().username;
    },
    role() {
      return AuthHelper.getUser().role.display_name;
    },
    roleSlug() {
      let slug = AuthHelper.getUser().role.slug;
      if (slug === 'admin') {
        // Admin icon is the same as map-validator
        slug = 'map-validator';
      }
      if (!icons.includes(slug)) {
        // Default to angel
        slug = 'angel';
      }
      return slug;
    },
    points() {
      return numberFormat.format(AuthHelper.getUser().points);
    },
  },
};
</script>

<style scoped>
.avatar {
  display: flex;
  flex-direction: row;
}

.avatar-right {
  display: flex;
  flex-direction: column;
}

.avatar-username {
  color: #212529;
}

.avatar-points {
  color: #8293A3;
  font-size: 0.7em;
}
</style>
