<template>
  <div class="calendar" v-if="diffCalendar.length > 0">
    <div v-for="(item, i) in diffCalendar" :key="i" class="item">
      <div :key="item.title" v-if="item.title" class="item-title" :class="itemClass(item)">{{ item.title }}</div>
      <div v-for="day in item.days" :key="item.title + day.title + day.hours.join(',') + itemClass(item)['item-added']" class="day" :class="itemClass(item)">
        <div class="title" v-html="day.title"/>
        <div class="values">
          <div v-for="hours in day.hours" :key="hours" v-html="hours"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import compare from '@/utils/compare';

export default {
  name: 'Calendar',
  props: {
    initialRegulation: null,
    updatedRegulation: null,
    section: null,
  },
  data() {
    return {
      ITEM_STATUS_REMOVED: 1,
      ITEM_STATUS_ADDED: 2,
    };
  },
  computed: {
    diffCalendar() {
      const initialItems = this.initialRegulation && this.section in this.initialRegulation && this.initialRegulation[this.section] !== null ? this.initialRegulation[this.section].slice(0) : [];
      const updatedItems = this.updatedRegulation && this.section in this.updatedRegulation && this.updatedRegulation[this.section] !== null ? this.updatedRegulation[this.section].slice(0) : [];
      const diffItems = [];
      const removedIndexes = [];

      for (let i = 0; i < initialItems.length; i++) {
        const initialItem = initialItems[i];
        delete initialItem.change_status;
        let found = false;
        for (let j = 0; j < updatedItems.length; j++) {
          const updatedItem = updatedItems[j];
          delete updatedItem.change_status;

          if (compare(initialItem, updatedItem)) {
            diffItems.push(initialItem);
            updatedItems.splice(j, 1);
            found = true;
            break;
          }
        }

        if (!found) {
          initialItem.change_status = this.ITEM_STATUS_REMOVED;
          diffItems.push(initialItem);
          removedIndexes.push(diffItems.length - 1);
        }
      }

      for (let i = 0; i < updatedItems.length; i++) {
        const updatedItem = updatedItems[i];
        let found = false;
        for (let j = 0; j < initialItems.length; j++) {
          if (compare(initialItems[j], updatedItem)) {
            found = true;
            break;
          }
        }

        if (!found) {
          updatedItem.change_status = this.ITEM_STATUS_ADDED;
          const insertIdx = removedIndexes.pop();
          if (insertIdx !== undefined) {
            diffItems.splice(insertIdx + 1, 0, updatedItem);
          } else {
            diffItems.push(updatedItem);
          }
        }
      }

      return diffItems;
    },
  },
  methods: {
    itemClass(item) {
      return {
        'item-removed': item.change_status === this.ITEM_STATUS_REMOVED,
        'item-added': item.change_status === this.ITEM_STATUS_ADDED,
      };
    },
  },
};

</script>

<style scoped>

.calendar {
  padding: 16px 16px 0 16px;
}

.calendar:last-child {
  padding-bottom: 16px;
}

.item {
  padding-bottom: 16px;
}

.item:last-child {
  padding-bottom: 0;
}

.item-title {
  font-weight: bold;
}

.day {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.values {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/*noinspection CssUnusedSymbol*/
.item-removed {
  color: #ff4541;
  text-decoration: line-through;
}

/*noinspection CssUnusedSymbol*/
.item-added {
  color: #1dcf32;
}

</style>
