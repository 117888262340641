<template>
  <div class="spot-pictures-wrapper">
    <div class="spot-pictures">
      <img
        v-for="(item, index) in data"
        class="regulation-picture"
        :src="item.thumbnail_url"
        :key="item.thumbnail_url"
        @click="openGallery(index)"
        alt="Spot picture"
      />
    </div>
    <portal to="lightbox">
      <!-- Move the Lightbox up in the dom so it renders on top -->
      <LightBox
        ref="lightbox"
        :media="media"
        :showLightBox="false"
        :showCaption="true"
      />
    </portal>
  </div>
</template>

<script>

import LightBox from 'vue-image-lightbox';

require('vue-image-lightbox/dist/vue-image-lightbox.min.css');

export default {
  props: ['data'],
  components: {
    LightBox,
  },
  computed: {
    media() {
      return this.data.map((item) => ({
        thumb: item.thumbnail_url,
        src: item.picture_url,
        caption: `<div style="padding-top:10px">${item.created_at_by}</div>`,
      }));
    },
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
  },
};
</script>

<style scoped>

.spot-pictures {
  overflow-x: auto;
  white-space: nowrap;
  background: white;
}

.regulation-picture {
  width: 150px;
  height: 150px;
  margin-right: 8px;
  display: inline-block;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
}

.regulation-picture:last-child {
  margin-right: 0;
}

img {
  vertical-align: middle;
  border: 0;
}

</style>
