const compare = (obj1, obj2) => {
  for (const p of Object.keys(obj1)) {
    if (!Object.prototype.hasOwnProperty.call(obj2, p)) return false;

    switch (typeof (obj1[p])) {
      case 'object':
        if (!compare(obj1[p], obj2[p])) return false;
        break;
      case 'function':
        if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
        break;
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  for (const p of Object.keys(obj2)) {
    if (!Object.prototype.hasOwnProperty.call(obj1, p)) {
      return false;
    }
  }

  return true;
};

export default compare;
