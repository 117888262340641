<template>
  <button type="button" class="image-button" v-on:click="$emit('click')" :disabled="disabled">
    <img :src="src" :title="title" :alt="alt || title"/>
  </button>
</template>

<script>

export default {
  name: 'ImageButton',
  props: {
    src: null,
    title: null,
    alt: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style scoped>

.image-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 4px;
  opacity: 0.6;
  background: none;
  border: none;
  cursor: pointer;
}

.image-button:hover {
  opacity: 1;
}

.image-button:disabled {
  opacity: 0.3;
  cursor: initial;
}

.image-button:disabled:hover {
  opacity: 0.3;
}

</style>
