<template>
  <div class="item-card" :class="{'item-card-notitle': title === null}">
    <div v-if="title !== null" class="item-card-header">
      <span class="item-card-header-title">{{ title }}</span>
      <div class="item-card-actions">
        <ImageButton
          :src="require('@/assets/images/content_copy-black-24dp.svg')"
          :title="`Copy ${title.toLowerCase()}`"
          v-on:click="$emit('copy')"
        />
        <ImageButton
          :src="require('@/assets/images/delete-black-24dp.svg')"
          :title="deletable ? `Delete ${title.toLowerCase()}` : `Can't delete this ${title.toLowerCase()}`"
          :disabled="!deletable"
          v-on:click="$emit('delete')"
        />
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>

import ImageButton from '@/components/ImageButton';

export default {
  name: 'ItemCard',
  components: { ImageButton },
  props: {
    title: {
      type: String,
      default: null,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
};

</script>

<style scoped>

.item-card {
  background: white;
  padding: 8px 12px 12px 12px;
  border-radius: 7px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #CED6DE;
}

.item-card > * {
  margin-bottom: 8px;
}

.item-card > *:last-child {
  margin-bottom: 0;
}

.item-card.item-card-notitle > *:first-child {
  margin-top: 8px;
}

.item-card .item-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.item-card-header-title {
  flex-grow: 1;
}

.item-card-actions {
  display: flex;
  flex-direction: row;
}

.item-card-actions > button {
  margin-right: 8px;
}

.item-card-actions > button:last-child {
  margin-right: 0;
}

</style>
