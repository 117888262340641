<template>
  <div>
    <template v-if="href === null">
      <div v-on:click="!disabled && $emit('click')" class="sheet-card-action" :style="{ color: disabled ? null : color }" :disabled="disabled">
        <img :style="{visibility: !!icon ? 'visible' : 'hidden'}" :src="icon" :alt="action"/>
        <div class="sheet-card-action-text-wrapper">
          <span class="sheet-card-action-text">{{ action }}</span>
          <span class="sheet-card-action-summary" v-if="summary">{{ summary }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <a class="sheet-card-action-a" :href="href" target="_blank" :disabled="disabled">
        <div class="sheet-card-action" :style="{ color: color }">
          <img :style="{visibility: !!icon ? 'visible' : 'hidden'}" :src="icon" :alt="action"/>
          <div class="sheet-card-action-text-wrapper">
            <span class="sheet-card-action-text">{{ action }}</span>
            <span class="sheet-card-action-summary" v-if="summary">{{ summary }}</span>
          </div>
        </div>
      </a>
    </template>
  </div>
</template>

<script>

export default {
  name: 'SheetCardAction',
  props: {
    icon: null,
    action: {
      type: String,
      default: null,
    },
    summary: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#1c9be6',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style scoped>

.sheet-card-action {
  height: 54px;
  padding: 0 16px;
  border-bottom: 1px solid #CED6DE;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.sheet-card-action[disabled] {
  cursor: not-allowed;
  color: #8293a3;
}

.sheet-card-action:hover {
  background-color: #E6F3FA;
}

.sheet-card-action img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.sheet-card-action[disabled] img {
  filter: grayscale(100%);
}

.sheet-card-action-text-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
}

.sheet-card-action-summary {
  color: #212529;
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sheet-card-action-a {
  text-decoration: none;
}

</style>
